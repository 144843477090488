export const groupsItems = [
	{
		gr_id: "all",
		value: "Toutes les fiches",
		icon: "mdi-account-group"
	},
	{
		gr_id: "mycontacts",
		value: "Mes contacts",
		icon: "mdi-account"
	},
	{
		gr_id: "contacts",
		value: "Tous les contacts",
		icon: "mdi-account"
	},
	{
		gr_id: "societies",
		value: "Toutes les sociétés",
		icon: "mdi-domain"
	},
	{
		gr_id: "users",
		value: "Tous les utilisateurs",
		icon: "mdi-account-card-details"
	},
	{
		gr_id: "agents",
		value: "Tous les négociateurs immo.",
		icon: "mdi-account-key"
	},
	{
		gr_id: "owners",
		value: "Tous les proprétaires",
		icon: "mdi-castle"
	},
	{
		gr_id: "lawyers",
		value: "Tous les avocats",
		icon: "mdi-scale-balance"
	},
	{
		gr_id: "renters",
		value: "Tous les locataires",
		icon: "mdi-owl"
	},
	{
		gr_id: "agencies",
		value: "Toutes les agences immo.",
		icon: "mdi-home-thermometer"
	},
	{
		gr_id: "syndics",
		value: "Tous les syndics",
		icon: "mdi-crane"
	},
	{
		gr_id: "caretakers",
		value: "Tous les concierges",
		icon: "mdi-account-question"
	},
	{
		gr_id: "intermediaire",
		value: "Tous les intermédiaires",
		icon: "mdi-account-question"
	},
	{
		gr_id: "buildings",
		value: "Tous les immeubles",
		icon: "mdi-office-building"
	},
	{
		gr_id: "voeux",
		value: "Les voeux",
		icon: "mdi-office-building"
	},
	{
		gr_id: "follows",
		value: "Mes contacts suivis",
		icon: "mdi-hand-heart"
	}
	// {
	//   gr_id: "caddy",
	//   value: "Mon panier",
	//   icon: "mdi-cart"
	// },
	// {
	//   gr_id: "lastimport",
	//   value: "Dernier import",
	//   icon: "mdi-clock"
	// }
];

export const itemsItems = {
	items_bu_roof: [
		{
			li_id: 76,
			li_name: "items_bu_roof",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:28:04",
			updatedAt: "2019-11-12 17:28:04"
		},
		{
			li_id: 73,
			li_name: "items_bu_roof",
			key: 1,
			value: "Ardoise",
			options: "",
			createdAt: "2019-11-12 17:27:28",
			updatedAt: "2022-01-19 09:11:46"
		},
		{
			li_id: 74,
			li_name: "items_bu_roof",
			key: 2,
			value: "Tuile",
			options: "",
			createdAt: "2019-11-12 17:27:38",
			updatedAt: "2019-11-12 17:27:38"
		},
		{
			li_id: 75,
			li_name: "items_bu_roof",
			key: 3,
			value: "Paille",
			options: "",
			createdAt: "2019-11-12 17:27:53",
			updatedAt: "2019-11-12 17:27:53"
		}
	],
	items_bu_construction: [
		{
			li_id: 82,
			li_name: "items_bu_construction",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:30:20",
			updatedAt: "2019-11-12 17:30:20"
		},
		{
			li_id: 80,
			li_name: "items_bu_construction",
			key: 1,
			value: "Béton",
			options: "",
			createdAt: "2019-11-12 17:29:09",
			updatedAt: "2019-11-12 17:29:09"
		},
		{
			li_id: 81,
			li_name: "items_bu_construction",
			key: 2,
			value: "Pierre",
			options: "",
			createdAt: "2019-11-12 17:30:00",
			updatedAt: "2019-11-12 17:30:00"
		}
	],
	items_lists: [
		{
			key: "items_of_classegaes",
			value: "Biens > Class. gaz à eff. de serre"
		},
		{
			key: "items_of_classeenergie",
			value: "Biens > Classification énergétique"
		},
		{ key: "items_bu_construction", value: "Biens > Construction" },
		{ key: "items_of_disponibility", value: "Biens > Disponibilité" },
		{ key: "items_of_typeboiler", value: "Biens > Eau chaude" },
		{ key: "items_of_sunny", value: "Biens > Ensoleillement" },
		{ key: "items_of_exposition", value: "Biens > Exposition" },
		{ key: "items_of_generalstate", value: "Biens > Etat général" },
		{ key: "items_of_exclusivite", value: "Biens > Exclusivité" },
		{ key: "items_of_natureheating", value: "Biens > Nature de chauffage" },
		{ key: "items_of_notary", value: "Biens > Notaire" },
		{ key: "items_of_sign", value: "Biens > Vitrine" },
		{ key: "items_bu_district", value: "Biens > Quartiers" },
		{ key: "items_bu_standing", value: "Biens > Standing" },
		{ key: "items_of_typesubscription", value: "Biens > Type d'abonnement eau" },
		{ key: "items_of_rent_regimebail", value: "Biens > Type de bail" },
		{ key: "items_of_type", value: "Biens > Type de biens" },
		{ key: "items_of_typeheating", value: "Biens > Type de chauffage" },
		{ key: "items_of_typecook", value: "Biens > Type de cuisine" },
		{ key: "items_bu_facade", value: "Biens > Type de façade" },
		{ key: "items_of_rent_typecharge", value: "Biens > Type de charge" },
		{ key: "items_of_typemandat", value: "Biens > Type de mandat" },
		{ key: "items_bu_roof", value: "Biens > Type de toit" },
		{ key: "items_of_urgency", value: "Biens > Urgence" }
	],
	items_languages_all: [
		{ key: "fr", value: "Français", pref: "french" },
		{ key: "en", value: "Anglais", pref: "english" },
		{ key: "it", value: "Italien", pref: "italian" },
		{ key: "ru", value: "Russe", pref: "russian" },
		{ key: "de", value: "Allemand", pref: "german" },
		{ key: "sp", value: "Espagnol", pref: "spanish" }
	],
	items_of_honoraires: [
		{ key: 1, value: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%" },
		{ key: 2, value: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%" },
		{ key: 3, value: "Honoraires d’Agence : 10% du Loyer Annuel + TVA" },
		{ key: 4, value: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%" },
		{ key: 5, value: "Frais de notaire réduits 2,5%" }
	],
	items_countries: [
		{ key: "monaco", value: "Monaco" },
		{ key: "france", value: "France" },
		{ key: "angleterre", value: "Angleterre" },
		{ key: "italie", value: "Italie" },
		{ key: "russie", value: "Russie" },
		{ key: "allemagne", value: "Allemagne" },
		{ key: "espagne", value: "Espagne" }
	],
	items_of_type: [
		{
			li_id: 83,
			li_name: "items_of_type",
			key: 1,
			value: "Appartement",
			options: "01-1",
			createdAt: "2019-11-12 19:22:29",
			updatedAt: "2021-10-27 12:12:03"
		},
		{
			li_id: 109,
			li_name: "items_of_type",
			key: 18,
			value: "Loft",
			options: "01-2",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:24:47"
		},
		{
			li_id: 112,
			li_name: "items_of_type",
			key: 21,
			value: "Penthouse/Roof",
			options: "01-3",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:25:01"
		},
		{
			li_id: 103,
			li_name: "items_of_type",
			key: 12,
			value: "Duplex",
			options: "01-4",
			createdAt: "2021-10-05 07:50:11",
			updatedAt: "2021-10-27 12:12:17"
		},
		{
			li_id: 104,
			li_name: "items_of_type",
			key: 13,
			value: "Rez-de-jardin",
			options: "01-5",
			createdAt: "2021-10-05 07:50:11",
			updatedAt: "2021-10-27 12:12:42"
		},
		{
			li_id: 102,
			li_name: "items_of_type",
			key: 11,
			value: "Chambre de service",
			options: "01-6",
			createdAt: "2021-10-05 07:50:11",
			updatedAt: "2021-10-27 12:13:38"
		},
		{
			li_id: 86,
			li_name: "items_of_type",
			key: 4,
			value: "Bureau",
			options: "05-21",
			createdAt: "2019-11-12 19:23:05",
			updatedAt: "2021-10-27 12:13:20"
		},
		{
			li_id: 98,
			li_name: "items_of_type",
			key: 7,
			value: "Cave",
			options: "06-22",
			createdAt: "2021-10-05 07:48:08",
			updatedAt: "2021-10-27 12:13:53"
		},
		{
			li_id: 88,
			li_name: "items_of_type",
			key: 6,
			value: "Local",
			options: "09-23",
			createdAt: "2019-11-12 19:23:42",
			updatedAt: "2021-10-27 12:14:53"
		},
		{
			li_id: 85,
			li_name: "items_of_type",
			key: 3,
			value: "Parking/Garage/Box",
			options: "11-24",
			createdAt: "2019-11-12 19:22:53",
			updatedAt: "2021-10-27 12:14:28"
		},
		{
			li_id: 153,
			li_name: "items_of_type",
			key: 26,
			value: "Garage",
			options: "11-25",
			createdAt: "2022-02-01 11:29:03",
			updatedAt: "2022-02-01 11:29:03"
		},
		{
			li_id: 154,
			li_name: "items_of_type",
			key: 27,
			value: "Box",
			options: "11-26",
			createdAt: "2022-02-01 11:30:55",
			updatedAt: "2022-02-01 11:30:55"
		},
		{
			li_id: 115,
			li_name: "items_of_type",
			key: 24,
			value: "Terrain constructible",
			options: "13-19",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:15:43"
		},
		{
			li_id: 155,
			li_name: "items_of_type",
			key: 28,
			value: "Terrain non constructible",
			options: "13-20",
			createdAt: "2022-02-01 11:30:55",
			updatedAt: "2022-02-01 11:30:55"
		},
		{
			li_id: 157,
			li_name: "items_of_type",
			key: 30,
			value: "Ferme",
			options: "14-10",
			createdAt: "2022-02-01 11:30:55",
			updatedAt: "2022-02-01 11:30:55"
		},
		{
			li_id: 158,
			li_name: "items_of_type",
			key: 31,
			value: "Mas",
			options: "14-13",
			createdAt: "2022-02-01 11:33:08",
			updatedAt: "2022-02-01 11:33:08"
		},
		{
			li_id: 159,
			li_name: "items_of_type",
			key: 32,
			value: "Propriété",
			options: "14-14",
			createdAt: "2022-02-01 11:33:09",
			updatedAt: "2022-02-01 11:33:09"
		},
		{
			li_id: 160,
			li_name: "items_of_type",
			key: 33,
			value: "Rez-de-villa",
			options: "14-15",
			createdAt: "2022-02-01 11:33:09",
			updatedAt: "2022-02-01 11:33:09"
		},
		{
			li_id: 84,
			li_name: "items_of_type",
			key: 2,
			value: "Villa",
			options: "14-16",
			createdAt: "2019-11-12 19:22:41",
			updatedAt: "2021-10-27 12:12:53"
		},
		{
			li_id: 161,
			li_name: "items_of_type",
			key: 34,
			value: "Chalet",
			options: "14-18",
			createdAt: "2022-02-01 11:33:09",
			updatedAt: "2022-02-01 11:33:09"
		},
		{
			li_id: 110,
			li_name: "items_of_type",
			key: 19,
			value: "Maison",
			options: "14-7",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:24:52"
		},
		{
			li_id: 99,
			li_name: "items_of_type",
			key: 8,
			value: "Bastide",
			options: "14-8",
			createdAt: "2021-10-05 07:49:20",
			updatedAt: "2021-10-27 12:15:12"
		},
		{
			li_id: 156,
			li_name: "items_of_type",
			key: 29,
			value: "Château",
			options: "14-9",
			createdAt: "2022-02-01 11:30:55",
			updatedAt: "2022-02-01 11:30:55"
		},
		{
			li_id: 105,
			li_name: "items_of_type",
			key: 14,
			value: "Fonds de commerce",
			options: "18-27",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:24:23"
		},
		{
			li_id: 101,
			li_name: "items_of_type",
			key: 10,
			value: "Cessions de droit au bail",
			options: "22-28",
			createdAt: "2021-10-05 07:50:11",
			updatedAt: "2021-10-27 12:25:25"
		},
		{
			li_id: 111,
			li_name: "items_of_type",
			key: 20,
			value: "Murs local commercial",
			options: "24-29",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:14:43"
		},
		{
			li_id: 87,
			li_name: "items_of_type",
			key: 5,
			value: "Autre produit",
			options: "31-30",
			createdAt: "2019-11-12 19:23:24",
			updatedAt: "2021-10-27 12:15:22"
		},
		{
			li_id: 107,
			li_name: "items_of_type",
			key: 16,
			value: "Immeuble",
			options: "31-31",
			createdAt: "2021-10-05 07:52:22",
			updatedAt: "2021-10-27 12:24:36"
		}
	],
	items_bu_district: [
		{
			li_id: 93,
			li_name: "items_bu_district",
			key: 0,
			value: "Aucun",
			options: "",
			createdAt: "2019-11-15 12:52:07",
			updatedAt: "2022-01-12 10:40:20"
		},
		{
			li_id: 118,
			li_name: "items_bu_district",
			key: 1,
			value: "Fontvieille",
			options: "1",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		},
		{
			li_id: 117,
			li_name: "items_bu_district",
			key: 15,
			value: "Condamine",
			options: "15",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		},
		{
			li_id: 122,
			li_name: "items_bu_district",
			key: 17,
			value: "Saint Roman",
			options: "17",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		},
		{
			li_id: 121,
			li_name: "items_bu_district",
			key: 18,
			value: "Port",
			options: "18",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		},
		{
			li_id: 94,
			li_name: "items_bu_district",
			key: 19,
			value: "Carré d'Or",
			options: "19",
			createdAt: "2020-01-27 13:33:55",
			updatedAt: "2020-01-27 13:33:55"
		},
		{
			li_id: 91,
			li_name: "items_bu_district",
			key: 2,
			value: "Larvotto",
			options: "2",
			createdAt: "2019-11-15 12:49:11",
			updatedAt: "2019-11-15 12:49:11"
		},
		{
			li_id: 92,
			li_name: "items_bu_district",
			key: 6,
			value: "Monaco-Ville",
			options: "6",
			createdAt: "2019-11-15 12:49:38",
			updatedAt: "2019-11-15 12:49:38"
		},
		{
			li_id: 119,
			li_name: "items_bu_district",
			key: 7,
			value: "Jardin Exotique",
			options: "7",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		},
		{
			li_id: 120,
			li_name: "items_bu_district",
			key: 8,
			value: "Monte-Carlo",
			options: "8",
			createdAt: "2021-10-14 14:33:23",
			updatedAt: "2021-10-14 14:33:23"
		}
	],
	items_of_city: [
		{ key: 1, xml: 1, pays: 2, value: "Antibes" },
		{ key: 2, xml: 20, pays: 2, value: "Beaulieu-sur-mer" },
		{ key: 3, xml: 9, pays: 2, value: "Beausoleil" },
		{ key: 4, xml: 18, pays: 2, value: "Cabris" },
		{ key: 5, xml: 3, pays: 2, value: "Cagnes sur mer" },
		{ key: 6, xml: 325, pays: 2, value: "Cannes" },
		{ key: 7, xml: 8, pays: 2, value: "Cap d'Ail" },
		{ key: 8, xml: 7, pays: 2, value: "Eze" },
		{ key: 9, xml: 326, pays: 2, value: "Eze bord de mer" },
		{ key: 10, xml: 215, pays: 2, value: "Eze Village" },
		{ key: 11, xml: 12, pays: 2, value: "Frejus" },
		{ key: 12, xml: 22, pays: 2, value: "Gorbio" },
		{ key: 13, xml: 39, pays: 2, value: "Grasse" },
		{ key: 14, xml: 2, pays: 2, value: "Juan les pins" },
		{ key: 15, xml: 14, pays: 2, value: "La Turbie" },
		{ key: 16, xml: 17, pays: 2, value: "Le Mas" },
		{ key: 17, xml: 10, pays: 2, value: "Menton" },
		{ key: 18, xml: 36, pays: 2, value: "Miami Beach Florida" },
		{ key: 19, xml: 4, pays: 1, value: "Monaco" },
		{ key: 20, xml: 25, pays: 2, value: "Mouans Sartoux" },
		{ key: 21, xml: 19, pays: 2, value: "Mougins" },
		{ key: 22, xml: 6, pays: 2, value: "Nice" },
		{ key: 23, xml: 16, pays: 2, value: "Peille" },
		{ key: 24, xml: 13, pays: 2, value: "Roquebrune Cap Martin" },
		{ key: 25, xml: 324, pays: 2, value: "Roquebrune Village" },
		{ key: 26, xml: 15, pays: 2, value: "Saint Jean Cap Ferrat" },
		{ key: 27, xml: 44, pays: 2, value: "Saint Martin de Peille" },
		{ key: 28, xml: 11, pays: 2, value: "Saint Raphael" },
		{ key: 29, xml: 23, pays: 2, value: "Saint-Agnes" },
		{ key: 30, xml: 327, pays: 2, value: "Saint-Laurent-d'Eze" },
		{ key: 31, xml: 24, pays: 2, value: "Saint-Paul-de-Vence" },
		{ key: 32, xml: 253, pays: 2, value: "St Tropez" },
		{ key: 33, xml: 21, value: "Villefranche-sur-mer" }
	],
	items_of_status: [
		{ key: 1, value: "Actif" },
		{ key: 3, value: "Actif : Loué !" },
		{ key: 4, value: "Actif : Vendu !" },
		{ key: 5, value: "Actif : Sous-offre !" },
		{ key: 6, value: "Archivé" },
		{ key: 11, value: "Archivé : vendu par l'agence" },
		{ key: 12, value: "Archivé : vendu par le propriétaire" },
		{ key: 13, value: "Archivé : vendu par un confrère" },
		{ key: 14, value: "Archivé : vendu commission partagée" },
		{ key: 15, value: "Archivé : vendu aux enchères" },
		{ key: 16, value: "Archivé : vendu autre" },
		{ key: 17, value: "Archivé : mandat perdu" },
		{ key: 18, value: "Supprimé" }
	],
	items_of_web: [
		{ key: 0, value: "Non" },
		{ key: 1, value: "Oui" },
		{ key: 2, value: "Oui - Page d'accueil" }
	],
	items_of_ci: [
		{ key: 0, value: "Non" },
		{ key: 1, value: "Oui" }
	],
	items_of_disponibility: [
		{ key: 0, value: "Disponible immédiatement" },
		{ key: 1, value: "Actuellement loué" },
		{ key: 2, value: "Actuellement habité par propriétaire" },
		{ key: 3, value: "Actuellement occupé" },
		{ key: 4, value: "Travaux" }
	],
	items_of_diffusions: [
		{ label: "Site public Monte-Carlo Real Estate", key: 1, valuetxt: "realestatemontecarlo", valueimmotool: 1 },
		{ label: "Intranet Monte-Carlo Real Estate - MarketPlace ITB Monaco", key: 2, valuetxt: "marketplace", valueimmotool: 42 },
		{ label: "Nice Matin", key: 4, valuetxt: "nice-matin", valueimmotool: 40 },
		{ label: "Site public de la C.I.M", key: 8, valuetxt: "public-cim", valueimmotool: 2 },
		{ label: "Intranet privé de la C.I.M", key: 16, valuetxt: "private-cim", valueimmotool: 41 },
		{ label: "Encart sur nos sites", key: 32, valuetxt: "encart-sur-nos-sites", valueimmotool: 45 },
		{ label: "Site vente-appartement-monaco.com et associés", key: 64, valuetxt: "vente-appartement-monaco", valueimmotool: 55 },
		{ label: "Site Monte-Carlo.mc", key: 128, valuetxt: "site-monte-carlo", valueimmotool: 60 },
		{ label: "Résidences Immobilier", key: 256, valuetxt: "residences-immobilier", valueimmotool: 51 },
		{ label: "Vitrine SMB/SkySignage", key: 512, valuetxt: "vitrine-smb-skysignage", valueimmotool: 67 }
	],
	items_of_services: [
		{
			label_fr: "Appartement penthouse",
			label_en: "Penthouse apartment",
			label_it: "Appartamento attico",
			key: 1,
			valuetxt: "appartement-penthouse",
			icon: "icons8-parking-et-penthouse-100.png"
		},
		{
			label_fr: "Avec parking/box",
			label_en: "with car park/box",
			label_it: "Con parcheggio/box",
			key: 2048,
			valuetxt: "avec-parking-box",
			icon: "icons8-location-de-voiture-100.png"
		},
		{
			label_fr: "Avec terrasse",
			label_en: "With terrasse",
			label_it: "Con terrazza",
			key: 1024,
			valuetxt: "avec-terrasse",
			icon: "icons8-balcon-100.png"
		},
		{
			label_fr: "Avec vue",
			label_en: "With view",
			label_it: "Con vista",
			key: 512,
			valuetxt: "avec-vue",
			icon: "icons8-vue-panoramique-90.png"
		},
		{
			label_fr: "Bibliothèque",
			label_en: "Library",
			label_it: "Biblioteca",
			key: 16777216,
			valuetxt: "bibliotheque",
			icon: "icons8-etagere-a-livres-100.png"
		},
		{
			label_fr: "Bien exceptionnel",
			label_en: "Very exceptional",
			label_it: "Molto eccezionale",
			key: 134217728,
			valuetxt: "bien-exceptionnel",
			icon: "icons8-diamant-100.png"
		},
		{
			label_fr: "Bureau/Commerce",
			label_en: "Office / Commerce",
			label_it: "Ufficio / Commercio",
			key: 4096,
			valuetxt: "bureau-commerce",
			icon: "icons8-stylo-a-bille-100.png"
		},
		{
			label_fr: "Cave à vin",
			label_en: "Wine cellar",
			label_it: "Cantina",
			key: 8388608,
			valuetxt: "cave-a-vin",
			icon: "icons8-bouteille-de-vin-100.png"
		},
		{
			label_fr: "Concierge",
			label_en: "Concierge",
			label_it: "Concierge",
			key: 16,
			valuetxt: "concierge",
			icon: "icons8-cle-100.png"
		},
		{
			label_fr: "Développement neuf",
			label_en: "New development",
			label_it: "Nuovo sviluppo",
			key: 2,
			valuetxt: "developpement-neuf",
			icon: "icons8-ouvrier-du-batiment-100.png"
		},
		{
			label_fr: "Garage",
			label_en: "Garage",
			label_it: "Box auto",
			key: 262144,
			valuetxt: "garage",
			icon: "icons8-garage-100.png"
		},
		{
			label_fr: "Hammam",
			label_en: "Hammam",
			label_it: "Hammam",
			key: 536870912,
			valuetxt: "hammam",
			icon: "icons8-bains-publics-100.png"
		},
		{
			label_fr: "Immeuble bourgeois",
			label_en: "Bourgeois building",
			label_it: "Palazzo borghese",
			key: 64,
			valuetxt: "immeuble-bourgeois",
			icon: "icons8-residence-100.png"
		},
		{
			label_fr: "Immeuble moderne",
			label_en: "Modern apart. build.",
			label_it: "Condominio moderno",
			key: 8,
			valuetxt: "immeuble-moderne",
			icon: "icons8-appartement-100.png"
		},
		{
			label_fr: "Jacuzzi",
			label_en: "Jacuzzi",
			label_it: "vasca idromassaggio",
			key: 1073741824,
			valuetxt: "jacuzzi",
			icon: "icons8-jacuzzi-64.png"
		},
		{
			label_fr: "Jardin",
			label_en: "Garden",
			label_it: "giardino",
			key: 8589934592,
			valuetxt: "garden",
			icon: "icons8-fleur-100.png"
		},
		{
			label_fr: "Location Professionnelle",
			label_en: "Professional Rental",
			label_it: "Noleggio professionale",
			key: 137438953472,
			valuetxt: "professional-rental",
			icon: "icons8-fleur-100.png"
		},
		{
			label_fr: "Location Saisonnière",
			label_en: "Seasonal rental",
			label_it: "Affitto stagionale",
			key: 68719476736,
			valuetxt: "seasonal-rental",
			icon: "icons8-fleur-100.png"
		},
		{
			label_fr: "Maison d'amis",
			label_en: "Guest house",
			label_it: "Pensione",
			key: 524288,
			valuetxt: "maison-d-amis",
			icon: "icons8-maison-avec-jardin-100.png"
		},
		{
			label_fr: "Maison de gardien",
			label_en: "Caretaker's house",
			label_it: "Casa del custode",
			key: 1048576,
			valuetxt: "maison-de-gardien",
			icon: "icons8-securite-publique-100.png"
		},
		{
			label_fr: "Maison individuelle",
			label_en: "Individual house",
			label_it: "Casa individuale",
			key: 4,
			valuetxt: "maison-individuelle",
			icon: "icons8-chalet-100.png"
		},
		{
			label_fr: "Meublé",
			label_en: "Furniture",
			label_it: "Arredamento",
			key: 256,
			valuetxt: "meuble",
			icon: "icons8-bureau-100.png"
		},
		{
			label_fr: "Parking",
			label_en: "Car park",
			label_it: "Parcheggio",
			key: 32,
			valuetxt: "parking",
			icon: "icons8-location-de-voiture-100.png"
		},
		{
			label_fr: "Pieds dans l'eau",
			label_en: "Feet in the water",
			label_it: "Piedi nell'acqua",
			key: 67108864,
			valuetxt: "pieds-dans-l-eau",
			icon: "icons8-wave-lines-100.png"
		},
		{
			label_fr: "Piscine",
			label_en: "swimming pool",
			label_it: "Piscina",
			key: 16384,
			valuetxt: "piscine",
			icon: "icons8-natation-100.png"
		},
		{
			label_fr: "Piscine extérieure",
			label_en: "Outdoor pool",
			label_it: "Piscina all'aperto",
			key: 65536,
			valuetxt: "piscine-exterieure",
			icon: "icons8-piscine-100.png"
		},
		{
			label_fr: "Piscine intérieure",
			label_en: "Intdoor pool",
			label_it: "Piscina al coperto",
			key: 131072,
			valuetxt: "piscine-interieure",
			icon: "icons8-natation-100.png"
		},
		{
			label_fr: "Pool house",
			label_en: "Pool house",
			label_it: "Pool house",
			key: 2097152,
			valuetxt: "pool-house",
			icon: "icons8-niche-pour-chien-100.png"
		},
		{
			label_fr: "Port privé/jetée",
			label_en: "Private port / jetty",
			label_it: "Porto / molo privato",
			key: 268435456,
			valuetxt: "port-prive-jetee",
			icon: "icons8-ancre-100.png"
		},
		{
			label_fr: "Salle de gymnastique",
			label_en: "Gym hall",
			label_it: "Palestra",
			key: 4194304,
			valuetxt: "salle-de-gymnastique",
			icon: "icons8-appareil-a-step-100.png"
		},
		{
			label_fr: "Sous loi 1235",
			label_en: "Law 1235",
			label_it: "Ai sensi della legge 1235",
			key: 2147483648,
			valuetxt: "sousloi1235",
			icon: "icons8-loi-100.png"
		},
		{
			label_fr: "Sous loi 1291",
			label_en: "Law 1291",
			label_it: "Ai sensi della legge 1291",
			key: 274877906944,
			valuetxt: "sousloi1235",
			icon: "icons8-loi-100.png"
		},
		{
			label_fr: "Sous loi 887",
			label_en: "Law 887",
			label_it: "Ai sensi della legge 887",
			key: 4294967296,
			valuetxt: "sousloi887",
			icon: "icons8-loi-100.png"
		},
		{
			label_fr: "Sécurité",
			label_en: "Security",
			label_it: "Sicurezza",
			key: 8192,
			valuetxt: "securite",
			icon: "icons8-cadenas-100.png"
		},
		{ label_fr: "Tennis", label_en: "Tennis", label_it: "Tennis", key: 32768, valuetxt: "tennis", icon: "icons8-tennis-100.png" },
		{
			label_fr: "Usage mixte",
			label_en: "Mixed use",
			label_it: "Uso misto",
			key: 128,
			valuetxt: "usage-mixte",
			icon: "icons8-nouveau-magasin-100.png"
		},
		{
			label_fr: "Vente Professionnelle",
			label_en: "Professional sale",
			label_it: "Vendita professionale",
			key: 17179869184,
			valuetxt: "professional-sale",
			icon: "icons8-fleur-100.png"
		},
		{
			label_fr: "Vente Viager",
			label_en: "Life annuity",
			label_it: "Rendita vitalizia",
			key: 34359738368,
			valuetxt: "viager",
			icon: "icons8-fleur-100.png"
		},
		{
			label_fr: "Vue mer",
			label_en: "Sea view",
			label_it: "Vista sul mare",
			key: 33554432,
			valuetxt: "vue-mer",
			icon: "icons8-bateau-a-voile-100.png"
		}
	],
	items_of_sign: [
		{
			li_id: 7,
			li_name: "items_of_sign",
			key: 0,
			value: "Aucun",
			options: "",
			createdAt: "2019-11-12 13:47:15",
			updatedAt: "2019-11-12 13:47:15"
		},
		{
			li_id: 8,
			li_name: "items_of_sign",
			key: 1,
			value: "A vendre",
			options: "",
			createdAt: "2019-11-12 13:47:32",
			updatedAt: "2019-11-12 13:47:32"
		},
		{
			li_id: 9,
			li_name: "items_of_sign",
			key: 2,
			value: "A louer",
			options: "",
			createdAt: "2019-11-12 13:47:53",
			updatedAt: "2019-11-12 13:47:53"
		},
		{
			li_id: 10,
			li_name: "items_of_sign",
			key: 3,
			value: "Vendu",
			options: "",
			createdAt: "2019-11-12 13:48:15",
			updatedAt: "2019-11-12 13:48:15"
		},
		{
			li_id: 11,
			li_name: "items_of_sign",
			key: 4,
			value: "Loué",
			options: "",
			createdAt: "2019-11-12 13:48:28",
			updatedAt: "2019-11-12 13:48:28"
		}
	],
	items_of_classeenergie: [
		{
			li_id: 12,
			li_name: "items_of_classeenergie",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 13:49:50",
			updatedAt: "2019-11-12 13:49:50"
		},
		{
			li_id: 13,
			li_name: "items_of_classeenergie",
			key: 1,
			value: "A",
			options: "",
			createdAt: "2019-11-12 14:41:21",
			updatedAt: "2019-11-12 14:41:21"
		},
		{
			li_id: 14,
			li_name: "items_of_classeenergie",
			key: 2,
			value: "B",
			options: "",
			createdAt: "2019-11-12 14:41:34",
			updatedAt: "2019-11-12 14:41:34"
		},
		{
			li_id: 15,
			li_name: "items_of_classeenergie",
			key: 3,
			value: "C",
			options: "",
			createdAt: "2019-11-12 14:41:44",
			updatedAt: "2019-11-12 14:41:44"
		},
		{
			li_id: 16,
			li_name: "items_of_classeenergie",
			key: 4,
			value: "D",
			options: "",
			createdAt: "2019-11-12 14:41:58",
			updatedAt: "2019-11-12 14:41:58"
		},
		{
			li_id: 17,
			li_name: "items_of_classeenergie",
			key: 5,
			value: "E",
			options: "",
			createdAt: "2019-11-12 14:42:08",
			updatedAt: "2019-11-12 14:42:08"
		},
		{
			li_id: 18,
			li_name: "items_of_classeenergie",
			key: 6,
			value: "F",
			options: "",
			createdAt: "2019-11-12 14:42:19",
			updatedAt: "2019-11-12 14:42:19"
		},
		{
			li_id: 19,
			li_name: "items_of_classeenergie",
			key: 7,
			value: "G",
			options: "",
			createdAt: "2019-11-12 14:42:29",
			updatedAt: "2019-11-12 14:42:29"
		}
	],
	items_of_classegaes: [],
	items_of_typemandat: [
		{
			li_id: 20,
			li_name: "items_of_typemandat",
			key: 0,
			value: "Mandat de vente",
			options: "",
			createdAt: "2019-11-12 14:43:04",
			updatedAt: "2019-11-12 14:43:04"
		},
		{
			li_id: 21,
			li_name: "items_of_typemandat",
			key: 1,
			value: "Mandat de commercialisation",
			options: "",
			createdAt: "2019-11-12 14:43:19",
			updatedAt: "2019-11-12 14:43:19"
		},
		{
			li_id: 22,
			li_name: "items_of_typemandat",
			key: 2,
			value: "Mandat d'acheter",
			options: "",
			createdAt: "2019-11-12 14:47:19",
			updatedAt: "2019-11-12 14:47:19"
		},
		{
			li_id: 23,
			li_name: "items_of_typemandat",
			key: 3,
			value: "Mandat de gestion locative",
			options: "",
			createdAt: "2019-11-12 14:47:41",
			updatedAt: "2019-11-12 14:47:41"
		},
		{
			li_id: 24,
			li_name: "items_of_typemandat",
			key: 4,
			value: "Mandat de syndic de copropriété",
			options: "",
			createdAt: "2019-11-12 14:47:54",
			updatedAt: "2019-11-12 14:47:54"
		}
	],
	items_of_exclusivite: [
		{
			li_id: 123,
			li_name: "items_of_exclusivite",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2021-10-19 17:51:18",
			updatedAt: "2021-10-19 17:51:18"
		},
		{
			li_id: 25,
			li_name: "items_of_exclusivite",
			key: 1,
			value: "Exclusivité",
			options: "",
			createdAt: "2019-11-12 14:50:55",
			updatedAt: "2021-10-19 09:33:02"
		},
		{
			li_id: 27,
			li_name: "items_of_exclusivite",
			key: 2,
			value: "Co-exclusivité",
			options: "",
			createdAt: "2019-11-12 14:51:24",
			updatedAt: "2021-10-19 09:33:25"
		}
	],
	items_of_urgency: [
		{
			li_id: 29,
			li_name: "items_of_urgency",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 14:52:16",
			updatedAt: "2019-11-12 14:52:16"
		},
		{
			li_id: 30,
			li_name: "items_of_urgency",
			key: 1,
			value: "Non",
			options: "",
			createdAt: "2019-11-12 14:52:29",
			updatedAt: "2019-11-12 14:52:29"
		},
		{
			li_id: 31,
			li_name: "items_of_urgency",
			key: 2,
			value: "Oui",
			options: "",
			createdAt: "2019-11-12 14:52:40",
			updatedAt: "2019-11-12 14:52:40"
		},
		{
			li_id: 32,
			li_name: "items_of_urgency",
			key: 3,
			value: "Crédit relais",
			options: "",
			createdAt: "2019-11-12 14:52:52",
			updatedAt: "2019-11-12 14:52:52"
		},
		{
			li_id: 33,
			li_name: "items_of_urgency",
			key: 4,
			value: "1 mois",
			options: "",
			createdAt: "2019-11-12 14:53:06",
			updatedAt: "2019-11-12 14:53:06"
		},
		{
			li_id: 34,
			li_name: "items_of_urgency",
			key: 5,
			value: "3 mois",
			options: "",
			createdAt: "2019-11-12 14:53:20",
			updatedAt: "2019-11-12 14:53:20"
		},
		{
			li_id: 35,
			li_name: "items_of_urgency",
			key: 6,
			value: "6 mois",
			options: "",
			createdAt: "2019-11-12 14:53:34",
			updatedAt: "2019-11-12 14:53:34"
		},
		{
			li_id: 36,
			li_name: "items_of_urgency",
			key: 7,
			value: "1 an",
			options: "",
			createdAt: "2019-11-12 14:53:45",
			updatedAt: "2019-11-12 14:53:45"
		}
	],
	items_of_typecook: [
		{
			li_id: 37,
			li_name: "items_of_typecook",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 14:54:36",
			updatedAt: "2019-11-12 14:54:36"
		},
		{
			li_id: 38,
			li_name: "items_of_typecook",
			key: 1,
			value: "Cuisine indépendante",
			options: "",
			createdAt: "2019-11-12 14:54:46",
			updatedAt: "2019-11-12 14:54:46"
		},
		{
			li_id: 39,
			li_name: "items_of_typecook",
			key: 2,
			value: "Cuisine américaine",
			options: "",
			createdAt: "2019-11-12 14:54:56",
			updatedAt: "2019-11-12 14:54:56"
		}
	],
	items_of_notary: [
		{
			li_id: 97,
			li_name: "items_of_notary",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2020-08-17 18:10:44",
			updatedAt: "2020-08-17 18:10:44"
		},
		{
			li_id: 95,
			li_name: "items_of_notary",
			key: 1,
			value: "Me Aureglia - Caruso",
			options: "",
			createdAt: "2020-08-17 18:07:52",
			updatedAt: "2021-12-07 13:25:20"
		},
		{
			li_id: 96,
			li_name: "items_of_notary",
			key: 2,
			value: "Me Rey",
			options: "",
			createdAt: "2020-08-17 18:08:07",
			updatedAt: "2021-12-07 13:25:34"
		},
		{
			li_id: 124,
			li_name: "items_of_notary",
			key: 3,
			value: "Me Crovetto Aquilina",
			options: "",
			createdAt: "2021-12-07 13:24:29",
			updatedAt: "2022-01-10 12:19:51"
		}
	],
	items_of_typeheating: [
		{
			li_id: 42,
			li_name: "items_of_typeheating",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 14:59:35",
			updatedAt: "2019-11-12 15:00:00"
		},
		{
			li_id: 40,
			li_name: "items_of_typeheating",
			key: 1,
			value: "Collectif",
			options: "",
			createdAt: "2019-11-12 14:56:40",
			updatedAt: "2019-11-12 15:00:08"
		},
		{
			li_id: 41,
			li_name: "items_of_typeheating",
			key: 2,
			value: "Individuel",
			options: "",
			createdAt: "2019-11-12 14:58:46",
			updatedAt: "2019-11-12 15:00:17"
		}
	],
	items_of_natureheating: [
		{
			li_id: 43,
			li_name: "items_of_natureheating",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 15:00:38",
			updatedAt: "2019-11-12 15:00:38"
		},
		{
			li_id: 44,
			li_name: "items_of_natureheating",
			key: 1,
			value: "Bois",
			options: "",
			createdAt: "2019-11-12 15:01:01",
			updatedAt: "2019-11-12 15:01:01"
		},
		{
			li_id: 45,
			li_name: "items_of_natureheating",
			key: 2,
			value: "Gaz",
			options: "",
			createdAt: "2019-11-12 15:01:13",
			updatedAt: "2019-11-12 15:01:13"
		},
		{
			li_id: 46,
			li_name: "items_of_natureheating",
			key: 3,
			value: "Fioul",
			options: "",
			createdAt: "2019-11-12 15:01:24",
			updatedAt: "2019-11-12 15:01:24"
		},
		{
			li_id: 47,
			li_name: "items_of_natureheating",
			key: 4,
			value: "Electrique",
			options: "",
			createdAt: "2019-11-12 15:01:39",
			updatedAt: "2019-11-12 15:01:39"
		},
		{
			li_id: 48,
			li_name: "items_of_natureheating",
			key: 5,
			value: "Autre",
			options: "",
			createdAt: "2019-11-12 15:01:51",
			updatedAt: "2019-11-12 15:01:51"
		}
	],
	items_of_typeboiler: [
		{
			li_id: 53,
			li_name: "items_of_typeboiler",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 15:30:48",
			updatedAt: "2019-11-12 15:30:48"
		},
		{
			li_id: 49,
			li_name: "items_of_typeboiler",
			key: 1,
			value: "Chaudière",
			options: "",
			createdAt: "2019-11-12 15:29:33",
			updatedAt: "2019-11-12 15:29:33"
		},
		{
			li_id: 50,
			li_name: "items_of_typeboiler",
			key: 2,
			value: "Collectif",
			options: "",
			createdAt: "2019-11-12 15:30:05",
			updatedAt: "2019-11-12 15:30:05"
		},
		{
			li_id: 51,
			li_name: "items_of_typeboiler",
			key: 3,
			value: "Chauffe eau",
			options: "",
			createdAt: "2019-11-12 15:30:17",
			updatedAt: "2019-11-12 15:30:17"
		},
		{
			li_id: 52,
			li_name: "items_of_typeboiler",
			key: 4,
			value: "Autre",
			options: "",
			createdAt: "2019-11-12 15:30:33",
			updatedAt: "2019-11-12 15:30:33"
		}
	],
	items_of_typesubscription: [
		{
			li_id: 133,
			li_name: "items_of_typesubscription",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2022-01-10 08:41:42",
			updatedAt: "2022-01-10 08:42:06"
		},
		{
			li_id: 134,
			li_name: "items_of_typesubscription",
			key: 1,
			value: "Compteur individuel",
			options: "",
			createdAt: "2022-01-10 08:42:37",
			updatedAt: "2022-01-10 08:42:37"
		},
		{
			li_id: 135,
			li_name: "items_of_typesubscription",
			key: 2,
			value: "Inclus dans les charges",
			options: "",
			createdAt: "2022-01-10 08:42:57",
			updatedAt: "2022-01-10 08:42:57"
		}
	],
	items_of_generalstate: [
		{
			li_id: 54,
			li_name: "items_of_generalstate",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:11:40",
			updatedAt: "2019-11-12 17:11:40"
		},
		{
			li_id: 55,
			li_name: "items_of_generalstate",
			key: 1,
			value: "Bon état",
			options: "Bon état",
			createdAt: "2019-11-12 17:11:57",
			updatedAt: "2022-01-19 08:57:45"
		},
		{
			li_id: 125,
			li_name: "items_of_generalstate",
			key: 3,
			value: "Très bon état",
			options: "Très bon état",
			createdAt: "2021-12-07 13:29:30",
			updatedAt: "2022-01-19 08:58:05"
		},
		{
			li_id: 144,
			li_name: "items_of_generalstate",
			key: 4,
			value: "Travaux à prévoir",
			options: "Travaux à prévoir",
			createdAt: "2022-01-14 15:10:48",
			updatedAt: "2022-01-14 15:11:16"
		},
		{
			li_id: 145,
			li_name: "items_of_generalstate",
			key: 5,
			value: "À rafraichir",
			options: "À rafraichir",
			createdAt: "2022-01-14 15:11:41",
			updatedAt: "2022-01-14 15:11:41"
		},
		{
			li_id: 146,
			li_name: "items_of_generalstate",
			key: 6,
			value: "Rénové",
			options: "Rénové",
			createdAt: "2022-01-14 15:11:56",
			updatedAt: "2022-01-14 15:11:56"
		},
		{
			li_id: 147,
			li_name: "items_of_generalstate",
			key: 7,
			value: "Prestations luxueuses",
			options: "Prestations luxueuses",
			createdAt: "2022-01-14 15:14:59",
			updatedAt: "2022-01-14 15:14:59"
		},
		{
			li_id: 148,
			li_name: "items_of_generalstate",
			key: 8,
			value: "Neuf",
			options: "Neuf",
			createdAt: "2022-01-14 15:15:13",
			updatedAt: "2022-01-14 15:15:13"
		}
	],
	items_of_sunny: [
		{
			li_id: 126,
			li_name: "items_of_sunny",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2022-01-10 08:31:14",
			updatedAt: "2022-01-12 10:45:57"
		},
		{
			li_id: 130,
			li_name: "items_of_sunny",
			key: 1,
			value: "Lumineux",
			options: "",
			createdAt: "2022-01-10 08:32:12",
			updatedAt: "2022-01-10 08:39:28"
		},
		{
			li_id: 142,
			li_name: "items_of_sunny",
			key: 2,
			value: "Trés lumineux",
			options: "",
			createdAt: "2022-01-12 10:34:26",
			updatedAt: "2022-01-12 10:34:26"
		},
		{
			li_id: 143,
			li_name: "items_of_sunny",
			key: 3,
			value: "Pas lumineux",
			options: "",
			createdAt: "2022-01-12 10:34:38",
			updatedAt: "2022-01-12 10:34:38"
		}
	],
	items_of_exposition: [
		{
			li_id: 57,
			li_name: "items_of_exposition",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:12:23",
			updatedAt: "2022-01-10 08:29:19"
		},
		{
			li_id: 58,
			li_name: "items_of_exposition",
			key: 1,
			value: "Nord",
			options: "",
			createdAt: "2019-11-12 17:12:32",
			updatedAt: "2022-01-10 08:29:33"
		},
		{
			li_id: 59,
			li_name: "items_of_exposition",
			key: 2,
			value: "Nord/Est",
			options: "",
			createdAt: "2019-11-12 17:12:44",
			updatedAt: "2022-01-10 08:29:45"
		},
		{
			li_id: 60,
			li_name: "items_of_exposition",
			key: 3,
			value: "Est",
			options: "",
			createdAt: "2019-11-12 17:12:56",
			updatedAt: "2022-01-10 08:29:55"
		},
		{
			li_id: 61,
			li_name: "items_of_exposition",
			key: 4,
			value: "Sud/Est",
			options: "",
			createdAt: "2019-11-12 17:13:06",
			updatedAt: "2022-01-10 08:30:13"
		},
		{
			li_id: 62,
			li_name: "items_of_exposition",
			key: 5,
			value: "Sud",
			options: "",
			createdAt: "2019-11-12 17:13:17",
			updatedAt: "2022-01-10 08:30:22"
		},
		{
			li_id: 63,
			li_name: "items_of_exposition",
			key: 6,
			value: "Sud/Ouest",
			options: "",
			createdAt: "2019-11-12 17:13:28",
			updatedAt: "2022-01-10 08:30:30"
		},
		{
			li_id: 64,
			li_name: "items_of_exposition",
			key: 7,
			value: "Ouest",
			options: "",
			createdAt: "2019-11-12 17:13:37",
			updatedAt: "2022-01-10 08:30:37"
		},
		{
			li_id: 65,
			li_name: "items_of_exposition",
			key: 8,
			value: "Nord/Ouest",
			options: "",
			createdAt: "2019-11-12 17:13:49",
			updatedAt: "2022-01-10 08:30:45"
		}
	],
	items_of_rent_typecharge: [
		{
			li_id: 139,
			li_name: "items_of_rent_typecharge",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2022-01-10 11:14:20",
			updatedAt: "2022-01-10 11:14:20"
		},
		{
			li_id: 136,
			li_name: "items_of_rent_typecharge",
			key: 1,
			value: "Forfaitaire",
			options: "",
			createdAt: "2022-01-10 11:11:38",
			updatedAt: "2022-01-10 11:11:38"
		},
		{
			li_id: 137,
			li_name: "items_of_rent_typecharge",
			key: 2,
			value: "Incluse",
			options: "",
			createdAt: "2022-01-10 11:12:23",
			updatedAt: "2022-01-10 11:12:23"
		},
		{
			li_id: 138,
			li_name: "items_of_rent_typecharge",
			key: 3,
			value: "Provisionnelle",
			options: "",
			createdAt: "2022-01-10 11:13:02",
			updatedAt: "2022-01-10 11:13:02"
		}
	],
	items_of_rent_regimebail: [
		{
			li_id: 66,
			li_name: "items_of_rent_regimebail",
			key: 0,
			value: "Bail d'habitation",
			options: "",
			createdAt: "2019-11-12 17:14:07",
			updatedAt: "2019-11-12 17:14:07"
		},
		{
			li_id: 67,
			li_name: "items_of_rent_regimebail",
			key: 1,
			value: "Bail à usage mixte",
			options: "",
			createdAt: "2019-11-12 17:14:17",
			updatedAt: "2019-11-12 17:14:17"
		},
		{
			li_id: 68,
			li_name: "items_of_rent_regimebail",
			key: 2,
			value: "Bail à usage de bureau",
			options: "",
			createdAt: "2019-11-12 17:14:28",
			updatedAt: "2019-11-12 17:14:28"
		},
		{
			li_id: 69,
			li_name: "items_of_rent_regimebail",
			key: 3,
			value: "Contrat de location",
			options: "",
			createdAt: "2019-11-12 17:14:38",
			updatedAt: "2019-11-12 17:14:38"
		}
	],
	items_of_keywords: [
		{ key: "Air conditionné", value: "Air conditionné" },
		{ key: "Alcove", value: "Alcove" },
		{ key: "Appartement entièrement climatisé", value: "Appartement entièrement climatisé" },
		{ key: "Appartement rénové avec de beaux matériaux", value: "Appartement rénové avec de beaux matériaux" },
		{ key: "Balcon", value: "Balcon" },
		{ key: "Balcons", value: "Balcons" },
		{ key: "Belle vue mer", value: "Belle vue mer" },
		{ key: "Bow Windows", value: "Bow Windows" },
		{ key: "Cave", value: "Cave" },
		{ key: "Climatisation dans le séjour", value: "Climatisation dans le séjour" },
		{ key: "Climatisation dans les chambres uniquement", value: "Climatisation dans les chambres uniquement" },
		{ key: "Cuisine américaine", value: "Cuisine américaine" },
		{ key: "Cuisine équipée", value: "Cuisine équipée" }
	],
	items_bu_standing: [
		{
			li_id: 70,
			li_name: "items_bu_standing",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:14:54",
			updatedAt: "2019-11-12 17:14:54"
		},
		{
			li_id: 72,
			li_name: "items_bu_standing",
			key: 2,
			value: "Immeuble Ancien",
			options: "Ancien",
			createdAt: "2019-11-12 17:15:19",
			updatedAt: "2022-01-19 09:01:17"
		},
		{
			li_id: 149,
			li_name: "items_bu_standing",
			key: 3,
			value: "Immeuble Récent",
			options: "Récent",
			createdAt: "2022-01-14 15:19:45",
			updatedAt: "2022-01-19 09:01:52"
		},
		{
			li_id: 150,
			li_name: "items_bu_standing",
			key: 4,
			value: "Immeuble Bourgeois",
			options: "Bourgeois",
			createdAt: "2022-01-14 15:20:09",
			updatedAt: "2022-01-19 09:02:22"
		},
		{
			li_id: 151,
			li_name: "items_bu_standing",
			key: 5,
			value: "Immeuble de Standing",
			options: "Standing",
			createdAt: "2022-01-14 15:20:33",
			updatedAt: "2022-01-19 09:02:42"
		},
		{
			li_id: 152,
			li_name: "items_bu_standing",
			key: 6,
			value: "Immeuble de très haut Standing",
			options: "Très haut Standing",
			createdAt: "2022-01-14 15:21:03",
			updatedAt: "2022-01-19 09:03:03"
		}
	],
	items_bu_facade: [
		{
			li_id: 77,
			li_name: "items_bu_facade",
			key: 0,
			value: "Choisir",
			options: "",
			createdAt: "2019-11-12 17:28:19",
			updatedAt: "2019-11-12 17:28:19"
		},
		{
			li_id: 78,
			li_name: "items_bu_facade",
			key: 1,
			value: "Béton",
			options: "",
			createdAt: "2019-11-12 17:28:33",
			updatedAt: "2019-11-12 17:28:33"
		},
		{
			li_id: 79,
			li_name: "items_bu_facade",
			key: 2,
			value: "Pierre",
			options: "",
			createdAt: "2019-11-12 17:28:48",
			updatedAt: "2019-11-12 17:28:48"
		}
	],
	items_frame_details: [
		{ label: "Agence", key: "of_agency_txt" },
		{ label: "Mise à jour", key: "updatedAt", filter: "formatDateTime" },
		{ label: "Type de bien", key: "of_type", filter: "formatFromArray", filtermore: "items_of_type" },
		{ label: "Référence", key: "of_ref" },
		{ label: "Surf. totale", key: "of_surface", filter: "formatSurface" },
		{ label: "Surf. habitable", key: "of_surface_livable", filter: "formatSurface" },
		{ label: "Surf. approximative", key: "of_surface_approximate", filter: "formatYesNo" },
		{ label: "Surf. jardin", key: "of_surface_garden", filter: "formatSurface" },
		{ label: "Surf. terrain", key: "of_surface_terrain", filter: "formatSurface" },
		{ label: "Surf. terrasse", key: "of_surface_terrace", filter: "formatSurface" },
		{ label: "Etage", key: "of_etage" },
		{ label: "Nb pièces", key: "of_nbpieces" },
		{ label: "Nb caves", key: "of_nbcaves" },
		{ label: "Nb balcons", key: "of_nbbalconies" },
		{ label: "Nb chambres", key: "of_nbrooms" },
		{ label: "Nb parkings", key: "of_nbparkings" },
		{ label: "Nb box", key: "of_nbboxes" },
		{ label: "Nb s. de bain", key: "of_nbbathrooms" },
		{ label: "Nb WC", key: "of_nbwc" },
		{ label: "Nb niveaux", key: "of_nbfloor" },
		{ label: "Type de cuisine", key: "of_typecook", filter: "formatFromArray", filtermore: "items_of_typecook" },
		{ label: "Type de chauffage", key: "of_typeheating", filter: "formatFromArray", filtermore: "items_of_typeheating" },
		{ label: "Nature de chauffage", key: "of_natureheating", filter: "formatFromArray", filtermore: "items_of_natureheating" },
		{
			label: "Eau chaude",
			key: "of_typeboiler",
			filter: "formatFromArray",
			filtermore: [
				{
					li_id: 53,
					li_name: "items_of_typeboiler",
					key: 0,
					value: "Choisir",
					options: "",
					createdAt: "2019-11-12 15:30:48",
					updatedAt: "2019-11-12 15:30:48"
				},
				{
					li_id: 49,
					li_name: "items_of_typeboiler",
					key: 1,
					value: "Chaudière",
					options: "",
					createdAt: "2019-11-12 15:29:33",
					updatedAt: "2019-11-12 15:29:33"
				},
				{
					li_id: 50,
					li_name: "items_of_typeboiler",
					key: 2,
					value: "Collectif",
					options: "",
					createdAt: "2019-11-12 15:30:05",
					updatedAt: "2019-11-12 15:30:05"
				},
				{
					li_id: 51,
					li_name: "items_of_typeboiler",
					key: 3,
					value: "Chauffe eau",
					options: "",
					createdAt: "2019-11-12 15:30:17",
					updatedAt: "2019-11-12 15:30:17"
				},
				{
					li_id: 52,
					li_name: "items_of_typeboiler",
					key: 4,
					value: "Autre",
					options: "",
					createdAt: "2019-11-12 15:30:33",
					updatedAt: "2019-11-12 15:30:33"
				}
			]
		},
		{
			label: "Type d'abonnement eau",
			key: "of_typesubscription",
			filter: "formatFromArray",
			filtermore: [
				{
					li_id: 133,
					li_name: "items_of_typesubscription",
					key: 0,
					value: "Choisir",
					options: "",
					createdAt: "2022-01-10 08:41:42",
					updatedAt: "2022-01-10 08:42:06"
				},
				{
					li_id: 134,
					li_name: "items_of_typesubscription",
					key: 1,
					value: "Compteur individuel",
					options: "",
					createdAt: "2022-01-10 08:42:37",
					updatedAt: "2022-01-10 08:42:37"
				},
				{
					li_id: 135,
					li_name: "items_of_typesubscription",
					key: 2,
					value: "Inclus dans les charges",
					options: "",
					createdAt: "2022-01-10 08:42:57",
					updatedAt: "2022-01-10 08:42:57"
				}
			]
		},
		{ label: "Etat général", key: "of_generalstate", filter: "formatFromArray", filtermore: "items_of_generalstate" },
		{ label: "Ensoleillement", key: "of_sunny", filter: "formatFromArray", filtermore: "items_of_sunny" },
		{ label: "Exposition", key: "of_exposition", filter: "formatFromArray", filtermore: "items_of_exposition" },
		{ label: "Climatisation", key: "of_airconditioning", filter: "formatYesNo" },
		{ label: "Meublé", key: "of_furnished", filter: "formatYesNo" },
		{ label: "Prestige", key: "of_prestige", filter: "formatYesNo" },
		{ label: "Programme neuf", key: "of_newprogram", filter: "formatYesNo" },
		{ label: "Immeuble moderne", key: "of_modernbuilding", filter: "formatYesNo" },
		{ label: "Immeuble bourgeois", key: "of_bourgeoisbuilding", filter: "formatYesNo" },
		{ label: "Concierge", key: "of_concierge", filter: "formatYesNo" },
		{ label: "Sous loi 1235", key: "of_law1235", filter: "formatYesNo" },
		{ label: "Sous loi 887", key: "of_law887", filter: "formatYesNo" },
		{ label: "Libre de loi", key: "of_freeoflaw", filter: "formatYesNo" },
		{ label: "Vue interessante", key: "of_interestingview", filter: "formatYesNo" },
		{ label: "Vue mer", key: "of_seaview", filter: "formatYesNo" },
		{ label: "Usage mixte", key: "of_mixeduse", filter: "formatYesNo" },
		{ label: "Toit terrasse", key: "of_roofterrace", filter: "formatYesNo" }
	],
	items_frame_mandat: [
		{ label: "Propriétaire", key: "owner", filter: "formatContactName" },
		{ label: "Mandat", key: "of_mandat" },
		{ label: "Mandat exclusif", key: "of_typemandat", filter: "formatYesNo" },
		{
			label: "Exclusivité",
			key: "of_exclusivite",
			filter: "formatFromArray",
			filtermore: [
				{
					li_id: 123,
					li_name: "items_of_exclusivite",
					key: 0,
					value: "Choisir",
					options: "",
					createdAt: "2021-10-19 17:51:18",
					updatedAt: "2021-10-19 17:51:18"
				},
				{
					li_id: 25,
					li_name: "items_of_exclusivite",
					key: 1,
					value: "Exclusivité",
					options: "",
					createdAt: "2019-11-12 14:50:55",
					updatedAt: "2021-10-19 09:33:02"
				},
				{
					li_id: 27,
					li_name: "items_of_exclusivite",
					key: 2,
					value: "Co-exclusivité",
					options: "",
					createdAt: "2019-11-12 14:51:24",
					updatedAt: "2021-10-19 09:33:25"
				}
			]
		},
		{ label: "Date début mandat", key: "of_startmandat", filter: "formatDate" },
		{ label: "Date fin mandat", key: "of_endmandat", filter: "formatDate" },
		{ label: "Numéro de mandat", key: "of_nummandat" },
		{ label: "Notaire", key: "of_notary", filter: "formatFromArray", filtermore: "items_of_notary" }
	],
	items_co_type: [
		{ key: "", value: "Choisir", align: "center" },
		{ key: "contact", value: "Contact", align: "center" },
		{ key: "user", value: "Utilisateur", align: "center" },
		{ key: "secretary", value: "Secrétaire", align: "center" },
		{ key: "agent", value: "Agent immobilier", align: "center" },
		{ key: "accountant", value: "Comptable", align: "center" },
		{ key: "manager", value: "Directeur", align: "center" },
		{ key: "adminagent", value: "Administrateur agent", align: "center" },
		{ key: "admin", value: "Administrateur", align: "center" },
		{ key: "superadmin", value: "Super-Administrateur", align: "center" }
	],
	items_feedbacks: [
		{ key: 1, text1: "Secteur agréable", text2: "Secteur dérangeant", type: "situation" },
		{ key: 2, text1: "Vue dégagée", text2: "Vis-à-vis dérangeant", type: "situation" },
		{ key: 3, text1: "Calme", text2: "Trop bruyant", type: "situation" },
		{ key: 4, text1: "Secteur recherché", text2: "Trop éloigné", type: "situation" },
		{ key: 5, text1: "Bon rapport qualité/prix", text2: "Mauvais rapport qualité/prix", type: "price" },
		{ key: 6, text1: "Bon aménagement", text2: "Mauvais aménagement", type: "installation" },
		{ key: 7, text1: "Bonne luminosité", text2: "Trop sombre", type: "installation" },
		{ key: 8, text1: "Bonne exposition", text2: "Mauvaise exposition", type: "installation" },
		{ key: 9, text1: "Garage", text2: "Pas de garage", type: "installation" },
		{ key: 10, text1: "Cave/grenier", text2: "Pas de cave/grenier", type: "installation" },
		{ key: 11, text1: "Coup de coeur", text2: "Pas de coup de coeur", type: "esthetics" },
		{ key: 12, text1: "Avec du charme", text2: "Sans charme", type: "esthetics" },
		{ key: 13, text1: "Décoration extérieure", text2: "Mauvaise décoration extérieure", type: "esthetics" },
		{ key: 14, text1: "Décoration intérieure", text2: "Mauvaise décoration intérieure", type: "esthetics" },
		{ key: 15, text1: "Bonne surface", text2: "Trop petit", type: "surface" },
		{ key: 16, text1: "Pas ou peu de travaux", text2: "Trop de travaux", type: "stateinterior" },
		{ key: 17, text1: "Bon état", text2: "Mauvais état", type: "stateexterior" }
	],
	items_feedbackstypes: [
		{ key: "situation", value: "Situation" },
		{ key: "price", value: "Prix" },
		{ key: "installation", value: "Agréments" },
		{ key: "esthetics", value: "Esthétique" },
		{ key: "surface", value: "Surface" },
		{ key: "stateinterior", value: "Etat intérieur" },
		{ key: "stateexterior", value: "Etat extérieur" }
	],
	items_co_civilites: [
		{ key: "M", value: "M" },
		{ key: "Mme", value: "Mme" },
		{ key: "Dr", value: "Dr" },
		{ key: "Pr", value: "Pr" },
		{ key: "Me", value: "Me" },
		{ key: "Mgr", value: "Mgr" }
	],
	items_bu_state: [
		{ key: 0, value: "Choisir" },
		{ key: 1, value: "Bon" },
		{ key: 2, value: "Pas bon" }
	],

	items_ac_type: [
		{ key: "call", value: "Appeler", icon: "phone", iconwidth: 30, done: "Fait", notdone: "A faire" },
		{ key: "todo", value: "A faire", icon: "clipboard-check", iconwidth: 30, done: "Fait", notdone: "A faire" },
		{ key: "comment", longvalue: "", value: "Commentaire", icon: "comment", iconwidth: 30, done: "Fait", notdone: "A faire" },
		{ key: "signature", longvalue: "", value: "Signature", icon: "handshake", iconwidth: 40, done: "Signé", notdone: "Non signé" },
		{ key: "email", longvalue: "", value: "email", icon: "envelope", iconwidth: 30, done: "Envoyé", notdone: "A envoyer" },
		{ key: "present", longvalue: "", value: "Présentés", icon: "eye", iconwidth: 30, done: "Présenté", notdone: "A présenter" },
		{ key: "report", longvalue: "", value: "C. Rendu", icon: "sticky-note", iconwidth: 30, done: "Rempli", notdone: "A remplir" },
		{
			key: "notinterested",
			longvalue: "",
			value: "Pas intéressé",
			icon: "sticky-note",
			iconwidth: 30,
			done: "Pas interessé",
			notdone: "Interessé"
		},
		{ key: "newcontact", longvalue: "", value: "Nouveau contact", icon: "user-plus", iconwidth: 30, done: "", notdone: "" },
		{ key: "newoffer", longvalue: "", value: "Nouveau bien", icon: "folder-plus", iconwidth: 30, done: "", notdone: "" },
		{ key: "event", longvalue: "", value: "À visiter", icon: "key", iconwidth: 30, done: "Visité", notdone: "A visiter" },
		{ key: "follow", longvalue: "", value: "À suivre", icon: "folder-plus", iconwidth: 30, done: "", notdone: "" }
	],
	items_ac_type_signature: [
		{ key: "sell", value: "Vente" },
		{ key: "rent", value: "Location" }
	],
	items_ac_type_call: [
		{ key: "callout", value: "Appel sortant" },
		{ key: "callin", value: "Appel entrant" },
		{ key: "tocall", value: "A rappeler" }
	],
	items_frame_tosell: [
		{ label: "Prix sur demande", key: "of_sell_uponrequest", filter: "formatYesNo" },
		{ label: "Prix de présentation", key: "of_sell_sum", filter: "formatPrice" },
		{ label: "Prix net vendeur", key: "of_sell_sum_fix", filter: "formatPrice" },
		{ label: "Viager €/mois", key: "of_sell_lifeannuity", filter: "formatPrice" },
		{ label: "Charges totales/an", key: "of_sell_charge", filter: "formatPrice" },
		{ label: "Taxe d'habibation", key: "of_sell_taxdwelling", filter: "formatPrice" },
		{ label: "Taxe foncière", key: "of_sell_taxrealestate", filter: "formatPrice" },
		{ label: "Travaux envisagés", key: "of_sell_workenvisaged", filter: "formatPrice" },
		{ label: "Prix de départ", key: "of_sell_start", filter: "formatPrice" },
		{ label: "Honoraire à charge du vendeur TTC", key: "of_sell_honorairevendeur", filter: "formatPrice" },
		{ label: "Honoraire à charge de l'acquéreur TTC", key: "of_sell_honoraireacquereur", filter: "formatPrice" },
		{ label: "Frais de notaire", key: "of_sell_fraisdenotaire", filter: "formatPrice" },
		{ label: "Pourcentage honoraire", key: "of_sell_purcentfee", filter: "formatPurcent" },
		{ label: "Travaux votés", key: "of_sell_workenvisaged2", filter: "formatPrice" },
		{ label: "Offre > Prix de vente", key: "of_offer_price", filter: "formatPrice" },
		{ label: "Offre > En cours", key: "of_offer_inprogress", filter: "formatYesNo" },
		{ label: "Offre > Acceptée", key: "of_offer_accepted", filter: "formatYesNo" },
		{ label: "Offre > Acompte encaissé", key: "of_offer_account", filter: "formatYesNo" },
		{ label: "Offre > Commission HT", key: "of_offer_commission", filter: "formatPrice" },
		{ label: "Offre > Rétrocession", key: "of_offer_retrocession", filter: "formatPrice" },
		{ label: "Offre > Acquéreur", key: "co_id_offer_taker", filter: "formatContactName" },
		{ label: "Offre > Vendeur", key: "seller", filter: "formatContactName" },
		{ label: "Offre > Notaire", key: "notary", filter: "formatContactName" },
		{ label: "Offre > Date signature prévue", key: "of_offer_date", filter: "formatDate" }
	],
	items_frame_building: [
		{ label: "Nom de l'immeuble", key: "bu_name" },
		{ label: "Adresse 1", key: "bu_address1" },
		{ label: "Adresse 2", key: "bu_address2" },
		{ label: "Code postal", key: "bu_zip" },
		{ label: "Ville", key: "bu_city" },
		{ label: "Pays", key: "bu_country" },
		{ label: "Quartier", key: "bu_district" },
		{ label: "Standing", key: "bu_standing", filter: "formatFromArray", filtermore: "items_bu_standing" },
		{ label: "Etat de l'immeuble", key: "bu_state" },
		{ label: "Construction", key: "bu_construction", filter: "formatFromArray", filtermore: "items_bu_construction" },
		{ label: "Façade", key: "bu_facade", filter: "formatFromArray", filtermore: "items_bu_facade" },
		{ label: "Couverture", key: "bu_roof", filter: "formatFromArray", filtermore: "items_bu_roof" },
		{ label: "Année de construction", key: "bu_construction_year" },
		{ label: "Nb d'étages", key: "bu_floor" },
		{ label: "Syndic", key: "bu_syndic", filter: "formatContactName" },
		{ label: "Travaux votés", key: "bu_plannedwork" },
		{ label: "Ascenseur", key: "bu_elevator", filter: "formatYesNo" },
		{ label: "Accès handicapé", key: "bu_handicaccess", filter: "formatYesNo" },
		{ label: "Interphone", key: "bu_intercom", filter: "formatYesNo" },
		{ label: "Concierge", key: "bu_concierge", filter: "formatYesNo" },
		{ label: "Piscine", key: "bu_swimming_pool", filter: "formatYesNo" },
		{ label: "Digicode", key: "bu_digital_code" },
		{ label: "Gardien", key: "bu_guardian", filter: "formatYesNo" }
	],
	items_frame_torent: [
		{ label: "Prix sur demande", key: "of_rent_uponrequest", filter: "formatYesNo" },
		{ label: "Loyer mensuel", key: "of_rent_sum", filter: "formatPrice", filtermore: "&nbsp;€/mois" },
		{ label: "Loyer annuel", key: "of_rent_sum_year", filter: "formatPrice", filtermore: "&nbsp;€/an" },
		{ label: "Charge mensuelles", key: "of_rent_charge", filter: "formatPrice", filtermore: "&nbsp;€/mois" },
		{ label: "Type de charge", key: "of_rent_typecharge", filter: "formatFromArray", filtermore: "items_of_rent_typecharge" },
		{ label: "Type de bail", key: "of_rent_regimebail", filter: "formatFromArray", filtermore: "items_of_rent_regimebail" },
		{ label: "Echéance du bail", key: "of_rent_echeancebail", filter: "formatDate" },
		{ label: "Disponibilité du bien", key: "of_rent_disponibilitebien", filter: "formatDate" },
		{ label: "Honoraire à charge du preneur TTC", key: "of_rent_honorairebailleur", filter: "formatPrice" },
		{ label: "Honoraire à charge du Bailleur TTC", key: "of_rent_honoraireacquereur", filter: "formatPrice" },
		{ label: "Honoraire sur droit au bail à charge du Vendeur", key: "of_rent_droitbail_honorairebailleur", filter: "formatPrice" },
		{
			label: "Honoraire sur droit au bail à charge de l'acquereur",
			key: "of_rent_droitbail_honoraireacquereur",
			filter: "formatPrice"
		},
		{ label: "Résilier par anticipation", key: "of_rent_resilier", filter: "formatYesNo" }
	],
	items_frame_visibility: [
		{ label: "Status", key: "of_status", filter: "formatFromArray", filtermore: "items_of_status", force: true },
		{ label: "Sur internet", key: "of_web", filter: "formatFromArray", filtermore: "items_of_web", force: true },
		{ label: "Sur CI", key: "of_ci", filter: "formatFromArray", filtermore: "items_of_ci", force: true },
		{ label: "Vitrine", key: "of_sign", filter: "formatFromArray", filtermore: "items_of_sign", force: true },
		{ label: "Disponibilité", key: "of_disponibility", filter: "formatFromArray", filtermore: "items_of_disponibility" },
		{ label: "Date de disponibilité", key: "of_disponibility_date", filter: "formatDate" },
		{ label: "Date entrée du bien", key: "of_date_entry", filter: "formatDate" },
		{ label: "Vitrine", key: "of_window", filter: "formatYesNo" },
		{ label: "Pas de corrélation", key: "of_correlation", filter: "formatYesNo" },
		{ label: "Confidentiel", key: "of_confidential", filter: "formatYesNo" },
		{ label: "Nouveau", key: "of_isnew", filter: "formatYesNo" }
	],
	items_frame_diagnostics: [
		{ label: "Métrage Carrez", key: "of_carrez", filter: "formatSurface" },
		{ label: "Plomb (-1 an)", key: "of_plomb", filter: "formatDate" },
		{ label: "Termite (-3 mois)", key: "of_termite", filter: "formatDate" },
		{ label: "Amiante", key: "of_amiante", filter: "formatDate" },
		{ label: "Gaz (-1 an)", key: "of_gaz", filter: "formatDate" },
		{ label: "ERNT (-6 mois)", key: "of_ernt", filter: "formatDate" },
		{ label: "DPE (-10 ans)", key: "of_dpe", filter: "formatDate" },
		{ label: "Consommation énergétique", key: "of_consoenergie" },
		{
			label: "Classification énergétique",
			key: "of_classeenergie",
			filter: "formatFromArray",
			filtermore: "items_of_classeenergie"
		},
		{ label: "Conso. gaz à eff. de serre", key: "of_emissiongaes" },
		{ label: "Class. gaz à eff. de serre", key: "of_classegaes", filter: "formatFromArray", filtermore: "items_of_classegaes" }
	],
	items_frame_commercial: [
		{
			label: "Urgence",
			key: "of_urgency",
			filter: "formatFromArray",
			filtermore: [
				{
					li_id: 29,
					li_name: "items_of_urgency",
					key: 0,
					value: "Choisir",
					options: "",
					createdAt: "2019-11-12 14:52:16",
					updatedAt: "2019-11-12 14:52:16"
				},
				{
					li_id: 30,
					li_name: "items_of_urgency",
					key: 1,
					value: "Non",
					options: "",
					createdAt: "2019-11-12 14:52:29",
					updatedAt: "2019-11-12 14:52:29"
				},
				{
					li_id: 31,
					li_name: "items_of_urgency",
					key: 2,
					value: "Oui",
					options: "",
					createdAt: "2019-11-12 14:52:40",
					updatedAt: "2019-11-12 14:52:40"
				},
				{
					li_id: 32,
					li_name: "items_of_urgency",
					key: 3,
					value: "Crédit relais",
					options: "",
					createdAt: "2019-11-12 14:52:52",
					updatedAt: "2019-11-12 14:52:52"
				},
				{
					li_id: 33,
					li_name: "items_of_urgency",
					key: 4,
					value: "1 mois",
					options: "",
					createdAt: "2019-11-12 14:53:06",
					updatedAt: "2019-11-12 14:53:06"
				},
				{
					li_id: 34,
					li_name: "items_of_urgency",
					key: 5,
					value: "3 mois",
					options: "",
					createdAt: "2019-11-12 14:53:20",
					updatedAt: "2019-11-12 14:53:20"
				},
				{
					li_id: 35,
					li_name: "items_of_urgency",
					key: 6,
					value: "6 mois",
					options: "",
					createdAt: "2019-11-12 14:53:34",
					updatedAt: "2019-11-12 14:53:34"
				},
				{
					li_id: 36,
					li_name: "items_of_urgency",
					key: 7,
					value: "1 an",
					options: "",
					createdAt: "2019-11-12 14:53:45",
					updatedAt: "2019-11-12 14:53:45"
				}
			]
		},
		{ label: "Géré par l'agence", key: "of_agency", filter: "formatContactName" },
		{ label: "Chef de produit 1", key: "commercial1", filter: "formatContactName" },
		{ label: "Chef de produit 2", key: "commercial2", filter: "formatContactName" },
		{ label: "Apporteur d'affaire", key: "contributor", filter: "formatContactName" },
		{ label: "% apporteur", key: "of_purcentcontributor" },
		{ label: "Commission revendeur %", key: "of_commissionseller" },
		{ label: "Tantième", key: "of_percentage" },
		{ label: "Digicode", key: "of_digicode" },
		{ label: "Num. de lots", key: "of_nblots" },
		{ label: "Numéro clé à l'agence", key: "of_numkey" },
		{ label: "Consigne de visite", key: "of_access" },
		{ label: "Raison de la vente", key: "of_reasonsale" },
		{
			label: "Honoraires",
			key: "of_fn",
			filter: "formatFromArray",
			filtermore: [
				{ key: 1, value: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%" },
				{ key: 2, value: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%" },
				{ key: 3, value: "Honoraires d’Agence : 10% du Loyer Annuel + TVA" },
				{ key: 4, value: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%" },
				{ key: 5, value: "Frais de notaire réduits 2,5%" }
			]
		},
		{ label: "Notes client", key: "of_notes_customer", filter: "nl2br" },
		{ label: "Notes privées", key: "of_notes_private", filter: "nl2br" },
		{ label: "Num. de lot", key: "of_notes_private2" }
	],
	items_frame_more: [
		{ label: "Nombre de pièces", key: "of_nbpieces" },
		{ label: "Nom français", key: "of_name_fr" },
		{ label: "Nom anglais", key: "of_name_en" },
		{ label: "Nom russe", key: "of_name_ru" },
		{ label: "Nom italien", key: "of_name_it" },
		{ label: "Nom allemand", key: "of_name_de" },
		{ label: "Nom espagnol", key: "of_name_sp" },
		{ label: "Texte français", key: "of_text_fr" },
		{ label: "Texte anglais", key: "of_text_en" },
		{ label: "Texte russe", key: "of_text_ru" },
		{ label: "Texte italien", key: "of_text_it" },
		{ label: "Texte allemand", key: "of_text_de" },
		{ label: "Texte espagnol", key: "of_text_sp" },
		{ label: "Résumé français", key: "of_summary_fr" },
		{ label: "Résumé anglais", key: "of_summary_en" },
		{ label: "Résumé russe", key: "of_summary_ru" },
		{ label: "Résumé italien", key: "of_summary_it" },
		{ label: "Résumé allemand", key: "of_summary_de" },
		{ label: "Résumé espagnol", key: "of_summary_sp" },
		{ label: "Engagement en cours", key: "of_commitment_inprogress" },
		{ label: "Engagement accepté", key: "of_commitment_accepted" },
		{ label: "Acompte accepté", key: "of_commitment_account" },
		{ label: "Montant de location (mois)", key: "of_commitment_price" },
		{ label: "Rétrocession HT", key: "of_commitment_retrocession" },
		{ label: "Preneur", key: "co_id_commitment_taker" },
		{ label: "Propriétaire", key: "co_id_commitment_owner" },
		{ label: "Agence à la vente", key: "of_immotools" },
		{ label: "Géré", key: "co_id_society" },
		{ label: "Charges annuelles", key: "of_rent_charge_year" },
		{ label: "A louer", key: "of_rent" },
		{ label: "A vendre", key: "of_sell" }
	],
	items_languages: [
		{ key: "fr", value: "Français", pref: "french" },
		{ key: "en", value: "Anglais", pref: "english" },
		{ key: "it", value: "Italien", pref: "italian" }
	]
};

export const tradsItems = {
	Description: { fr: "DESCRIPTION", en: "DESCRIPTION", de: "Description", it: "DESCRIZIONE", ru: "Описание", sp: "Description" },
	"En résumé": { fr: "En résumé", en: "In summary", de: "En résumé", it: "In sintesi", ru: "Справка", sp: "En résumé" },
	"Nombre de pièces": {
		fr: "Nombre de pièces",
		en: "Number of rooms",
		de: "Nombre de pièces",
		it: "Numero di stanze",
		ru: "Количество штук",
		sp: "Nombre de pièces"
	},
	"Nombre de caves": {
		fr: "Nombre de caves",
		en: "Number of cellars",
		de: "Nombre de caves",
		it: "Numero di cantine",
		ru: "Количество погребов",
		sp: "Nombre de caves"
	},
	"Nombre de parkings": {
		fr: "Nombre de parkings",
		en: "Number of parking space",
		de: "Nombre de parkings",
		it: "Numero di parcheggi",
		ru: "Количество автостоянок",
		sp: "Nombre de parkings"
	},
	"Surface habitable": {
		fr: "Surface habitable",
		en: "Living area",
		de: "Surface habitable",
		it: "Superficie abitabile",
		ru: "Жилое пространство",
		sp: "Surface habitable"
	},
	"Surface jardin": {
		fr: "Surface jardin",
		en: "Garden area",
		de: "Surface jardin",
		it: "Superficie giardino",
		ru: "Поверхность сада",
		sp: "Surface jardin"
	},
	Etage: { fr: "Etage", en: "Floor", de: "Etage", it: "Piano", ru: "Этаж", sp: "Etage" },
	"Ce document ne fait partie d'aucune offre...": {
		fr: "Ce document ne fait partie d'aucune offre ou contrat. Toutes mesures, surfaces et distances sont approximatives. Le descriptif, les plans, les surfaces et les photos ne sont donnés qu’à titre indicatif et leur exactitude n’est pas garantie. L’offre de vente ou de location est valable sauf en cas de vente ou de location, de retrait de vente ou retrait de location, ou de changement de prix ou d’autres conditions sans avis préalable",
		en: "This document does not form part of any offer or contract. All measurements, areas and distances are approximate. The description, plans, surfaces and photos are only given as an indication and their accuracy is not guaranteed. The offer to sell or rent is valid except in case of sale or rental, withdrawal of sale or rental, or change of price or other conditions without prior notice.",
		de: "Dieses Dokument ist nicht Teil eines Angebots oder eines Vertrags. Alle Maße, Flächen und Entfernungen sind ungefähre Angaben. Die Beschreibung, Pläne, Oberflächen und Fotos dienen nur als Anhaltspunkt und ihre Richtigkeit wird nicht garantiert. Das Angebot zum Verkauf oder zur Vermietung ist gültig, außer im Falle eines Verkaufs oder einer Vermietung, einer Rücknahme des Verkaufs oder der Vermietung oder einer Änderung des Preises oder anderer Bedingungen ohne vorherige Ankündigung.",
		it: "Questo documento non fa parte di alcuna offerta o contratto. Tutte le misure, le aree e le distanze sono approssimative. La descrizione, le piante, le superfici e le foto sono fornite solo a titolo indicativo e la loro precisione non è garantita. L'offerta di vendita o di noleggio è valida salvo in caso di vendita o di noleggio, di ritiro della vendita o del noleggio, o di modifica del prezzo o di altre condizioni senza preavviso. ",
		ru: "Настоящий документ не является частью какого-либо предложения или контракта. Все измерения, площади и расстояния являются приблизительными. Описание, планы, поверхности и фотографии даются только как указание, и их точность не гарантируется. Предложение о продаже или аренде действует, за исключением случаев продажи или аренды, снятия с продажи или аренды, изменения цены или других условий без предварительного уведомления.",
		sp: "Este documento no forma parte de ninguna oferta o contrato. Todas las medidas, áreas y distancias son aproximadas. La descripción, los planos, las superficies y las fotos sólo se dan a título indicativo y su exactitud no está garantizada. La oferta de venta o alquiler es válida salvo en caso de venta o alquiler, retirada de la venta o alquiler, o cambio de precio u otras condiciones sin previo aviso."
	},
	"Nombre de chambres": {
		fr: "Nombre de chambres",
		en: "Number of rooms",
		de: "Nombre de chambres",
		it: "Numero di stanze",
		ru: "количество комнат",
		sp: "Nombre de chambres"
	},
	"Surface terrace": {
		fr: "Surface terrasse",
		en: "Terrace area",
		de: "Surface terrace",
		it: "Superficie terrazzo",
		ru: "Поверхность террасы",
		sp: "Surface terrace"
	},
	"": { fr: "", en: "", de: "", it: "", ru: "", sp: "" },
	"A LOUER": { fr: "A LOUER", en: "TO RENT", de: "A louer", it: "DA AFFITTARE", ru: "АРЕНДА", sp: "A louer" },
	"A VENDRE | A LOUER": {
		fr: "A VENDRE | A LOUER",
		en: "FOR SALE / FOR RENT",
		de: "A VENDRE | A LOUER",
		it: "IN VENDITA | IN AFFITTO",
		ru: "В продаже / В аренде",
		sp: "A VENDRE | A LOUER"
	},
	"A vendre": { fr: "A vendre", en: "For sale", de: "A vendre", it: "A vendre", ru: "A vendre", sp: "A vendre" },
	"Prix sur demande": {
		fr: "Sur demande",
		en: "Upon request",
		de: "Prix sur demande",
		it: "Su richiesta",
		ru: "На лету",
		sp: "Prix sur demande"
	},
	mois: { fr: "mois", en: "month", de: "mois", it: "mese", ru: "месяц", sp: "mois" },
	an: { fr: "an", en: "year", de: "an", it: "anno", ru: "год", sp: "an" },
	Villa: { fr: "Villa", en: "Villa", de: "Villa", it: "Villa", ru: "Вилла", sp: "Villa" },
	Studio: { fr: "Studio", en: "Studio", de: "Studio", it: "Monolocale", ru: "Студия", sp: "Studio" },
	pièces: { fr: "pièces", en: "rooms", de: "pièces", it: "Locali", ru: "номера", sp: "pièces" },
	"Nb pièces non définis": {
		fr: "Nb pièces non définis",
		en: "Number undefined rooms",
		de: "Nb pièces non définis",
		it: "Numero di pezzi indefiniti",
		ru: "Количество неопределенных частей",
		sp: "Nb pièces non définis"
	},
	Appartement: { fr: "Appartement", en: "Apartment", de: "Appartement", it: "Appartamento", ru: "Квартира", sp: "Appartement" },
	"Surface totale": {
		fr: "Surface totale",
		en: "Total surface",
		de: "Surface totale",
		it: "Superficie totale",
		ru: "Общая площадь",
		sp: "Surface totale"
	},
	Vidéo: { fr: "Vidéo", en: "Vidéo", de: "Vidéo", it: "Vidéo", ru: "Vidéo", sp: "Vidéo" },
	"Type de bien": {
		fr: "Type de bien",
		en: "Property type",
		de: "Type de bien",
		it: "Tipologia di immobile",
		ru: "Вид недвижимости",
		sp: "Type de bien"
	},
	Référence: { fr: "Référence", en: "Reference", de: "Référence", it: "Riferimento", ru: "Ссылка", sp: "Référence" },
	"Surf. habitable": {
		fr: "Surf. habitable",
		en: "Living space",
		de: "Surf. habitable",
		it: "Sup. abitabile",
		ru: "Жизненное пространство",
		sp: "Surf. habitable"
	},
	"Surf. approximative": {
		fr: "Surf. approximative",
		en: "Approximate area",
		de: "Surf. approximative",
		it: "Superficie approssimativa",
		ru: "Примерная площадь",
		sp: "Surf. approximative"
	},
	"Surf. jardin": {
		fr: "Surf. jardin",
		en: "Garden surface",
		de: "Surf. jardin",
		it: "Sup. giardino",
		ru: "Поверхность сада",
		sp: "Surf. jardin"
	},
	"Surf. terrain": {
		fr: "Surf. terrain",
		en: "Land area",
		de: "Surf. terrain",
		it: "Sup. terreno",
		ru: "Поверхность земли",
		sp: "Surf. terrain"
	},
	"Surf. terrasse": {
		fr: "Surf. terrasse",
		en: "Terrace area",
		de: "Surf. terrasse",
		it: "Sup. terrazza",
		ru: "Поверхность террасы",
		sp: "Surf. terrasse"
	},
	"Nb pièces": {
		fr: "Nb pièces",
		en: "Number of room",
		de: "Nb pièces",
		it: "Numero di stanze",
		ru: "Кол-во деталей",
		sp: "Nb pièces"
	},
	"Nb caves": {
		fr: "Nb caves",
		en: "Number of cellars",
		de: "Nb caves",
		it: "Numero di cantine",
		ru: "Количество погребов",
		sp: "Nb caves"
	},
	"Nb balcons": {
		fr: "Nb balcons",
		en: "Number of balconies",
		de: "Nb balcons",
		it: "Numero di balconi",
		ru: "Кол-во балконов",
		sp: "Nb balcons"
	},
	"Nb chambres": {
		fr: "Nb chambres",
		en: "Number of bedrooms",
		de: "Nb chambres",
		it: "Numero di camere da letto",
		ru: "Кол-во спален",
		sp: "Nb chambres"
	},
	"Nb parkings": {
		fr: "Nb parkings",
		en: "Number of parking space",
		de: "Nb parkings",
		it: "Numero di parcheggi",
		ru: "Количество автостоянок",
		sp: "Nb parkings"
	},
	"Nb box": { fr: "Nb box", en: "Number of box", de: "Nb box", it: "Numero di garage", ru: "Nb коробка", sp: "Nb box" },
	"Nb s. de bain": {
		fr: "Nb s. de bain",
		en: "Number of bathrooms",
		de: "Nb s. de bain",
		it: "Numero di bagni",
		ru: "количество ванных комнат",
		sp: "Nb s. de bain"
	},
	"Nb WC": { fr: "Nb WC", en: "Number of WC", de: "Nb WC", it: "Numero WC", ru: "Номер туалета", sp: "Nb WC" },
	"Nb niveaux": {
		fr: "Nb niveaux",
		en: "Number of levels",
		de: "Nb niveaux",
		it: "Numero di livelli",
		ru: "Уровни Nb",
		sp: "Nb niveaux"
	},
	"Type de cuisine": {
		fr: "Type de cuisine",
		en: "Kitchen type",
		de: "Type de cuisine",
		it: "Tipo di cucina",
		ru: "Тип кухни",
		sp: "Type de cuisine"
	},
	"Type de chauffage": {
		fr: "Type de chauffage",
		en: "Heating type",
		de: "Type de chauffage",
		it: "tipo di riscaldamento",
		ru: "тип отопления",
		sp: "Type de chauffage"
	},
	"Nature de chauffage": {
		fr: "Nature de chauffage",
		en: "Type of heating",
		de: "Nature de chauffage",
		it: "Tipo di riscaldamento",
		ru: "Тип отопления",
		sp: "Nature de chauffage"
	},
	"Eau chaude": { fr: "Eau chaude", en: "Hot water", de: "Eau chaude", it: "Acqua calda", ru: "Горячая вода", sp: "Eau chaude" },
	"Type d'abonnement": {
		fr: "Type d'abonnement eau",
		en: "Water Subscription type",
		de: "Type d'abonnement",
		it: "Tipo di abbonamento acqua",
		ru: "Тип подписки на воду",
		sp: "Type d'abonnement"
	},
	"Etat général": {
		fr: "Etat général",
		en: "General condition",
		de: "Etat général",
		it: "Condizione",
		ru: "Состояние",
		sp: "Etat général"
	},
	Ensoleillement: {
		fr: "Ensoleillement",
		en: "Sunshine",
		de: "Ensoleillement",
		it: "Luminosità",
		ru: "Солнечный свет",
		sp: "Ensoleillement"
	},
	Exposition: { fr: "Exposition", en: "Exposure", de: "Exposition", it: "Esposizione", ru: "Контакт", sp: "Exposition" },
	Climatisation: {
		fr: "Climatisation",
		en: "Air conditioner",
		de: "Climatisation",
		it: "Condizionatore",
		ru: "Кондиционер",
		sp: "Climatisation"
	},
	Meublé: { fr: "Meublé", en: "Furnished ", de: "Meublé", it: "Mobilia", ru: "Мебель", sp: "Meublé" },
	Prestige: { fr: "Prestige", en: "Prestige", de: "Prestige", it: "Prestigio", ru: "Престиж", sp: "Prestige" },
	"Programme neuf": {
		fr: "Programme neuf",
		en: "New program",
		de: "Programme neuf",
		it: "Nuovo programma",
		ru: "Новая программа",
		sp: "Programme neuf"
	},
	"Immeuble moderne": {
		fr: "Immeuble moderne",
		en: "Modern building",
		de: "Immeuble moderne",
		it: "Condominio moderno",
		ru: "Современный жилой дом",
		sp: "Immeuble moderne"
	},
	"Immeuble bourgeois": {
		fr: "Immeuble bourgeois",
		en: "Period building",
		de: "Immeuble bourgeois",
		it: "Edificio d'epoca",
		ru: "Буржуазный дом",
		sp: "Immeuble bourgeois"
	},
	Concierge: { fr: "Concierge", en: "Concierge", de: "Concierge", it: "Portiere", ru: "Консьерж", sp: "Concierge" },
	"Sous loi 1235": {
		fr: "Sous loi 1235",
		en: "Under law 1235",
		de: "Sous loi 1235",
		it: "Ai sensi della legge 1235",
		ru: "Согласно закону 1235",
		sp: "Sous loi 1235"
	},
	"Sous loi 887": {
		fr: "Sous loi 887",
		en: "Under law 887",
		de: "Sous loi 887",
		it: "Ai sensi della legge 887",
		ru: "Согласно закону 887",
		sp: "Sous loi 887"
	},
	"Libre de loi": {
		fr: "Libre de loi",
		en: "Free of law",
		de: "Libre de loi",
		it: "Libero dalla legge",
		ru: "Свободный от закона",
		sp: "Libre de loi"
	},
	"Vue interessante": {
		fr: "Vue interessante",
		en: "Interesting view",
		de: "Vue interessante",
		it: "Vista interessante",
		ru: "Интересный вид",
		sp: "Vue interessante"
	},
	"Usage mixte": {
		fr: "Usage mixte",
		en: "Mixed use",
		de: "Usage mixte",
		it: "Uso promiscuo",
		ru: "Смешанное использование",
		sp: "Usage mixte"
	},
	"Toit terrasse": {
		fr: "Toit terrasse",
		en: "Roof terrace",
		de: "Toit terrasse",
		it: "Terrazza sul tetto",
		ru: "Терраса на крыше",
		sp: "Toit terrasse"
	},
	Agence: { fr: "Agence", en: "Agency", de: "Agence", it: "Agenzia", ru: "Агентство", sp: "Agence" },
	"Mise à jour": { fr: "Mise à jour", en: "Update", de: "Mise à jour", it: "Aggiornamento", ru: "Обновить", sp: "Mise à jour" },
	"Prix de présentation": {
		fr: "Prix de présentation",
		en: "Presentation price",
		de: "Prix de présentation",
		it: "Prezzo di presentazione",
		ru: "Стоимость презентации",
		sp: "Prix de présentation"
	},
	"Prix net vendeur": {
		fr: "Prix net vendeur",
		en: "Net seller price",
		de: "Prix net vendeur",
		it: "Prezzo netto venditore",
		ru: "Чистая цена продавца",
		sp: "Prix net vendeur"
	},
	"Viager €/mois": {
		fr: "Viager €/mois",
		en: "Life annuity € / month",
		de: "Viager €/mois",
		it: "Rendita vitalizia € / mese",
		ru: "Пожизненная рента € / мес.",
		sp: "Viager €/mois"
	},
	"Charges totales/an": {
		fr: "Charges totales/an",
		en: "Total charges / year",
		de: "Charges totales/an",
		it: "Spese totali / anno",
		ru: "Общие расходы / год",
		sp: "Charges totales/an"
	},
	"Taxe d'habibation": {
		fr: "Taxe d'habibation",
		en: "Tax on occupation",
		de: "Taxe d'habibation",
		it: "Tassa di soggiorno",
		ru: "Налог на проживание",
		sp: "Taxe d'habibation"
	},
	"Taxe foncière": {
		fr: "Taxe foncière",
		en: "Property tax",
		de: "Taxe foncière",
		it: "Tassa fondiaria ",
		ru: "Налог на имущество",
		sp: "Taxe foncière"
	},
	"Travaux envisagés": {
		fr: "Travaux envisagés",
		en: "Planned works",
		de: "Travaux envisagés",
		it: "Lavori previsti",
		ru: "Планируемые работы",
		sp: "Travaux envisagés"
	},
	"Prix de départ": {
		fr: "Prix de départ",
		en: "Starting price",
		de: "Prix de départ",
		it: "prezzo di partenza",
		ru: "Начальная цена",
		sp: "Prix de départ"
	},
	"Honoraire à charge du vendeur TTC": {
		fr: "Honoraire à charge du vendeur TTC",
		en: "Fees charged to the seller including VAT",
		de: "Honoraire à charge du vendeur TTC",
		it: "Commissione a carico del venditore IVA inclusa",
		ru: "Комиссионные, оплачиваемые продавцом, включая НДС",
		sp: "Honoraire à charge du vendeur TTC"
	},
	"Honoraire à charge de l'acquéreur TTC": {
		fr: "Honoraire à charge de l'acquéreur TTC",
		en: "Fees payable by the purchaser including VAT",
		de: "Honoraire à charge de l'acquéreur TTC",
		it: "Commissione a carico dell'acquirente IVA inclusa",
		ru: "Комиссионные, оплачиваемые покупателем, включая НДС",
		sp: "Honoraire à charge de l'acquéreur TTC"
	},
	"Frais de notaire": {
		fr: "Frais de notaire",
		en: "Notary fees",
		de: "Frais de notaire",
		it: "Spese notarili",
		ru: "Нотариальные сборы",
		sp: "Frais de notaire"
	},
	"Pourcentage honoraire": {
		fr: "Pourcentage honoraire",
		en: "Honorary percentage",
		de: "Pourcentage honoraire",
		it: "Percentuale commissione",
		ru: "Почетный процент",
		sp: "Pourcentage honoraire"
	},
	"Travaux votés": {
		fr: "Travaux votés",
		en: "Voted works",
		de: "Travaux votés",
		it: "Lavori",
		ru: "Проголосовавшие работы",
		sp: "Travaux votés"
	},
	"Offre > Prix de vente": {
		fr: "Offre > Prix de vente",
		en: "Offer> Sale price",
		de: "Offre > Prix de vente",
		it: "Offerta> Prezzo di vendita",
		ru: "Предложение> Цена продажи",
		sp: "Offre > Prix de vente"
	},
	"Offre > En cours": {
		fr: "Offre > En cours",
		en: "Offer> Ongoing",
		de: "Offre > En cours",
		it: "Offerta> In corso",
		ru: "Предложение> Выполняется",
		sp: "Offre > En cours"
	},
	"Offre > Acceptée": {
		fr: "Offre > Acceptée",
		en: "Offer accepted",
		de: "Offre > Acceptée",
		it: "Offerta> Accettata",
		ru: "Предложение> Принято",
		sp: "Offre > Acceptée"
	},
	"Offre > Acompte encaissé": {
		fr: "Offre > Acompte encaissé",
		en: "Offer> Deposit cashed",
		de: "Offre > Acompte encaissé",
		it: "Offerta> Deposito incassato",
		ru: "Предложение> Депозит обналичен",
		sp: "Offre > Acompte encaissé"
	},
	"Offre > Commission HT": {
		fr: "Offre > Commission HT",
		en: "Offer> Commission VAT not included",
		de: "Offre > Commission HT",
		it: "Offerta> Commissione IVA esclusa",
		ru: "Предложение> Комиссия HT",
		sp: "Offre > Commission HT"
	},
	"Offre > Rétrocession": {
		fr: "Offre > Rétrocession",
		en: "Offer> Retrocession",
		de: "Offre > Rétrocession",
		it: "Offerta> Retrocessione",
		ru: "Предложение> Ретроцессия",
		sp: "Offre > Rétrocession"
	},
	"Offre > Acquéreur": {
		fr: "Offre > Acquéreur",
		en: "Offer> Buyer",
		de: "Offre > Acquéreur",
		it: "Offerta> Acquirente",
		ru: "Предложение> Покупатель",
		sp: "Offre > Acquéreur"
	},
	"Offre > Vendeur": {
		fr: "Offre > Vendeur",
		en: "Offer> Seller",
		de: "Offre > Vendeur",
		it: "Offerta> Venditore",
		ru: "Предложение> Продавец",
		sp: "Offre > Vendeur"
	},
	"Offre > Notaire": {
		fr: "Offre > Notaire",
		en: "Offer> Notary",
		de: "Offre > Notaire",
		it: "Offerta> Notaio",
		ru: "Предложение> Нотариус",
		sp: "Offre > Notaire"
	},
	"Offre > Date signature prévue": {
		fr: "Offre > Date signature prévue",
		en: "Offer> Planned signature date",
		de: "Offre > Date signature prévue",
		it: "Offerta> Data della firma prevista ",
		ru: "Предложение> Планируемая дата подписания",
		sp: "Offre > Date signature prévue"
	},
	"Nom de l'immeuble": {
		fr: "Nom de l'immeuble",
		en: "Name of the building",
		de: "Nom de l'immeuble",
		it: "Nome dell'edificio",
		ru: "Название здания",
		sp: "Nom de l'immeuble"
	},
	"Adresse 1": { fr: "Adresse 1", en: "Address 1", de: "Adresse 1", it: "Indirizzo 1", ru: "Адрес 1", sp: "Adresse 1" },
	"Adresse 2": { fr: "Adresse 2", en: "Adress 2", de: "Adresse 2", it: "Indirizzo 2", ru: "Адрес 2", sp: "Adresse 2" },
	"Code postal": { fr: "Code postal", en: "Zip code", de: "Code postal", it: "Codice postale", ru: "Почтовый Код", sp: "Code postal" },
	Ville: { fr: "Ville", en: "City", de: "Ville", it: "città", ru: "город", sp: "Ville" },
	Pays: { fr: "Pays", en: "Country", de: "Pays", it: "Nazione", ru: "Страна", sp: "Pays" },
	Quartier: { fr: "Quartier", en: "District", de: "Quartier", it: "Quartiere", ru: "Район", sp: "Quartier" },
	Standing: { fr: "Standing", en: "Standing", de: "Standing", it: "In piedi", ru: "Стоя", sp: "Standing" },
	"Etat de l'immeuble": {
		fr: "Etat de l'immeuble",
		en: "State of the building",
		de: "Etat de l'immeuble",
		it: "Stato della costruzione",
		ru: "Состояние здания",
		sp: "Etat de l'immeuble"
	},
	Construction: {
		fr: "Construction",
		en: "Construction",
		de: "Construction",
		it: "Costruzione",
		ru: "строительство",
		sp: "Construction"
	},
	Façade: { fr: "Façade", en: "Facade", de: "Façade", it: "Facciata", ru: "Фасад", sp: "Façade" },
	Couverture: { fr: "Couverture", en: "Blanket", de: "Couverture", it: "Coperta", ru: "Одеяло", sp: "Couverture" },
	"Année de construction": {
		fr: "Année de construction",
		en: "Year built",
		de: "Année de construction",
		it: "Anno di costruzione",
		ru: "Год строительства",
		sp: "Année de construction"
	},
	"Nb d'étages": {
		fr: "Nb d'étages",
		en: "Number of floors",
		de: "Nb d'étages",
		it: "Numero di piani",
		ru: "Количество этажей",
		sp: "Nb d'étages"
	},
	Syndic: {
		fr: "Syndic",
		en: "Building manager",
		de: "Syndic",
		it: "Amministratore condominiale",
		ru: "Доверенное лицо",
		sp: "Syndic"
	},
	Ascenseur: { fr: "Ascenseur", en: "Elevator", de: "Ascenseur", it: "Ascensore", ru: "Лифт", sp: "Ascenseur" },
	"Accès handicapé": {
		fr: "Accès handicapé",
		en: "Disabled access",
		de: "Accès handicapé",
		it: "Accesso per disabili",
		ru: "Доступ для инвалидов",
		sp: "Accès handicapé"
	},
	Interphone: { fr: "Interphone", en: "Intercom", de: "Interphone", it: "Citofono", ru: "Внутренняя связь", sp: "Interphone" },
	Piscine: { fr: "Piscine", en: "Swimming pool", de: "Piscine", it: "Piscina", ru: "Бассейн", sp: "Piscine" },
	Digicode: { fr: "Digicode", en: "Digital code", de: "Digicode", it: "Digicode", ru: "Digicode", sp: "Digicode" },
	Gardien: { fr: "Gardien", en: "Guardian", de: "Gardien", it: "Custode", ru: "Хранитель", sp: "Gardien" },
	"Loyer mensuel": {
		fr: "Loyer mensuel",
		en: "Monthly rent",
		de: "Loyer mensuel",
		it: "Affitto mensile",
		ru: "Месячная плата",
		sp: "Loyer mensuel"
	},
	"Loyer annuel": {
		fr: "Loyer annuel",
		en: "Annual rent",
		de: "Loyer annuel",
		it: "Affitto annuale",
		ru: "Годовая аренда",
		sp: "Loyer annuel"
	},
	"Charge mensuelles": {
		fr: "Charge mensuelles",
		en: "Monthly charges",
		de: "Charge mensuelles",
		it: "Spese condominiali",
		ru: "Ежемесячная плата",
		sp: "Charge mensuelles"
	},
	"Type de charge": {
		fr: "Type de charge",
		en: "Charge type",
		de: "Type de charge",
		it: "Tipo di spese",
		ru: "Тип нагрузки",
		sp: "Type de charge"
	},
	"Type de bail": {
		fr: "Type de bail",
		en: "Type of lease",
		de: "Type de bail",
		it: "Tipo di locazione",
		ru: "Тип аренды",
		sp: "Type de bail"
	},
	"Echéance du bail": {
		fr: "Echéance du bail",
		en: "End of lease",
		de: "Echéance du bail",
		it: "Scadenza contratto di locazione",
		ru: "Истечение срока аренды",
		sp: "Echéance du bail"
	},
	"Disponibilité du bien": {
		fr: "Disponibilité du bien",
		en: "Availability of the property",
		de: "Disponibilité du bien",
		it: "Disponibilità dell'immobile",
		ru: "Доступность недвижимости",
		sp: "Disponibilité du bien"
	},
	"Honoraire à charge du preneur TTC": {
		fr: "Honoraire à charge du preneur TTC",
		en: "Fees payable by the lessee including VAT",
		de: "Honoraire à charge du preneur TTC",
		it: "Commissioni a carico del locatario IVA inclusa",
		ru: "Комиссионные, подлежащие уплате арендатором, включая НДС",
		sp: "Honoraire à charge du preneur TTC"
	},
	"Honoraire à charge de l'acquereur": {
		fr: "Honoraire à charge de l'acquereur",
		en: "Fees payable by the purchaser",
		de: "Honoraire à charge de l'acquereur",
		it: "Commissione a carico dell'acquirente",
		ru: "Комиссионные, оплачиваемые покупателем",
		sp: "Honoraire à charge de l'acquereur"
	},
	"Honoraire sur droit au bail à charge du preneur": {
		fr: "Honoraire sur droit au bail à charge du preneur",
		en: "Fee on the right to the lease payable by the lessee",
		de: "Honoraire sur droit au bail à charge du preneur",
		it: "Commissione sul diritto al contratto di locazione a carico del locatario",
		ru: "Плата за право аренды, выплачиваемая арендатором",
		sp: "Honoraire sur droit au bail à charge du preneur"
	},
	"Honoraire sur droit au bail à charge de l'acquereur": {
		fr: "Honoraire sur droit au bail à charge de l'acquereur",
		en: "Fee on right to the lease payable by the purchaser",
		de: "Honoraire sur droit au bail à charge de l'acquereur",
		it: "Commissione sul contratto di affitto a carico dell'acquirente",
		ru: "Плата за право аренды, оплачиваемая покупателем",
		sp: "Honoraire sur droit au bail à charge de l'acquereur"
	},
	"Résilier par anticipation": {
		fr: "Résilier par anticipation",
		en: "Cancel early",
		de: "Résilier par anticipation",
		it: "Annulla in anticipo",
		ru: "Отменить досрочно",
		sp: "Résilier par anticipation"
	},
	Status: { fr: "Status", en: "Status", de: "Status", it: "Stato", ru: "Статус", sp: "Status" },
	"Sur internet": {
		fr: "Sur internet",
		en: "On the Internet",
		de: "Sur internet",
		it: "Su internet",
		ru: "В Интернете",
		sp: "Sur internet"
	},
	"Sur CI": { fr: "Sur Immotools", en: "On Immotools", de: "Sur CI", it: "Su Immotools", ru: "На Immotools", sp: "Sur CI" },
	Disponibilité: {
		fr: "Disponibilité",
		en: "Availability",
		de: "Disponibilité",
		it: "Disponibilità",
		ru: "Доступность",
		sp: "Disponibilité"
	},
	Vitrine: { fr: "Vitrine", en: "Show window", de: "Vitrine", it: "Vetrina", ru: "Окно", sp: "Vitrine" },
	"Date entrée du bien": {
		fr: "Date entrée du bien",
		en: "Date added",
		de: "Date entrée du bien",
		it: "Data di entrata del bene",
		ru: "Дата ввода собственности",
		sp: "Date entrée du bien"
	},
	"Pas de corrélation": {
		fr: "Pas de corrélation",
		en: "No correlation",
		de: "Pas de corrélation",
		it: "Nessuna correlazione",
		ru: "Нет корреляции",
		sp: "Pas de corrélation"
	},
	Confidentiel: {
		fr: "Confidentiel",
		en: "Confidential",
		de: "Confidentiel",
		it: "Riservato",
		ru: "Конфиденциально",
		sp: "Confidentiel"
	},
	"Métrage Carrez": {
		fr: "Métrage Carrez",
		en: "Carrez footage",
		de: "Métrage Carrez",
		it: "Misurazione Carrez",
		ru: "Кадры Карреза",
		sp: "Métrage Carrez"
	},
	"Plomb (-1 an)": {
		fr: "Plomb (-1 an)",
		en: "Lead (-1 year)",
		de: "Plomb (-1 an)",
		it: "Piombo (-1 anno)",
		ru: "Свинец (-1 год)",
		sp: "Plomb (-1 an)"
	},
	"Termite (-3 mois)": {
		fr: "Termite (-3 mois)",
		en: "Termite (-3 months)",
		de: "Termite (-3 mois)",
		it: "Termiti (-3 mesi)",
		ru: "Термит (-3 месяца)",
		sp: "Termite (-3 mois)"
	},
	Amiante: { fr: "Amiante", en: "Amiante", de: "Amiante", it: "Amianto", ru: "Асбест", sp: "Amiante" },
	"Gaz (-1 an)": {
		fr: "Gaz (-1 an)",
		en: "Gas (-1 year)",
		de: "Gaz (-1 an)",
		it: "Gaz (-1 anno)",
		ru: "Газ (-1 год)",
		sp: "Gaz (-1 an)"
	},
	"ERNT (-6 mois)": {
		fr: "ERNT (-6 mois)",
		en: "ERNT (-6 months)",
		de: "ERNT (-6 mois)",
		it: "ERNT (-6 mesi)",
		ru: "ERNT (-6 месяцев)",
		sp: "ERNT (-6 mois)"
	},
	"DPE (-10 ans)": {
		fr: "DPE (-10 ans)",
		en: "DPE (-10 years old)",
		de: "DPE (-10 ans)",
		it: "DPE (-10 anni)",
		ru: "DPE (-10 лет)",
		sp: "DPE (-10 ans)"
	},
	"Consommation énergétique": {
		fr: "Consommation énergétique",
		en: "Energy consumption",
		de: "Consommation énergétique",
		it: "Consumo energetico",
		ru: "Энергопотребление",
		sp: "Consommation énergétique"
	},
	"Classification énergétique": {
		fr: "Classification énergétique",
		en: "Energy classification",
		de: "Classification énergétique",
		it: "Classificazione energetica",
		ru: "Классификация энергии",
		sp: "Classification énergétique"
	},
	"Conso. gaz à eff. de serre": {
		fr: "Conso. gaz à eff. de serre",
		en: "Conso. greenhouse gas",
		de: "Conso. gaz à eff. de serre",
		it: "Conso. gaz à eff. de serre",
		ru: "Conso. gaz à eff. de serre",
		sp: "Conso. gaz à eff. de serre"
	},
	"Class. gaz à eff. de serre": {
		fr: "Class. gaz à eff. de serre",
		en: "Class. greenhouse gas",
		de: "Class. gaz à eff. de serre",
		it: "Class. gas a eff. serra",
		ru: "Class. gaz à eff. de serre",
		sp: "Class. gaz à eff. de serre"
	},
	Propriétaire: { fr: "Propriétaire", en: "Owner", de: "Propriétaire", it: "Proprietario", ru: "Владелец", sp: "Propriétaire" },
	Mandat: { fr: "Mandat", en: "Mandate", de: "Mandat", it: "Mandato", ru: "Мандат", sp: "Mandat" },
	"Mandat exclusif": {
		fr: "Mandat exclusif",
		en: "Exclusive mandate",
		de: "Mandat exclusif",
		it: "Mandato esclusivo",
		ru: "Эксклюзивный мандат",
		sp: "Mandat exclusif"
	},
	Exclusivité: { fr: "Exclusivité", en: "Exclusivity", de: "Exclusivité", it: "Esclusiva", ru: "Эксклюзивность", sp: "Exclusivité" },
	"Date début mandat": {
		fr: "Date début mandat",
		en: "Start of terms",
		de: "Date début mandat",
		it: "Data di inizio del mandato",
		ru: "Дата начала мандата",
		sp: "Date début mandat"
	},
	"Date fin mandat": {
		fr: "Date fin mandat",
		en: "End of terms",
		de: "Date fin mandat",
		it: "Data di scadenza del mandato",
		ru: "Дата окончания мандата",
		sp: "Date fin mandat"
	},
	"Numéro de mandat": {
		fr: "Numéro de mandat",
		en: "Mandate number",
		de: "Numéro de mandat",
		it: "Numero di mandato",
		ru: "Номер мандата",
		sp: "Numéro de mandat"
	},
	Notaire: { fr: "Notaire", en: "Notary", de: "Notaire", it: "Notaio", ru: "Нотариус", sp: "Notaire" },
	Urgence: { fr: "Urgence", en: "Emergency", de: "Urgence", it: "Emergenza", ru: "Крайняя необходимость", sp: "Urgence" },
	"Géré par l'agence": {
		fr: "Géré par l'agence",
		en: "Managed by the agency",
		de: "Géré par l'agence",
		it: "Gestito dall'agenzia",
		ru: "Управляется агентством",
		sp: "Géré par l'agence"
	},
	"Chef de produit 1": {
		fr: "Chef de produit 1",
		en: "Product manager 1",
		de: "Chef de produit 1",
		it: "Responsabile del prodotto 1",
		ru: "Менеджер по продукту 1",
		sp: "Chef de produit 1"
	},
	"Chef de produit 2": {
		fr: "Chef de produit 2",
		en: "Product manager 2",
		de: "Chef de produit 2",
		it: "Responsabile del prodotto 2",
		ru: "Менеджер по продукту 2",
		sp: "Chef de produit 2"
	},
	"Apporteur d'affaire": {
		fr: "Apporteur d'affaire",
		en: "Provider business",
		de: "Apporteur d'affaire",
		it: "Attività di intermediazione",
		ru: "Посреднический бизнес",
		sp: "Apporteur d'affaire"
	},
	"% apporteur": { fr: "% apporteur", en: "% provider", de: "% apporteur", it: "% collaboratore", ru: "% участник", sp: "% apporteur" },
	"Commission revendeur %": {
		fr: "Commission revendeur %",
		en: "Reseller commission %",
		de: "Commission revendeur %",
		it: "Revendeur della Commissione %",
		ru: "Комиссия реселлера %",
		sp: "Commission revendeur %"
	},
	Tantième: { fr: "Tantième", en: "Related allocation of a quote ", de: "Tantième", it: "Millesimi", ru: "Tantième", sp: "Tantième" },
	"Num. de lots": {
		fr: "Num. de lots",
		en: "Lot number",
		de: "Num. de lots",
		it: "Numero di lotto",
		ru: "Большое число",
		sp: "Num. de lots"
	},
	"Numéro clé à l'agence": {
		fr: "Numéro clé à l'agence",
		en: "Agency key number",
		de: "Numéro clé à l'agence",
		it: "Numero chiave in agenzia",
		ru: "Ключевой номер агентства",
		sp: "Numéro clé à l'agence"
	},
	"Consigne de visite": {
		fr: "Consigne de visite",
		en: "Visit instructions",
		de: "Consigne de visite",
		it: "Visita le istruzioni",
		ru: "Посетите инструкции",
		sp: "Consigne de visite"
	},
	"Raison de la vente": {
		fr: "Raison de la vente",
		en: "Reason for sale",
		de: "Raison de la vente",
		it: "Motivo della vendita",
		ru: "Причина продажи",
		sp: "Raison de la vente"
	},
	"Notes privées": {
		fr: "Notes privées",
		en: "Private Notes",
		de: "Notes privées",
		it: "Note private",
		ru: "Личные заметки",
		sp: "Notes privées"
	},
	"Num. de lot": {
		fr: "Num. de lot",
		en: "Number of lot",
		de: "Num. de lot",
		it: "Numero di lotto ",
		ru: "Серийный номер",
		sp: "Num. de lot"
	},
	"Description du bien": {
		fr: "Description du bien",
		en: "Property description",
		de: "Description du bien",
		it: "Descrizione della proprietà",
		ru: "Описание недвижимости",
		sp: "Description du bien"
	},
	"Les détails": { fr: "Les détails", en: "The details", de: "Les détails", it: "I dettagli", ru: "Детали", sp: "Les détails" },
	"Le proprétaire et le mandat": {
		fr: "Le proprétaire et le mandat",
		en: "Le proprétaire et le mandat",
		de: "Le proprétaire et le mandat",
		it: "Le proprétaire et le mandat",
		ru: "Le proprétaire et le mandat",
		sp: "Le proprétaire et le mandat"
	},
	"Statut et visibilité": {
		fr: "Statut et visibilité",
		en: "Status and visibility",
		de: "Statut et visibilité",
		it: "Stato e visibilità",
		ru: "Статус и видимость",
		sp: "Statut et visibilité"
	},
	"Informations commerciales... et consignes de visites": {
		fr: "Informations commerciales... et consignes de visites",
		en: "Commercial information ... and visit instructions",
		de: "Informations commerciales... et consignes de visites",
		it: "Informations commerciales... et consignes de visites",
		ru: "Коммерческая информация ... и инструкции по посещению",
		sp: "Informations commerciales... et consignes de visites"
	},
	corrélation: { fr: "correlation", en: "correlation", de: "corrélation", it: "correlazione", ru: "корреляция", sp: "corrélation" },
	"A la vente": { fr: "A la vente", en: "On sales", de: "A la vente", it: "In vendita", ru: "Продается", sp: "A la vente" },
	"Immeuble, addresse et quartier": {
		fr: "Immeuble, adresse et quartier",
		en: "Building, address and district",
		de: "Immeuble, addresse et quartier",
		it: "Edificio, indirizzo e quartiere",
		ru: "Дом, адрес и район",
		sp: "Immeuble, addresse et quartier"
	},
	"A la location": {
		fr: "A la location",
		en: "For rent",
		de: "A la location",
		it: "Da noleggiare",
		ru: "В аренду",
		sp: "A la location"
	},
	Fichiers: { fr: "Fichiers", en: "Files", de: "Fichiers", it: "File", ru: "Файлы", sp: "Fichiers" },
	"Avis des clients": {
		fr: "Avis des clients",
		en: "Customer feedback",
		de: "Avis des clients",
		it: "Recensioni dei clienti",
		ru: "Отзывы клиентов",
		sp: "Avis des clients"
	},
	"Assemblée générale": {
		fr: "Assemblée générale",
		en: "General assembly",
		de: "Assemblée générale",
		it: "Assemblea generale",
		ru: "Генеральная Ассамблея",
		sp: "Assemblée générale"
	},
	"Le propriétaire et le mandat": {
		fr: "Le propriétaire et le mandat",
		en: "The owner and the mandate",
		de: "Le propriétaire et le mandat",
		it: "Il proprietario e il mandato",
		ru: "Владелец и мандат",
		sp: "Le propriétaire et le mandat"
	},
	pièce: { fr: "pièce", en: "room", de: "pièce", it: "stanza", ru: "номер", sp: "pièce" },
	"A louer": { fr: "A louer", en: "A louer", de: "A louer", it: "A louer", ru: "A louer", sp: "A louer" },
	Parking: { fr: "Parking", en: "Parking space", de: "Parking", it: "Parcheggio", ru: "Парковка", sp: "Parking" },
	Bureau: { fr: "Bureau", en: "Office", de: "Bureau", it: "Ufficio", ru: "Офис", sp: "Bureau" },
	Autre: { fr: "Autre", en: "Other", de: "Autre", it: "Altro", ru: "другие", sp: "Autre" },
	"Local comm.": {
		fr: "Local comm.",
		en: "Commercial premises",
		de: "Local comm.",
		it: "Strutture commerciali",
		ru: "Общая комната",
		sp: "Local comm."
	},
	Prix: { fr: "Prix", en: "Price", de: "Prix", it: "Prezzo", ru: "Цена", sp: "Prix" },
	"Disponibilité le ": {
		fr: "Disponibilité le",
		en: "Available on",
		de: "Disponibilité le",
		it: "Disponibilità",
		ru: "Доступность",
		sp: "Disponibilité le"
	},
	"Type d'abonnement eau": {
		fr: "Type d'abonnement eau",
		en: "Water Subscription type",
		de: "Type d'abonnement eau",
		it: "Tipo di abbonamento acqua",
		ru: "Type d'abonnement eau",
		sp: "Type d'abonnement eau"
	},
	"Surf. totale": {
		fr: "Surf. totale",
		en: "Total area",
		de: "Surf. totale",
		it: "Sup. totale",
		ru: "Surf. totale",
		sp: "Surf. totale"
	},
	"Cliquer ici pour télécharger la fiche du bien": {
		fr: "Cliquer ici pour télécharger la fiche du bien",
		en: "Click here to download the property file",
		de: "Click here to download the property file",
		it: "Clicca qui per scaricare il file della proprietà",
		ru: "Нажмите здесь, чтобы загрузить файл недвижимости",
		sp: "Click here to download the property file"
	},
	"Cher M": { fr: "Cher M", en: "Dear Mr.", de: "Dear Mr.", it: "Egregio Signore", ru: "Уважаемый   господин", sp: "Dear Mr." },
	"Chère Mme": { fr: "Chère Mme", en: "Dear Mrs", de: "Dear Mrs", it: "Gentile Signora", ru: "Уважаемая   госпожа", sp: "Dear Mrs" },
	"Nombre de": { fr: "Nombre de", en: "Nombre de", de: "Nombre de", it: "Nombre de", ru: "Nombre de", sp: "Nombre de" },
	chambres: { fr: "Chambres", en: "Bedrooms", de: "chambres", it: "Camera", ru: "chambres", sp: "chambres" },
	Surface: { fr: "Surface", en: "Surface", de: "Surface", it: "Surface", ru: "Surface", sp: "Surface" },
	totale: { fr: "totale", en: "totale", de: "totale", it: "totale", ru: "totale", sp: "totale" },
	terrasse: { fr: "terrasse", en: "terrasse", de: "terrasse", it: "terrasse", ru: "terrasse", sp: "terrasse" },
	caves: { fr: "caves", en: "caves", de: "caves", it: "caves", ru: "caves", sp: "caves" },
	"Surface terrasse": {
		fr: "Surface terrasse",
		en: "Terrace area",
		de: "Surface terrasse",
		it: "Superficie terrazzo",
		ru: "Поверхность террасы",
		sp: "Surface terrasse"
	},
	"Parking/Garage/Box": {
		fr: "Parking/Garage/Box",
		en: "Parking/Garage/Box",
		de: "Parking/Garage/Box",
		it: "Parcheggio/Garage/Box",
		ru: "Parking/Garage/Box",
		sp: "Parking/Garage/Box"
	},
	Cave: { fr: "Cave", en: "Cellar", de: "Cave", it: "Cantina", ru: "Cave", sp: "Cave" },
	Atelier: { fr: "Atelier", en: "Atelier", de: "Atelier", it: "Atelier", ru: "Atelier", sp: "Atelier" },
	Boutique: { fr: "Boutique", en: "Boutique", de: "Boutique", it: "Boutique", ru: "Boutique", sp: "Boutique" },
	"Cession de droit au bail": {
		fr: "Cession de droit au bail",
		en: "Cession de droit au bail",
		de: "Cession de droit au bail",
		it: "Cession de droit au bail",
		ru: "Cession de droit au bail",
		sp: "Cession de droit au bail"
	},
	"Chambre de bonne": {
		fr: "Chambre de bonne",
		en: "Chambre de bonne",
		de: "Chambre de bonne",
		it: "Chambre de bonne",
		ru: "Chambre de bonne",
		sp: "Chambre de bonne"
	},
	Duplex: { fr: "Duplex", en: "Duplex", de: "Duplex", it: "Duplex", ru: "Duplex", sp: "Duplex" },
	"Duplex-roof": { fr: "Duplex-roof", en: "Duplex-roof", de: "Duplex-roof", it: "Duplex-roof", ru: "Duplex-roof", sp: "Duplex-roof" },
	"Fonds de commerce": {
		fr: "Fonds de commerce",
		en: "Fonds de commerce",
		de: "Fonds de commerce",
		it: "Fonds de commerce",
		ru: "Fonds de commerce",
		sp: "Fonds de commerce"
	},
	Hangar: { fr: "Hangar", en: "Hangar", de: "Hangar", it: "Hangar", ru: "Hangar", sp: "Hangar" },
	Immeuble: { fr: "Immeuble", en: "Immeuble", de: "Immeuble", it: "Immeuble", ru: "Immeuble", sp: "Immeuble" },
	"Location saisonni&egrave;re": {
		fr: "Location saisonni&egrave;re",
		en: "Location saisonni&egrave;re",
		de: "Location saisonni&egrave;re",
		it: "Location saisonni&egrave;re",
		ru: "Location saisonni&egrave;re",
		sp: "Location saisonni&egrave;re"
	},
	Loft: { fr: "Loft", en: "Loft", de: "Loft", it: "Loft", ru: "Loft", sp: "Loft" },
	"Meubl&eacute;": {
		fr: "Meubl&eacute;",
		en: "Meubl&eacute;",
		de: "Meubl&eacute;",
		it: "Meubl&eacute;",
		ru: "Meubl&eacute;",
		sp: "Meubl&eacute;"
	},
	"Murs loc. commerciaux": {
		fr: "Murs loc. commerciaux",
		en: "Working space",
		de: "Murs loc. commerciaux",
		it: "Muri di loc. commerciale",
		ru: "Murs loc. commerciaux",
		sp: "Murs loc. commerciaux"
	},
	Penthouse: { fr: "Penthouse", en: "Penthouse", de: "Penthouse", it: "Penthouse", ru: "Penthouse", sp: "Penthouse" },
	Roof: { fr: "Roof", en: "Roof", de: "Roof", it: "Roof", ru: "Roof", sp: "Roof" },
	Terrain: { fr: "Terrain", en: "Terrain", de: "Terrain", it: "Terrain", ru: "Terrain", sp: "Terrain" },
	Viagers: { fr: "Viagers", en: "Viagers", de: "Viagers", it: "Viagers", ru: "Viagers", sp: "Viagers" },
	Services: { fr: "Services", en: "Services", de: "Services", it: "Services", ru: "Services", sp: "Services" },
	"Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%": {
		fr: "Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%",
		en: "Agency Fees: 3% of the Sale Price + VAT - Notary Fees: 6%",
		de: "Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%",
		it: "Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%",
		ru: "Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%",
		sp: "Honoraires d’Agence: 3% du Prix de Vente + TVA – Frais de Notaire : 6%"
	},
	webapropos: { fr: "À PROPOS", en: "ABOUT", de: "", it: "RIGUARDO A", ru: "", sp: "" },
	webventes: { fr: "VENTES", en: "FOR SALE", de: "", it: "VENDITE", ru: "", sp: "" },
	weblocations: { fr: "LOCATIONS", en: "RENTALS", de: "", it: "AFFITTI", ru: "", sp: "" },
	webinternational: { fr: "INTERNATIONAL", en: "INTERNATIONAL", de: "", it: "INTERNAZIONALE", ru: "", sp: "" },
	webactualites: { fr: "ACTUALITÉS", en: "NEWS", de: "", it: "ATTUALITÀ", ru: "", sp: "" },
	webcontact: { fr: "CONTACT", en: "CONTACT", de: "", it: "CONTATTI", ru: "", sp: "" },
	webaccueil: { fr: "ACCUEIL", en: "HOMEPAGE", de: "", it: "HOMEPAGE", ru: "", sp: "" },
	tel: { fr: "Tél", en: "Tel", de: "", it: "Tel", ru: "", sp: "" },
	nouscontacter: { fr: "Nous contacter", en: "Contact us", de: "", it: "Affiti", ru: "", sp: "" },
	credit: { fr: "Crédit", en: "Credit", de: "", it: "Credit", ru: "", sp: "" },
	mentionslegales: { fr: "Mentions légales", en: "Legal", de: "", it: "Avviso legale", ru: "", sp: "" },
	louer: { fr: "Louer", en: "To rent", de: "", it: "Affittare", ru: "", sp: "" },
	acheter: { fr: "Acheter", en: "To buy", de: "", it: "Comperare", ru: "", sp: "" },
	typedepropriete: { fr: "Type de propriété", en: "Property type", de: "", it: "Tipo di proprietà", ru: "", sp: "" },
	Quartiers: { fr: "Quartiers", en: "District", de: "", it: "Quartieri", ru: "", sp: "" },
	prixmin: { fr: "Prix min.", en: "Price min.", de: "", it: "Prezzo min.", ru: "", sp: "" },
	prixmax: { fr: "Prix max.", en: "Price max.", de: "", it: "Prezzo max.", ru: "", sp: "" },
	surfacemin: { fr: "Surface min.", en: "Surface min.", de: "", it: "Superficie min.", ru: "", sp: "" },
	surfacemax: { fr: "Surface max.", en: "Surface max.", de: "", it: "Superficie max.", ru: "", sp: "" },
	validform: {
		fr: "En cochant cette case, je consens à l’utilisation de mes renseignements fournis à des fins de marketing par courriel",
		en: "By checking this box, I consent to the use of my information provided for email marketing purposes",
		de: "",
		it: "Selezionando questa casella, acconsento all'utilizzo delle mie informazioni fornite per scopi di email marketing",
		ru: "",
		sp: ""
	},
	votrerecherche: { fr: "Votre recherche", en: "Your research", de: "", it: "La tua ricerca", ru: "", sp: "" },
	Location: { fr: "Location", en: "Rental", de: "", it: "Locazione", ru: "", sp: "" },
	Vente: { fr: "Vente", en: "Sale", de: "", it: "Vendita", ru: "", sp: "" },
	Choisir: { fr: "Choisir", en: "Choose", de: "", it: "Scegliere", ru: "", sp: "" },
	nbpieces: { fr: "Nombre de pièces", en: "Number of rooms", de: "", it: "Numero di pezzi", ru: "", sp: "" },
	messageenvoye: { fr: "Votre message a bien été envoyé", en: "District", de: "", it: "Quartieri", ru: "", sp: "" },
	champsmanquant: {
		fr: "Un ou plusieurs champs obligatoires sont manquant",
		en: "One or more mandatory fields are missing",
		de: "",
		it: "Mancano uno o più campi obbligatori",
		ru: "",
		sp: ""
	},
	ENVOYER: { fr: "ENVOYER", en: "SEND", de: "", it: "MANDARE", ru: "", sp: "" },
	votredemande: { fr: "Votre demande", en: "Your request", de: "", it: "La tua richiesta", ru: "", sp: "" },
	Nom: { fr: "Nom", en: "Name", de: "", it: "Nome", ru: "", sp: "" },
	Prenom: { fr: "Prénom", en: "First name", de: "", it: "Conome", ru: "", sp: "" },
	Telephone: { fr: "Téléphone", en: "Phone", de: "", it: "Telefono", ru: "", sp: "" },
	email: { fr: "E-mail", en: "E-mail", de: "", it: "E-mail", ru: "", sp: "" },
	votremessage: { fr: "Votre message", en: "Your message", de: "", it: "Il tuo messaggio", ru: "", sp: "" },
	contacteznous: { fr: "Contactez-nous", en: "Contact us", de: "", it: "Contattaci", ru: "", sp: "" },
	retourliste: { fr: "Retour liste", en: "Return to list", de: "", it: "Torna all'elenco", ru: "", sp: "" },
	surfacetotale: { fr: "Surface totale", en: "Total surface", de: "", it: "Superficie totale", ru: "", sp: "" },
	surfacehabitable: { fr: "Surface habitable", en: "Living space", de: "", it: "Spazio vitale", ru: "", sp: "" },
	surfaceterrasse: { fr: "Surface terrasse", en: "Terrace surface", de: "", it: "Superficie terrazza", ru: "", sp: "" },
	Imprimer: { fr: "Imprimer", en: "To print", de: "", it: "Per stampare", ru: "", sp: "" },
	voirplans: { fr: "Voir plan(s)", en: "See plan(s)", de: "", it: "Vedi piano/i", ru: "", sp: "" },
	voirvideo: { fr: "Voir vidéo", en: "See video", de: "", it: "Guarda il video", ru: "", sp: "" },
	voirphotos: { fr: "Voir photos", en: "See photos", de: "", it: "Guarda le foto", ru: "", sp: "" },
	Partager: { fr: "Partager", en: "Share", de: "", it: "To share", ru: "", sp: "" },
	descriptionbien: {
		fr: "Description de ce bien",
		en: "Description of this property",
		de: "",
		it: "Descrizione di questa proprietà",
		ru: "",
		sp: ""
	},
	contactezvotreagent: { fr: "Contactez votre agent", en: "Contact your agent", de: "", it: "Contatta il tuo agente", ru: "", sp: "" },
	vousetes: { fr: "Vous êtes", en: "You are", de: "", it: "Voi siete", ru: "", sp: "" },
	Message: { fr: "Message", en: "Message", de: "", it: "Messaggio", ru: "", sp: "" },
	webvousaider: { fr: "VOUS AIDER", en: "TO HELP YOU", de: "", it: "AIUTARVI", ru: "", sp: "" },
	"A VENDRE": { fr: "À VENDRE", en: "FOR SALE", de: "A VENDRE", it: "A VENDRE", ru: "A VENDRE", sp: "A VENDRE" },
	"semi-exclusivité": {
		fr: "semi-exclusivité",
		en: "semi-exclusive",
		de: "semi-exclusivité",
		it: "semi-exclusivité",
		ru: "semi-exclusivité",
		sp: "semi-exclusivité"
	},
	"co-exclusivité": {
		fr: "co-exclusivité",
		en: "co-exclusivity",
		de: "co-exclusivité",
		it: "co-exclusivité",
		ru: "co-exclusivité",
		sp: "co-exclusivité"
	},
	"Sur demande": { fr: "Sur demande", en: "On request", de: "Sur demande", it: "Sur demande", ru: "Sur demande", sp: "Sur demande" },
	Nosreseaux: { fr: "Nos réseaux", en: "our networks", de: "", it: "le nostre reti", ru: "", sp: "" },
	Parkings: { fr: "Parkings", en: "Parkings", de: "", it: "Parcheggios", ru: "", sp: "" },
	Informations: { fr: "Informations", en: "Informations", de: "", it: "Informazione", ru: "", sp: "" },
	Fermer: { fr: "Fermer", en: "Close", de: "", it: "Chiudere", ru: "", sp: "" },
	Copierlelien: { fr: "Copier le lien", en: "copy link", de: "", it: "Copia link", ru: "", sp: "" },
	Vousavezselectionne: { fr: "Vous avez séléctionné", en: "You have selected", de: "", it: "Hai selezionato", ru: "", sp: "" },
	saisissezvoscoordonneespourrecevoirplusdinformationsur: {
		fr: "saisissez vos coordonnées pour recevoir plus d'informations sur",
		en: "enter your details to receive more information about",
		de: "",
		it: "inserisci i tuoi dati per ricevere maggiori informazioni su",
		ru: "",
		sp: ""
	},
	Recevoirdesinformationscomplementaires: {
		fr: "Recevoir des informations complémentaires",
		en: "Receive additional information",
		de: "",
		it: "Ricevi ulteriori informazioni",
		ru: "",
		sp: ""
	},
	Urlcopierdansvotrepressepapier: {
		fr: "Url copié dans votre presse papier",
		en: "Url copied to your clipboard",
		de: "",
		it: "URL copiato negli appunti",
		ru: "",
		sp: ""
	},
	Partagercetteoffre: { fr: "Partager cette offre", en: "Share this offer", de: "", it: "Condividi questa offerta", ru: "", sp: "" },
	BienQuiPourraientVousInteresser: {
		fr: "Biens qui pourraient vous intéresser",
		en: "Properties that might interest you",
		de: "",
		it: "Proprietà che potrebbero interessarti",
		ru: "",
		sp: ""
	},
	weblagence: { fr: "L'AGENCE", en: "AGENCY", de: "", it: "AGENZIA", ru: "", sp: "" },
	coexclusivite: { fr: "Co-exclusivité", en: "Co-exclusive", de: "", it: "Co-exclusivité_it", ru: "", sp: "" },
	exclusivite: { fr: "Exclusivité", en: "Exclusive", de: "", it: "Exclusiviti", ru: "", sp: "" },
	webexclusivite: { fr: "Exclusivité", en: "Exclusive", de: "", it: "Esclusività", ru: "", sp: "" },
	webcoexclusivite: { fr: "Co-exclusivité", en: "co-exclusive", de: "", it: "Co-esclusività", ru: "", sp: "" },
	loue: { fr: "Loué", en: "Rented", de: "", it: "Affittato", ru: "", sp: "" },
	vendu: { fr: "Vendu", en: "Sold", de: "", it: "Venduto", ru: "", sp: "" },
	sousoffre: { fr: "Sous-offre", en: "Under offer", de: "", it: "Sotto offerta", ru: "", sp: "" },
	approximatif: {
		fr: "approximatif",
		en: "approximate",
		de: "approximatif",
		it: "approssimativo",
		ru: "approximatif",
		sp: "approximatif"
	},
	Nouveau: { fr: "Nouveau", en: "Nouveau", de: "Nouveau", it: "Nouveau", ru: "Nouveau", sp: "Nouveau" },
	isnew: { fr: "Nouveauté", en: "New", de: "", it: "Novità", ru: "", sp: "" },
	webpieces: { fr: "Nombre de pièces", en: "Number of rooms", de: "", it: "Numero di pezzi", ru: "", sp: "" },
	"Vue mer": { fr: "Vue mer", en: "Vue mer", de: "Vue mer", it: "Vue mer", ru: "Vue mer", sp: "Vue mer" },
	webnewprogram: { fr: "PROGRAMMES NEUFS", en: "NEW PROGRAMS", de: "", it: "NUOVI PROGRAMMI", ru: "", sp: "" },
	"Surface terrain": {
		fr: "Surface terrain",
		en: "Land surface",
		de: "Surface terrain",
		it: "Superficie terreno",
		ru: "Поверхность земли",
		sp: "Surface terrain"
	},
	"Tél : 97.97.79.29 | Fax : 97.97.79.78": {
		fr: "Tél : 97.97.79.29 | Fax : 97.97.79.28",
		en: "Tél : 97.97.79.29 | Fax : 97.97.79.28",
		de: "Tél : 97.97.79.29 | Fax : 97.97.79.28",
		it: "Tél : 97.97.79.29 | Fax : 97.97.79.28",
		ru: "Tél : 97.97.79.29 | Fax : 97.97.79.28",
		sp: "Tél : 97.97.79.29 | Fax : 97.97.79.28"
	},
	"Nombre de SdB": {
		fr: "Nombre de SdB",
		en: "Number of bathroom",
		de: "Nombre de SdB",
		it: "Numero di bagni",
		ru: "Nombre de SdB",
		sp: "Nombre de SdB"
	},
	"Nombre de niveaux": {
		fr: "Nombre de niveaux",
		en: "Number of levels",
		de: "Nombre de niveaux",
		it: "Numero di livelli",
		ru: "Nombre de niveaux",
		sp: "Nombre de niveaux"
	},
	"Nombre de WC": {
		fr: "Nombre de WC",
		en: "Number of WC",
		de: "Nombre de WC",
		it: "Numero di WC",
		ru: "Nombre de WC",
		sp: "Nombre de WC"
	},
	"Nombre de box": {
		fr: "Nombre de box",
		en: "Number of box",
		de: "Nombre de box",
		it: "Numero di garage",
		ru: "Nombre de box",
		sp: "Nombre de box"
	},
	"Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78": {
		fr: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28",
		en: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28",
		de: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28",
		it: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28",
		ru: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28",
		sp: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.28"
	},
	"Nombre de balcons": {
		fr: "Nombre de balcons",
		en: "Number of balconies",
		de: "Nombre de balcons",
		it: "Numero di balconi",
		ru: "Nombre de balcons",
		sp: "Nombre de balcons"
	},
	"14 avenue de la Costa | 98000 Monaco": {
		fr: "14 avenue de la Costa | 98000 Monaco",
		en: "14 avenue de la Costa | 98000 Monaco",
		de: "14 avenue de la Costa | 98000 Monaco",
		it: "14 avenue de la Costa | 98000 Monaco",
		ru: "14 avenue de la Costa | 98000 Monaco",
		sp: "14 avenue de la Costa | 98000 Monaco"
	},
	"Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com": {
		fr: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com",
		en: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com",
		de: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com",
		it: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com",
		ru: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com",
		sp: "Tél : +377.97.97.79.29 | Fax : +377.97.97.79.78 | info@miells.com"
	},
	"non définis": { fr: "non définis", en: "undefined", de: "non définis", it: "indefiniti", ru: "non définis", sp: "non définis" },
	ventes: { fr: "Ventes", en: "Sales", de: "", it: "Saldi", ru: "", sp: "" },
	locations: { fr: "Locations", en: "Rentals", de: "", it: "Affitti", ru: "", sp: "" },
	"Ce document ne fait partie d'aucune offre Ce document ne fait partie d'aucune offre Ce document ne fait partie d'aucune offre Ce document ne fait partie d'aucune offre Ce document ne fait partie d'aucune offre Ce document ne fait partie d'aucune offre Ce":
		{
			fr: "Ce document ne fait partie d’aucune offre ou contrat. Toutes mesures et surfaces sont approximatives. Le descriptif, les surfaces, les photos et les plans ne sont donnés qu’à titre indicatif et leur exactitude n’est pas garantie. L’offre est valable sauf en cas de vente ou de location, retrait de vente ou de location, changement de prix ou d’autres conditions, et ce sans avis préalable.",
			en: "This document does not form part of any offer or contract. All measurements and surfaces are approximate. The description, surface areas, photos and plans are only indicative and their accuracy is not guaranteed. The offer is valid except in case of sale or lease, withdrawal of sale or lease, change of price or other conditions, and without prior notice.",
			de: "Ce document ne fait partie d’aucune offre ou contrat. Toutes mesures et surfaces sont approximatives. Le descriptif, les surfaces, les photos et les plans ne sont donnés qu’à titre indicatif et leur exactitude n’est pas garantie. L’offre est valable sauf en cas de vente ou de location, retrait de vente ou de location, changement de prix ou d’autres conditions, et ce sans avis préalable.",
			it: "Questo documento non fa parte di nessuna offerta o contratto. Tutte le misure e le superfici sono approssimative. La descrizione, le superfici, le foto e le piante sono solo indicative e la loro precisione non è garantita. L'offerta è valida salvo in caso di vendita o locazione, ritiro della vendita o locazione, cambiamento di prezzo o di altre condizioni, e senza preavviso.",
			ru: "Ce document ne fait partie d’aucune offre ou contrat. Toutes mesures et surfaces sont approximatives. Le descriptif, les surfaces, les photos et les plans ne sont donnés qu’à titre indicatif et leur exactitude n’est pas garantie. L’offre est valable sauf en cas de vente ou de location, retrait de vente ou de location, changement de prix ou d’autres conditions, et ce sans avis préalable.",
			sp: "Ce document ne fait partie d’aucune offre ou contrat. Toutes mesures et surfaces sont approximatives. Le descriptif, les surfaces, les photos et les plans ne sont donnés qu’à titre indicatif et leur exactitude n’est pas garantie. L’offre est valable sauf en cas de vente ou de location, retrait de vente ou de location, changement de prix ou d’autres conditions, et ce sans avis préalable."
		},
	"Notes client": {
		fr: "Notes client",
		en: "Notes client",
		de: "Notes client",
		it: "Notes client",
		ru: "Notes client",
		sp: "Notes client"
	},
	Honoraires: { fr: "Honoraires", en: "Honoraires", de: "Honoraires", it: "Honoraires", ru: "Honoraires", sp: "Honoraires" },
	tous: { fr: "Tous", en: "All", de: "", it: "Tutti", ru: "", sp: "" },
	errorcaptcha: {
		fr: "Veuillez resaisir le code captcha",
		en: "Please re-enter the captcha code",
		de: "",
		it: "Si prega di reinserire il codice captcha",
		ru: "",
		sp: ""
	},
	webdiscoveragency: {
		fr: "Découvrez Real Estate Monte-Carlo",
		en: "Discover Real Estate Monte-Carlo",
		de: "",
		it: "Scopri Real Estate Monte-Carlo",
		ru: "",
		sp: ""
	},
	politiquedeconfidentialite: {
		fr: "Politique de confidentialité",
		en: "Privacy Policy",
		de: "",
		it: "Politica sulla riservatezza",
		ru: "",
		sp: ""
	},
	webremctointernational: {
		fr: "Real Estate Monte-Carlo à l'International",
		en: "Real Estate Monte-Carlo internationally",
		de: "",
		it: "Real Estate Monte-Carlo a livello internazionale",
		ru: "",
		sp: ""
	},
	webcoordinateandplan: { fr: "Coordonnées et plan", en: "Contact details and map", de: "", it: "Contatti e mappa", ru: "", sp: "" },
	web_search_appartementamonaco: {
		fr: "Appartement à Monaco",
		en: "Apartment in Monaco",
		de: "",
		it: "Appartamento a Monaco",
		ru: "",
		sp: ""
	},
	web_search_villadanslesalentours: {
		fr: "Villa dans les alentours",
		en: "Villa in the surroundings",
		de: "",
		it: "Villa nei dintorni",
		ru: "",
		sp: ""
	},
	web_search_appartementalouer: {
		fr: "Appartement à louer",
		en: "Apartment for rent",
		de: "",
		it: "Appartamento in affitto",
		ru: "",
		sp: ""
	},
	web_search_biendeplusde100m2: {
		fr: "Un bien de plus de 100m2",
		en: "A property of more than 100m2",
		de: "",
		it: "Una proprietà di oltre 100m2",
		ru: "",
		sp: ""
	},
	web_search_louerunparking: {
		fr: "Louer un parking",
		en: "Rent a parking lot",
		de: "",
		it: "Affittare un parcheggio",
		ru: "",
		sp: ""
	},
	web_search_louerunbureau: { fr: "Louer un bureau", en: "Rent an office", de: "", it: "Affittare un ufficio", ru: "", sp: "" },
	web_notreselectiondumois: {
		fr: "Notre sélection du mois",
		en: "Our selection of the month",
		de: "",
		it: "La nostra selezione del mese",
		ru: "",
		sp: ""
	},
	web_aider_aloueramonaco: { fr: "À louer à Monaco", en: "To rent in Monaco", de: "", it: "Affitto a Monaco", ru: "", sp: "" },
	web_aider_aacheter: {
		fr: "À acheter un bien à Monaco",
		en: "To buy real estate in Monaco",
		de: "",
		it: "Ad acquistare a Monaco",
		ru: "",
		sp: ""
	},
	web_aider_avendre: { fr: "À vendre votre bien", en: "To sell your property", de: "", it: "Vendi il tuo immobile", ru: "", sp: "" },
	web_aider_gerervotrebien: {
		fr: "À gérer votre bien à Monaco",
		en: "To manage your property in Monaco",
		de: "",
		it: "A gestire a Monaco",
		ru: "",
		sp: ""
	},
	web_vousetesaunclick: {
		fr: "Vous êtes à un clic de vos rêves",
		en: "You are one click away from your dreams",
		de: "",
		it: "Sei a un clic dai tuoi sogni",
		ru: "",
		sp: ""
	},
	web_chambres: { fr: "Chambres", en: "Bedrooms", de: "", it: "Camere da letto", ru: "", sp: "" },
	web_Immeuble: { fr: "Immeuble", en: "Building", de: "", it: "Edificio", ru: "", sp: "" },
	web_exclusivite: { fr: "Exclusivité", en: "Exclusive", de: "", it: "Esclusività", ru: "", sp: "" },
	web_coexclusivite: { fr: "Co-exclusivité", en: "co-exclusive", de: "", it: "Co-esclusività", ru: "", sp: "" },
	"Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%": {
		fr: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%",
		en: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%",
		de: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%",
		it: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%",
		ru: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%",
		sp: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 6%"
	},
	recopycode: {
		fr: "Recopier le code ci-dessus (minuscule seulement)",
		en: "Copy the code above (lowercase only)",
		de: "",
		it: "Copia il codice sopra (solo minuscolo)",
		ru: "",
		sp: ""
	},
	ourmissionvalue: {
		fr: "Notre Mission, vous offrir : | Notre professionnalisme | Notre expertise | Notre expérience | Notre savoir-faire | Un conseil personnalisé",
		en: "Our mission is to offer you: | Our professionalism | Our expertise | Our experience | Our know-how | Personal advice",
		de: "",
		it: "La nostra missione è offrirvi: | La nostra professionalità | La nostra competenza | La nostra esperienza | Il nostro know-how | Consulenza personale",
		ru: "",
		sp: ""
	},
	"Murs commerciaux": { fr: "Murs commerciaux", en: "Commercial walls", de: "", it: "Pareti commerciali", ru: "", sp: "" },
	"Programmes neufs": { fr: "Programmes neufs", en: "New programs", de: "", it: "Nuovi programmi", ru: "", sp: "" },
	"2 pièces": { fr: "2 pièces", en: "2 rooms", de: "", it: "2 pezzi", ru: "", sp: "" },
	"3 pièces": { fr: "3 pièces", en: "3 rooms", de: "", it: "3 pezzi", ru: "", sp: "" },
	"4 pièces": { fr: "4 pièces", en: "4 rooms", de: "", it: "4 pezzi", ru: "", sp: "" },
	"5 pièces": { fr: "5 pièces", en: "5 rooms", de: "", it: "5 pezzi", ru: "", sp: "" },
	"+5 pièces": { fr: "+5 pièces", en: "+5 rooms", de: "", it: "+5 pezzi", ru: "", sp: "" },
	web_inscrivezvousanotrenewsletter: {
		fr: "Inscrivez-vous à Notre newsletter",
		en: "Subscribe to our newsletter",
		de: "",
		it: "Iscriviti alla nostra Newsletter",
		ru: "",
		sp: ""
	},
	web_pourrecevoirnoslettresmensuelles: {
		fr: "Pour recevoir nos lettres mensuelles et connaître nos sélections",
		en: "To receive our monthly letters and know our selections",
		de: "",
		it: "Per ricevere le nostre lettere mensili e conoscere le nostre selezioni",
		ru: "",
		sp: ""
	},
	web_recevoirnotrenewsletter: {
		fr: "Recevoir notre newsletter",
		en: "Receive our newsletter",
		de: "",
		it: "Ricevi la nostra newsletter",
		ru: "",
		sp: ""
	},
	Apartment: { fr: "Apartment", en: "Apartment", de: "Apartment", it: "Apartment", ru: "Apartment", sp: "Apartment" },
	Office: { fr: "Office", en: "Office", de: "Office", it: "Office", ru: "Office", sp: "Office" },
	Appartamento: {
		fr: "Appartamento",
		en: "Appartamento",
		de: "Appartamento",
		it: "Appartamento",
		ru: "Appartamento",
		sp: "Appartamento"
	},
	Ufficio: { fr: "Ufficio", en: "Ufficio", de: "Ufficio", it: "Ufficio", ru: "Ufficio", sp: "Ufficio" },
	"2pieces": { fr: "2 pièces", en: "2 rooms", de: "", it: "2 pezzi", ru: "", sp: "" },
	"3pieces": { fr: "3 pièces", en: "3 rooms", de: "", it: "3 pezzi", ru: "", sp: "" },
	"4pieces": { fr: "4 pièces", en: "4 rooms", de: "", it: "4 pezzi", ru: "", sp: "" },
	"5jpieces": { fr: "5 pièces", en: "5 rooms", de: "", it: "5 pezzi", ru: "", sp: "" },
	"5ppieces": { fr: "Plus de 5 pièces", en: "More than 5 rooms", de: "", it: "Più di 5 pezzi", ru: "", sp: "" },
	biens: { fr: "biens", en: "offers", de: "", it: "offerte", ru: "", sp: "" },
	web_visitercetappartement: {
		fr: "Visiter cet appartement",
		en: "Visit this apartment",
		de: "",
		it: "Visita questo appartamento",
		ru: "",
		sp: ""
	},
	web_obtenirplusdinformations: {
		fr: "Obtenir plus d'informations",
		en: "Get more information",
		de: "",
		it: "Ottieni maggiori informazioni",
		ru: "",
		sp: ""
	},
	web_btenirplusdephotos: { fr: "Obtenir plus de photos", en: "Get more pictures", de: "", it: "Ottieni più foto", ru: "", sp: "" },
	Aucun: { fr: "Aucun", en: "Aucun", de: "Aucun", it: "Aucun", ru: "Aucun", sp: "Aucun" },
	webServices: { fr: "Services", en: "Services", de: "", it: "Servizi", ru: "", sp: "" },
	web_aider_aloueramonaco2: { fr: "À louer à Monaco", en: "To rent in Monaco", de: "", it: "Affitto a Monaco", ru: "", sp: "" },
	web_aider_aacheter2: {
		fr: "À acheter un bien à Monaco",
		en: "To buy real estate in Monaco",
		de: "",
		it: "Ad acquistare a Monaco",
		ru: "",
		sp: ""
	},
	web_aider_avendre2: { fr: "À vendre votre bien", en: "To sell your property", de: "", it: "Vendi il tuo immobile", ru: "", sp: "" },
	web_aider_gerervotrebien2: {
		fr: "À gérer votre bien à Monaco",
		en: "To manage your property in Monaco",
		de: "",
		it: "A gestire a Monaco",
		ru: "",
		sp: ""
	},
	contactezvotreagence: {
		fr: "Contactez votre agence",
		en: "Contact your agency",
		de: "",
		it: "Contatta la tua agenzia",
		ru: "",
		sp: ""
	},
	"Honoraires d’Agence : 10% du Loyer Annuel + TVA": {
		fr: "Honoraires d’Agence : 10% du Loyer Annuel + TVA",
		en: "Honoraires d’Agence : 10% du Loyer Annuel + TVA",
		de: "Honoraires d’Agence : 10% du Loyer Annuel + TVA",
		it: "Honoraires d’Agence : 10% du Loyer Annuel + TVA",
		ru: "Honoraires d’Agence : 10% du Loyer Annuel + TVA",
		sp: "Honoraires d’Agence : 10% du Loyer Annuel + TVA"
	},
	Chargesincluses: { fr: "Charges incluses", en: "Charges included", de: "", it: "Spese condominiali incluse", ru: "", sp: "" },
	Charges: { fr: "Charges", en: "Charges", de: "", it: "Spese", ru: "", sp: "" },
	"Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%": {
		fr: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%",
		en: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%",
		de: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%",
		it: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%",
		ru: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%",
		sp: "Honoraires d'agence 3% du prix de vente +TVA - Frais de notaire 9%"
	},
	"Parcheggio/Garage/Box": {
		fr: "Parcheggio/Garage/Box",
		en: "Parcheggio/Garage/Box",
		de: "Parcheggio/Garage/Box",
		it: "Parcheggio/Garage/Box",
		ru: "Parcheggio/Garage/Box",
		sp: "Parcheggio/Garage/Box"
	},
	"Frais de notaire réduits 2,5%": {
		fr: "Frais de notaire réduits 2,5%",
		en: "Frais de notaire réduits 2,5%",
		de: "Frais de notaire réduits 2,5%",
		it: "Frais de notaire réduits 2,5%",
		ru: "Frais de notaire réduits 2,5%",
		sp: "Frais de notaire réduits 2,5%"
	},
	"Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%": {
		fr: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%",
		en: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%",
		de: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%",
		it: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%",
		ru: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%",
		sp: "Honoraires d’agence 3% du prix de vente + TVA – Frais de notaire réduits 2,5%"
	},
	"Honoraire à charge du Bailleur TTC": {
		fr: "Honoraire à charge du Bailleur TTC",
		en: "Honoraire à charge du Bailleur TTC",
		de: "Honoraire à charge du Bailleur TTC",
		it: "Honoraire à charge du Bailleur TTC",
		ru: "Honoraire à charge du Bailleur TTC",
		sp: "Honoraire à charge du Bailleur TTC"
	},
	"Honoraire sur droit au bail à charge du Vendeur": {
		fr: "Honoraire sur droit au bail à charge du Vendeur",
		en: "Honoraire sur droit au bail à charge du Vendeur",
		de: "Honoraire sur droit au bail à charge du Vendeur",
		it: "Honoraire sur droit au bail à charge du Vendeur",
		ru: "Honoraire sur droit au bail à charge du Vendeur",
		sp: "Honoraire sur droit au bail à charge du Vendeur"
	},
	"Murs local commercial": {
		fr: "Murs local commercial",
		en: "Murs local commercial",
		de: "Murs local commercial",
		it: "Murs local commercial",
		ru: "Murs local commercial",
		sp: "Murs local commercial"
	},
	"Autre produit": {
		fr: "Autre produit",
		en: "Autre produit",
		de: "Autre produit",
		it: "Autre produit",
		ru: "Autre produit",
		sp: "Autre produit"
	},
	Local: { fr: "Local", en: "Local", de: "Local", it: "Local", ru: "Local", sp: "Local" },
	Bastide: { fr: "Bastide", en: "Bastide", de: "Bastide", it: "Bastide", ru: "Bastide", sp: "Bastide" },
	"Cessions de droit au bail": {
		fr: "Cessions de droit au bail",
		en: "Cessions de droit au bail",
		de: "Cessions de droit au bail",
		it: "Cessions de droit au bail",
		ru: "Cessions de droit au bail",
		sp: "Cessions de droit au bail"
	},
	"Chambre de service": {
		fr: "Chambre de service",
		en: "Chambre de service",
		de: "Chambre de service",
		it: "Chambre de service",
		ru: "Chambre de service",
		sp: "Chambre de service"
	},
	"Rez-de-jardin": {
		fr: "Rez-de-jardin",
		en: "Rez-de-jardin",
		de: "Rez-de-jardin",
		it: "Rez-de-jardin",
		ru: "Rez-de-jardin",
		sp: "Rez-de-jardin"
	},
	Maison: { fr: "Maison", en: "Maison", de: "Maison", it: "Maison", ru: "Maison", sp: "Maison" },
	"Penthouse/Roof": {
		fr: "Penthouse/Roof",
		en: "Penthouse/Roof",
		de: "Penthouse/Roof",
		it: "Penthouse/Roof",
		ru: "Penthouse/Roof",
		sp: "Penthouse/Roof"
	},
	"Terrain constructible": {
		fr: "Terrain constructible",
		en: "Terrain constructible",
		de: "Terrain constructible",
		it: "Terrain constructible",
		ru: "Terrain constructible",
		sp: "Terrain constructible"
	},
	Garage: { fr: "Garage", en: "Garage", de: "Garage", it: "Garage", ru: "Garage", sp: "Garage" },
	Box: { fr: "Box", en: "Box", de: "Box", it: "Box", ru: "Box", sp: "Box" },
	"Terrain non constructible": {
		fr: "Terrain non constructible",
		en: "Terrain non constructible",
		de: "Terrain non constructible",
		it: "Terrain non constructible",
		ru: "Terrain non constructible",
		sp: "Terrain non constructible"
	},
	Château: { fr: "Château", en: "Château", de: "Château", it: "Château", ru: "Château", sp: "Château" },
	Ferme: { fr: "Ferme", en: "Ferme", de: "Ferme", it: "Ferme", ru: "Ferme", sp: "Ferme" },
	Mas: { fr: "Mas", en: "Mas", de: "Mas", it: "Mas", ru: "Mas", sp: "Mas" },
	Propriété: { fr: "Propriété", en: "Propriété", de: "Propriété", it: "Propriété", ru: "Propriété", sp: "Propriété" },
	"Rez-de-villa": {
		fr: "Rez-de-villa",
		en: "Rez-de-villa",
		de: "Rez-de-villa",
		it: "Rez-de-villa",
		ru: "Rez-de-villa",
		sp: "Rez-de-villa"
	},
	Chalet: { fr: "Chalet", en: "Chalet", de: "Chalet", it: "Chalet", ru: "Chalet", sp: "Chalet" },
	"Date de disponibilité": {
		fr: "Disponibilité",
		en: "Availablity",
		de: "",
		it: "Disponibilità",
		ru: "Доступность",
		sp: "Disponibilidad"
	}
};

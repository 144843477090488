<script>
	import { onMount } from "svelte";
	import {} from "svelte/transition";
	import {} from "./store.js";
	import { formatContactName, formatOfferName, formatDateTime } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import MessagesView from "./MessagesView.svelte";

	export let routename;
	export let location;
	if (location || routename);

	onMount(() => {});
</script>

<main class="container-fluid">
	<div class="row overflow-hidden h-100">
		<div class="col overflow-auto h-100">
			<MessagesView />
		</div>
	</div>
</main>

<style>
</style>

export { default as FormInput } from "./FormInput.svelte";
export { default as FormBase } from "./FormBase.svelte";
export { default as FormInputDropdown } from "./FormInputDropdown.svelte";
export { default as FormDate } from "./FormDate.svelte";
export { default as FormDateTime } from "./FormDateTime.svelte";
export { default as FormCombobox } from "./FormCombobox.svelte";
export { default as FormTime } from "./FormTime.svelte";
export { default as FormSwitch } from "./FormSwitch.svelte";
export { default as FormRange } from "./FormRange.svelte";
export { default as FormCheckbox } from "./FormCheckbox.svelte";
export { default as FormRadio } from "./FormRadio.svelte";
export { default as Calendar } from "./Calendar.svelte";
export { default as Table } from "./Table.svelte";
export { default as Modal } from "./Modal.svelte";
export { default as Dialog } from "./Dialog.svelte";

import * as utils2 from "./utils.js";
export let utils = utils2;

export function init() {
	// document.addEventListener("click", hideDropdown);
	// document.addEventListener("dropdownopened", hideDropdown);
	console.log("init");
}

// import * as uut from "./utils.svelte";
// console.log("uut", uut);
// export let Form = { Input };

<script>
	import { onMount, onDestroy } from "svelte";
	import {} from "svelte/transition";
	import { flip } from "svelte/animate";
	import { dndzone } from "svelte-dnd-action";
	import { session, mainapp } from "./store.js";
	import { formatContactName, formatOfferName, formatDateTime } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import ActionCard from "./ActionCard.svelte";
	import ActionWin from "./ActionWin.svelte";

	export let routename;
	export let location;
	if (location || routename);

	let showActionWin = { show: false };
	let columnItems = [];
	const flipDurationMs = 200;
	let delaySave = "";
	let users = [];
	let usersSelected = [$session.user.co_id];

	$: {
		if (users.length) {
			usersSelected = users
				.filter(user => {
					return user.checked;
				})
				.map(user => {
					return user.co_id;
				});
			load();
		}
	}

	async function load() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/crm`, { users: usersSelected });
		if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		if (res.data) columnItems = res.data;
	}
	async function loadusers() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/contacts`, { gr_id: "users", orderby: "co_name" });
		if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		if (res.data)
			users = res.data.map(user => {
				user.checked = false;
				if (usersSelected.includes(user.co_id)) user.checked = true;
				// console.log("user.checked", usersSelected, user.checked);
				return user;
			});
	}
	function save() {
		delaySave = M_.utils.delay(
			delaySave,
			async () => {
				let actions = [];
				columnItems.map(col => {
					col.items.map((action, indexAction) => {
						actions.push({ ac_state: col.id, ac_id: action.ac_id, ac_state_sort: indexAction + 1 });
					});
				});
				let res = await M_.utils.fetchJsonPost(`/backoffice/1.0/crm/save`, { actions });
			},
			300
		);
	}
	function handleDndConsiderColumns(e) {
		columnItems = e.detail.items;
		console.log("handleDndConsiderColumns", columnItems);
	}
	function handleDndFinalizeColumns(e) {
		columnItems = e.detail.items;
		console.log("handleDndFinalizeColumns", columnItems);
	}
	function handleDndConsiderCards(cid, e) {
		const colIdx = columnItems.findIndex(c => c.id === cid);
		columnItems[colIdx].items = e.detail.items;
		columnItems = [...columnItems];
		console.log("handleDndConsiderCards", columnItems);
	}
	function handleDndFinalizeCards(cid, e) {
		const colIdx = columnItems.findIndex(c => c.id === cid);
		columnItems[colIdx].items = e.detail.items;
		columnItems = [...columnItems];
		console.log("handleDndFinalizeCards", columnItems);
		save();
	}
	function handleClick(e) {
		alert("dragabble elements are still clickable :)");
	}
	function oneditaction(action) {
		// console.log("action", action);
		showActionWin = { show: true, ac_id: action.ac_id };
		// console.log("showActionWin", showActionWin);
		// dispatch("editaction", { action });
	}
	function onactionsaved() {
		load();
	}
	function transformDraggedElement(element, _action, _index) {
		console.log("e", element, _action, _index);
	}

	onMount(() => {
		// load();
		loadusers();
		document.addEventListener("actionsaved", load);
		document.addEventListener("actiondestroyed", load);
	});
	onDestroy(() => {
		document.removeEventListener("actionsaved", load);
		document.removeEventListener("actiondestroyed", load);
	});
</script>

<main class="container-fluid">
	<div class="row overflow-hidden">
		<div class="col d-flex flex-column h-100 overflow-hidden">
			<div class="flex-grow-0 d-flex">
				{#each users as user}
					<M_.FormCheckbox label={formatContactName(user)} bind:value={user.checked} />
				{/each}
			</div>
			<div
				use:dndzone={{ items: columnItems, flipDurationMs, type: "columns" }}
				on:consider={handleDndConsiderColumns}
				on:finalize={handleDndFinalizeColumns}
				class="row flex-grow-1 overflow-hidden"
			>
				<!-- <M_.FormCombobox
				label="Voir les actions de l'utilisateur"
				bind:value={filtersActions.user}
				itemKey="co_id"
				itemValue={contact => formatContactName(contact)}
				remoteUrl="/backoffice/1.0/contacts"
				remoteParams={{ gr_id: "users" }}
			/>
			<M_.FormSwitch bind:value={filtersActions.ac_done} width={50} label="" onText=" " offText=" " /> -->
				<!-- <ActionsList filters={filtersActions} />
			 -->
				{#each columnItems as column (column.id)}
					<div class="col d-flex flex-column h-100 overflow-hidden" animate:flip={{ duration: flipDurationMs }}>
						<div class="flex-grow-0">{column.name}</div>
						<div
							class="flex-grow-1 overflow-auto"
							use:dndzone={{
								items: column.items,
								flipDurationMs,
								transformDraggedElement,
								dropTargetStyle: { outline: "rgba(0, 0, 0, 0.3) solid 2px" }
							}}
							on:consider={e => handleDndConsiderCards(column.id, e)}
							on:finalize={e => handleDndFinalizeCards(column.id, e)}
						>
							{#each column.items as item (item.id)}
								<div animate:flip={{ duration: flipDurationMs }}>
									<ActionCard
										action={item}
										showSwitch={false}
										on:editaction={e => oneditaction(e.detail.action)}
										on:click={handleClick}
									/>
								</div>
							{/each}
						</div>
					</div>
				{/each}
			</div>
		</div>
	</div>
</main>

<ActionWin bind:value={showActionWin} on:saved={onactionsaved} />

<style>
</style>

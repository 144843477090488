<script>
	import { createEventDispatcher, onMount, onDestroy, tick } from "svelte";
	import { slide } from "svelte/transition";
	import { navigate } from "svelte-routing";
	import { itemsTemp, mainapp } from "./store.js";
	import { itemsItems } from "./items.js";
	import { formatContactName } from "./Services.js";
	import * as M_ from "./components/M_.js";

	const dispatch = createEventDispatcher();

	export let value;

	let offer = { of_servicestemp: [] };
	let show_lang = "fr";

	$: {
		if (value.show && value.of_id) load();
	}

	async function load() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/offers/${value.of_id}`);
		if (res.data) {
			offer = res.data;
			offer.of_servicestemp = [];
			for (let iService = 0; iService < itemsItems.items_of_services.length; iService++) {
				const service = itemsItems.items_of_services[iService];
				offer.of_servicestemp[service.key] = false;
			}
		}
	}
	function newOwner() {}
	function changeLang(item) {
		show_lang = item.key;
	}
	function oncanceled(evt) {
		value.show = false;
		dispatch("canceled", { offer, evt });
	}
	async function save(confirm, evt) {
		let err = [];
		if (!offer.of_name) err.push("Le nom en français est obligatoire");
		// if (!offer.of_text_fr) err.push("of_text_fr");
		if (err.length) {
			return $mainapp.dialogAlert("Attention", "Merci de compléter ou corriger les champs suivants :<br>" + err.join("<br>"));
		}

		let res;
		if (offer.of_id) res = await M_.utils.fetchJsonPut(`/backoffice/1.0/offers/${offer.of_id}`, offer);
		else res = await M_.utils.fetchJsonPost(`/backoffice/1.0/offers`, offer);

		value.show = false;
		dispatch("saved", { offer, evt });
	}
	onMount(() => {
		// load();
		// console.log("onmount");
	});
</script>

<M_.Modal bind:value={value.show} modalClass={["offerwin-modal"]}>
	<svelte:fragment slot="header">
		<h5 class="modal-title">Edition du bien {offer.of_name_fr}</h5>
		<button type="button" class="btn-close" data-mo-dismiss="modal" aria-label="Close" />
	</svelte:fragment>

	<form autocomplete="off">
		<div class="row">
			<div class="col-md-5">
				<div class="card">
					<div class="card-body">
						<div class="row g-3">
							<div class="col">
								<M_.FormCombobox
									name="of_type"
									label="Type de bien"
									bind:value={offer.of_type}
									items={$itemsTemp.items_of_type}
									itemKey="key"
									itemValue="value"
								/>
								<M_.FormInput name="of_surface" label="Surface totale" bind:value={offer.of_surface} inline={true} labelSize={8} />
							</div>
							<div class="col">
								<M_.FormInput name="of_ref" label="Référence" bind:value={offer.of_ref} />
								<M_.FormCheckbox
									name="of_surface_app"
									label="Surf. approximative"
									bind:value={offer.of_surface_app}
									containerClass={["mt-2"]}
								/>
							</div>
						</div>
						<div class="row g-3">
							<div class="col">
								<M_.FormInput
									name="of_surface_livable"
									label="Surface habitable"
									bind:value={offer.of_surface_livable}
									type="number"
									inline={true}
									labelSize={8}
								/>
								<M_.FormInput
									name="of_surface_terrace"
									label="Surface terrasse"
									bind:value={offer.of_surface_terrace}
									type="number"
									inline={true}
									labelSize={8}
								/>
								<M_.FormInput
									name="of_surface"
									label="Etage"
									bind:value={offer.of_surface}
									type="number"
									inline={true}
									labelSize={8}
								/>
							</div>
							<div class="col">
								<M_.FormInput
									name="of_surface_garden"
									label="Surface jardin"
									bind:value={offer.of_surface_garden}
									type="number"
									inline={true}
									labelSize={8}
								/>
								<M_.FormInput
									name="of_surface_terrain"
									label="Surface terrain"
									bind:value={offer.of_surface_terrain}
									type="number"
									inline={true}
									labelSize={8}
								/>
							</div>
						</div>
						<div class="row g-3">
							<div class="col">
								<M_.FormInput
									name="of_nbpieces"
									label="Pièces"
									bind:value={offer.of_nbpieces}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbcaves"
									label="Caves"
									bind:value={offer.of_nbcaves}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbbalconies"
									label="Balcons"
									bind:value={offer.of_nbbalconies}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
							</div>
							<div class="col">
								<M_.FormInput
									name="of_nbrooms"
									label="Chambres"
									bind:value={offer.of_nbrooms}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbparkings"
									label="Parkings"
									bind:value={offer.of_nbparkings}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbboxes"
									label="Boxes"
									bind:value={offer.of_nbboxes}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
							</div>
							<div class="col">
								<M_.FormInput
									name="of_nbbathrooms"
									label="S. de bains"
									bind:value={offer.of_nbbathrooms}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbwc"
									label="WC"
									bind:value={offer.of_nbwc}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
								<M_.FormInput
									name="of_nbfloor"
									label="Niveaux"
									bind:value={offer.of_nbfloor}
									type="number"
									inline={true}
									labelSize={7}
									labelReverse={true}
								/>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<M_.FormCombobox
									name="of_typecook"
									label="Type de cuisine"
									bind:value={offer.of_typecook}
									items={$itemsTemp.items_of_typecook}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_typeheating"
									label="Type de chauffage"
									bind:value={offer.of_typeheating}
									items={$itemsTemp.items_of_typeheating}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_natureheating"
									label="Nature de chauffage"
									bind:value={offer.of_natureheating}
									items={$itemsTemp.items_of_natureheating}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_generalstate"
									label="Etat général"
									bind:value={offer.of_generalstate}
									items={$itemsTemp.items_of_generalstate}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_sunny"
									label="Ensoleillement"
									bind:value={offer.of_sunny}
									items={$itemsTemp.items_of_sunny}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_exposition"
									label="Exposition"
									bind:value={offer.of_exposition}
									items={$itemsTemp.items_of_exposition}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_typesubscription"
									label="Type abonnement eau"
									bind:value={offer.of_typesubscription}
									items={$itemsTemp.items_of_typesubscription}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_typeboiler"
									label="Eau chaude"
									bind:value={offer.of_typeboiler}
									items={$itemsTemp.items_of_typeboiler}
									inline={true}
									labelSize={5}
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5>Services</h5>
						<div class="d-flex flex-wrap" />
						{#each itemsItems.items_of_services as service}
							<M_.FormCheckbox name="of_sell_uponrequest" label={service.label_fr} bind:value={offer.of_servicestemp[service.key]} />
						{/each}
					</div>
				</div>
			</div>
			<div class="col-md-7">
				<div class="card">
					<div class="card-body">
						<h5>
							Nom et description
							<span style="font-size: 12px; display: inline-block" class="ms-3">
								{#each $itemsTemp.items_languages as item (item.key)}
									<span>
										<a
											href={"javascript:void(0)"}
											on:click={() => {
												changeLang(item);
											}}
											:class="!offer['of_name_' + item.value] || !offer['of_text_' + item.value] ? 'color-red' : ''"
											>{item.value}</a
										>
										/
									</span>
								{/each}
							</span>
						</h5>
						{#each $itemsTemp.items_languages as item (item.key)}
							<div>
								{#if show_lang == item.key}
									<M_.FormInput
										name={`of_name_${item.key}`}
										label={`Nom en ${item.value}`}
										bind:value={offer[`of_name_${item.key}`]}
										inline={true}
										labelSize={4}
									/>
									<M_.FormInput
										name={`of_text_${item.key}`}
										label={`Description en ${item.value}`}
										bind:value={offer[`of_text_${item.key}`]}
										type="textarea"
										inline={true}
										labelSize={4}
									/>
								{/if}
							</div>
						{/each}
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5>Les images</h5>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col">
						<div class="card">
							<div class="card-body">
								<h5>Statut et visibilité</h5>
								<M_.FormCombobox
									name="of_status"
									label="Statut"
									bind:value={offer.of_status}
									items={$itemsTemp.items_of_status}
									inline="true"
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_sign"
									label="Vitrine"
									bind:value={offer.of_sign}
									items={$itemsTemp.items_of_sign}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_disponibility"
									label="Disponibilité"
									bind:value={offer.of_disponibility}
									items={$itemsTemp.items_of_disponibility}
									inline={true}
									labelSize={5}
								/>
								<M_.FormDate
									name="of_disponibility_date"
									label="Date de disponibilité"
									bind:value={offer.of_disponibility_date}
									inline={true}
									labelSize={7}
								/>
								<M_.FormDate
									name="of_date_entry"
									label="Date entrée du bien"
									bind:value={offer.of_date_entry}
									inline={true}
									labelSize={7}
								/>
								<M_.FormCombobox
									name="of_exclusivite"
									label="Exclusivité"
									bind:value={offer.of_exclusivite}
									items={$itemsTemp.items_of_exclusivite}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_web"
									label="Sur internet"
									bind:value={offer.of_web}
									items={$itemsTemp.items_of_web}
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_ci"
									label="Sur Immotoolsbox"
									bind:value={offer.of_ci}
									items={$itemsTemp.items_of_ci}
									inline={true}
									labelSize={5}
								/>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card">
							<div class="card-body">
								<h5>Le propriétaire et le mandat</h5>
								<M_.FormCombobox
									name="owner"
									label="Propriétaire"
									bind:value={offer.owner}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<div class="mb-2 text-end">
									<button type="button" class="btn btn-secondary btn-xs" on:click={newOwner}>Nouveau propriétaire</button>
								</div>
								<M_.FormCombobox
									name="agency"
									label="Gestion"
									bind:value={offer.agency}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_typemandat"
									label="Type de mandat"
									bind:value={offer.of_typemandat}
									items={$itemsTemp.items_of_typemandat}
									inline={true}
									labelSize={5}
								/>
								<M_.FormInput name="of_mandat" label="Mandat de vente" bind:value={offer.of_mandat} inline={true} labelSize={5} />
								<M_.FormDate
									name="of_startmandat"
									label="Date début mandat"
									bind:value={offer.of_startmandat}
									inline={true}
									labelSize={7}
								/>
								<M_.FormDate
									name="of_endmandat"
									label="Date fin mandat"
									bind:value={offer.of_endmandat}
									inline={true}
									labelSize={7}
								/>
								<M_.FormCombobox
									name="notary"
									label="Notaire"
									bind:value={offer.notary}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="lawyer"
									label="Avocat"
									bind:value={offer.lawyer}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5>Informations commerciales... et consignes de visites</h5>
						<div class="row">
							<div class="col">
								<M_.FormCombobox
									name="commercial1"
									label="Chef de produit 1"
									bind:value={offer.commercial1}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="contributor"
									label="Apporteur d'affaire"
									bind:value={offer.contributor}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_fn"
									label="Honoraires"
									bind:value={offer.of_fn}
									items={$itemsTemp.items_of_honoraires}
									inline={true}
									labelSize={5}
								/>
								<M_.FormInput
									name="of_commissionseller"
									label="Commission vendeur %"
									bind:value={offer.of_commissionseller}
									type="number"
									inline={true}
									labelSize={9}
								/>
							</div>
							<div class="col">
								<M_.FormCombobox
									name="commercial2"
									label="Chef de produit 2"
									bind:value={offer.commercial2}
									itemKey="co_id"
									itemValue={contact => formatContactName(contact)}
									remoteUrl="/backoffice/1.0/contacts"
									inline={true}
									labelSize={5}
								/>
								<M_.FormCombobox
									name="of_urgency"
									label="Urgence"
									bind:value={offer.of_urgency}
									items={$itemsTemp.items_of_type}
									inline={true}
									labelSize={5}
								/>
								<M_.FormInput
									name="of_purcentcontributor"
									label="% apporteur"
									bind:value={offer.of_purcentcontributor}
									type="number"
									inline={true}
									labelSize={7}
								/>
								<M_.FormInput
									name="of_nblots"
									label="Num. de lots"
									bind:value={offer.of_nblots}
									type="number"
									inline={true}
									labelSize={7}
								/>
								<M_.FormInput
									name="of_numkey"
									label="Numéro clé à l'agence"
									bind:value={offer.of_numkey}
									type="number"
									inline={true}
									labelSize={7}
								/>
								<M_.FormInput
									name="of_reasonsale"
									label="Raison de la vente"
									bind:value={offer.of_reasonsale}
									type="number"
									inline={true}
									labelSize={7}
								/>
								<M_.FormInput
									name="of_notes_customer"
									label="Notes client"
									bind:value={offer.of_notes_customer}
									type="number"
									inline={true}
									labelSize={7}
								/>
								<M_.FormInput
									name="of_numkey"
									label="Numéro clé à l'agence"
									bind:value={offer.of_numkey}
									type="number"
									inline={true}
									labelSize={7}
								/>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<M_.FormInput name="of_access" label="Consigne de visite" bind:value={offer.of_access} type="textarea" />
							</div>
							<div class="col">
								<M_.FormInput
									name="of_notes_private"
									label="Notes privées (texte masqué)"
									bind:value={offer.of_notes_private}
									type="textarea"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="col">
									<M_.FormInput
										name="of_notes_private2"
										label="Numéro de lot / appartement / parking (texte masqué)"
										bind:value={offer.of_notes_private2}
										type="textarea"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5><input type="checkbox" bind:checked={offer.of_sell} id="idOfSell" /> <label for="idOfSell">A la vente</label></h5>

						{#if offer.of_sell}
							<div class="row" transition:slide>
								<div class="col">
									<M_.FormCheckbox
										name="of_sell_uponrequest"
										label="Prix sur demande"
										bind:value={offer.of_sell_uponrequest}
										containerClass={["mt-2"]}
									/>
									<M_.FormInput
										name="of_sell_sum"
										label="Prix de présentation"
										bind:value={offer.of_sell_sum}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_sum_fix"
										label="Prix net vendeur"
										bind:value={offer.of_sell_sum_fix}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_lifeannuity"
										label="Viager €/mois"
										bind:value={offer.of_sell_lifeannuity}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_charge"
										label="Charges totales/an"
										bind:value={offer.of_sell_charge}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_workenvisaged"
										label="Travaux envisagés"
										bind:value={offer.of_sell_workenvisaged}
										type="number"
										inline={true}
										labelSize={8}
									/>
								</div>
								<div class="col">
									<M_.FormInput
										name="of_sell_taxdwelling"
										label="Taxe d'habibation"
										bind:value={offer.of_sell_taxdwelling}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_taxrealestate"
										label="Taxe foncière"
										bind:value={offer.of_sell_taxrealestate}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_honorairevendeur"
										label="Honoraire à charge du vendeur TTC"
										bind:value={offer.of_sell_honorairevendeur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_honoraireacquereur"
										label="Honoraire à charge de l'acquéreur TTC"
										bind:value={offer.of_sell_honoraireacquereur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_fraisdenotaire"
										label="Frais de notaire"
										bind:value={offer.of_sell_fraisdenotaire}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_purcentfee"
										label="Pourcentage honoraire vendeur"
										bind:value={offer.of_sell_purcentfee}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_purcentfeeacquereur"
										label="Pourcentage honoraire acquéreur"
										bind:value={offer.of_sell_purcentfeeacquereur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_sell_workenvisaged2"
										label="Travaux votés"
										bind:value={offer.of_sell_workenvisaged2}
										type="number"
										inline={true}
										labelSize={8}
									/>
								</div>
							</div>
						{/if}
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5><input type="checkbox" bind:checked={offer.of_rent} id="idOfRent" /> <label for="idOfRent">A la location</label></h5>
						{#if offer.of_rent}
							<div class="row" transition:slide>
								<div class="col">
									<M_.FormCheckbox
										name="of_rent_uponrequest"
										label="Prix sur demande"
										bind:value={offer.of_rent_uponrequest}
										containerClass={["mt-2"]}
									/>
									<M_.FormInput
										name="of_rent_sum"
										label="Loyer mensuel"
										bind:value={offer.of_rent_sum}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_sum_year"
										label="Loyer annuel"
										bind:value={offer.of_rent_sum_year}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_charge"
										label="Charges mensuelles"
										bind:value={offer.of_rent_charge}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_charge_year"
										label="Charges annuelles"
										bind:value={offer.of_rent_charge_year}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormCheckbox
										name="of_rent_charge_included"
										label="Charges incluses"
										bind:value={offer.of_rent_charge_included}
										containerClass={["mt-2"]}
									/>
									<M_.FormDate
										name="of_rent_echeancebail"
										label="Echéance du bail"
										bind:value={offer.of_rent_echeancebail}
										inline={true}
										labelSize={7}
									/>
									<M_.FormDate
										name="of_rent_disponibilitebien"
										label="Disponibilité du bien"
										bind:value={offer.of_rent_disponibilitebien}
										inline={true}
										labelSize={7}
									/>
								</div>
								<div class="col">
									<M_.FormCombobox
										name="of_rent_typecharge"
										label="Type de charge"
										bind:value={offer.of_rent_typecharge}
										items={$itemsTemp.items_of_rent_typecharge}
										inline={true}
										labelSize={5}
									/>
									<M_.FormCombobox
										name="of_rent_regimebail"
										label="Type de bail"
										bind:value={offer.of_rent_regimebail}
										items={$itemsTemp.items_of_rent_regimebail}
										inline={true}
										labelSize={5}
									/>
									<M_.FormInput
										name="of_rent_honorairebailleur"
										label="Honoraire à charge du preneur TTC"
										bind:value={offer.of_rent_honorairebailleur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_honoraireacquereur"
										label="Honoraire à charge du Bailleur TTC"
										bind:value={offer.of_rent_honoraireacquereur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_droitbail_honorairebailleur"
										label="Honoraire sur droit au bail à charge du Vendeur"
										bind:value={offer.of_rent_droitbail_honorairebailleur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormInput
										name="of_rent_droitbail_honoraireacquereur"
										label="Honoraire sur droit au bail à charge de l'acquéreur"
										bind:value={offer.of_rent_droitbail_honoraireacquereur}
										type="number"
										inline={true}
										labelSize={8}
									/>
									<M_.FormCheckbox
										name="of_rent_resilier"
										label="Résilier par anticipation"
										bind:value={offer.of_rent_resilier}
										containerClass={["mt-2"]}
									/>
								</div>
							</div>
						{/if}
					</div>
				</div>
			</div>
		</div>
	</form>

	<svelte:fragment slot="footer">
		<button type="button" class="btn btn-secondary" on:click={oncanceled}>Annuler</button>
		<button
			type="button"
			class="btn btn-primary"
			on:click={evt => {
				save(false, evt);
			}}>Enregistrer</button
		>
	</svelte:fragment>
</M_.Modal>

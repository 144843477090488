<script>
	import { onMount, createEventDispatcher, tick } from "svelte";
	import { fade } from "svelte/transition";
	import { navigate } from "svelte-routing";
	import {
		formatContactName,
		formatOfferName,
		formatEventName,
		formatFromArray,
		formatDateTime,
		formatContactFunctions,
		formatEmail,
		formatTel,
		formatContactAdress
	} from "./Services.js";
	import { mainapp, groups, currentContact } from "./store.js";
	import * as M_ from "./components/M_.js";
	import ContactWin from "./ContactWin.svelte";

	const dispatch = createEventDispatcher();

	export let co_id = null;
	export let edit = false;

	let contact = {};
	let isMounted = false;
	let showContactWin = { show: false };
	let tofollow = false;

	$: {
		if (co_id && isMounted) load();
	}
	$: {
		// console.log("edit", edit);
		if (edit) editContact();
	}
	// $: {
	// 	tofollow;
	// 	saveFollow();
	// }

	async function saveFollow() {
		await M_.utils.fetchJsonPut(`/backoffice/1.0/follow/contact/${co_id}`, { tofollow });
	}
	async function load() {
		// co_id = _co_id;
		// contact = M_.utils.cloneObject($contactTemp);
		// contact.co_name = "nom " + _co_id;
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/contacts/${co_id}`);
		if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		if (res.data) contact = res.data;
		tofollow = contact.tofollow;
	}
	async function editContact() {
		await tick();
		showContactWin = { show: true, co_id };
		console.log("showContactWin", showContactWin);
		edit = false;
		dispatch("editing", { co_id });
	}
	function onContactWinSaved(evt) {
		co_id = evt.detail?.contact?.co_id;
		load();
		dispatch("saved", { co_id });
	}
	function onContactWinCanceled(evt) {
		dispatch("canceled", { co_id });
	}
	function onContactWinDestroyed(evt) {
		dispatch("destroyed", { co_id });
	}

	onMount(async () => {
		isMounted = true;
		if (co_id) await load();
		if (co_id && edit) await editContact();
	});
</script>

<div class="container-fluid">
	<div class="row">
		<div class="col">
			<div class="d-flex">
				<div>
					<h1>
						{formatContactName(contact)}
						{#if contact.co_code}<span class="fs-6"> - {contact.co_code}</span>{/if}
					</h1>
					<h3>{formatContactFunctions(contact)}</h3>
				</div>
				<div class="ms-auto d-flex justify-content-end">
					<div class="me-2">Suivre le contact</div>
					<M_.FormSwitch bind:value={tofollow} width={50} label="" onText=" " offText=" " on:input={saveFollow} />
				</div>
				<div class="ms-3">
					<button class="btn btn-primary" on:click={editContact}>Modifier</button>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<h5>Coordonnées</h5>
					<table class="tableinfos">
						{#if contact.co_email1}
							<tr>
								<td class="text-no-wrap">Email 1</td>
								<td>{@html formatEmail(contact.co_email1)}</td>
							</tr>
						{/if}
						{#if contact.co_email2}
							<tr>
								<td class="text-no-wrap">Email 2</td>
								<td>{@html formatEmail(contact.co_email2)}</td>
							</tr>
						{/if}
						{#if contact.co_email3}
							<tr>
								<td class="text-no-wrap">Email 3</td>
								<td>{@html formatEmail(contact.co_email3)}</td>
							</tr>
						{/if}

						{#if contact.co_tel1}
							<tr>
								<td class="text-no-wrap">Téléphone 1</td>
								<td>{@html formatTel(contact.co_tel1)}</td>
							</tr>
						{/if}
						{#if contact.co_tel2}
							<tr>
								<td class="text-no-wrap">Téléphone 2</td>
								<td>{@html formatTel(contact.co_tel2)}</td>
							</tr>
						{/if}
						{#if contact.co_tel3}
							<tr>
								<td class="text-no-wrap">Téléphone 3</td>
								<td>{@html formatTel(contact.co_tel3)}</td>
							</tr>
						{/if}

						{#if contact.co_mobile1}
							<tr>
								<td class="text-no-wrap">Mobile 1</td>
								<td>{@html formatTel(contact.co_mobile1)}</td>
							</tr>
						{/if}
						{#if contact.co_mobile2}
							<tr>
								<td class="text-no-wrap">Mobile 2</td>
								<td>{@html formatTel(contact.co_mobile2)}</td>
							</tr>
						{/if}
						{#if contact.co_mobile3}
							<tr>
								<td class="text-no-wrap">Mobile 3</td>
								<td>{@html formatTel(contact.co_mobile3)}</td>
							</tr>
						{/if}

						{#if contact.co_web1}
							<tr>
								<td class="text-no-wrap">Web 1</td>
								<td>{@html formatTel(contact.co_web1)}</td>
							</tr>
						{/if}
						{#if contact.co_web2}
							<tr>
								<td class="text-no-wrap">Web 2</td>
								<td>{@html formatTel(contact.co_web2)}</td>
							</tr>
						{/if}
						{#if contact.co_web3}
							<tr>
								<td class="text-no-wrap">Web 3</td>
								<td>{@html formatTel(contact.co_web3)}</td>
							</tr>
						{/if}

						{#if contact.co_fax1}
							<tr>
								<td class="text-no-wrap">Fax 1</td>
								<td>{@html formatTel(contact.co_fax1)}</td>
							</tr>
						{/if}
						{#if contact.co_fax2}
							<tr>
								<td class="text-no-wrap">Fax 2</td>
								<td>{@html formatTel(contact.co_fax2)}</td>
							</tr>
						{/if}
						{#if contact.co_fax3}
							<tr>
								<td class="text-no-wrap">Fax 3</td>
								<td>{@html formatTel(contact.co_fax3)}</td>
							</tr>
						{/if}
					</table>
				</div>
				<div class="col">
					<h5>Adresse</h5>
					<div>{@html formatContactAdress(contact)}</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ContactWin bind:value={showContactWin} on:saved={onContactWinSaved} on:canceled={onContactWinCanceled} on:destroyed={onContactWinDestroyed} />

<script>
	import FormBase from "./FormBase.svelte";
	import { createEventDispatcher, onMount } from "svelte";

	const dispatch = createEventDispatcher();

	export let value = false;
	export let label = "";
	export let labelReverse = false;
	export let labelFor = "";
	export let labelSize = 3;
	export let width = 200;
	export let height = 25;
	export let validationClass = [];
	export let inputClass = [];
	export let dotColor = "white";
	export let onText = "On";
	export let offText = "Off";
	export let onColor = "var(--bs-success)";
	export let offColor = "var(--bs-secondary)";
	export let displayed = true;
	export let inline = false;
	export let help = "";
	export let feedback = "";
	export let labelClass = [];
	export let containerClass = [];

	const inputClassDefault = ["mformswitch-container"];
	// if ($$slots.prepend || $$slots.append) mainClassDefault.push("input-group");
	// if (inline) inputClassDefault.push("col");
	const margin = 2;
	let containerEl, dotEl;

	$: {
		if (value) setToogle();
		else setToogle();
	}

	function setToogle() {
		if (!dotEl || !containerEl) return;
		// console.log("ici", value);
		if (value) {
			dotEl.style.left = width - height + "px";
			containerEl.style.backgroundColor = onColor;
		} else {
			dotEl.style.left = margin + "px";
			containerEl.style.backgroundColor = offColor;
		}
		dotEl.style.top = margin + "px";
		dotEl.style.width = height - 2 * margin + "px";
		dotEl.style.height = height - 2 * margin + "px";
		dotEl.style.backgroundColor = dotColor;
		containerEl.style.width = width + "px";
		containerEl.style.height = height + "px";
		containerEl.style.borderRadius = height / 2 + "px";
	}

	function switchSwitch() {
		value = !value;
		setToogle();
		dispatch("input", value);
	}
	onMount(() => {
		setToogle();
	});
</script>

<FormBase
	{label}
	{displayed}
	{inline}
	{labelReverse}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	on:clicklabel={switchSwitch}
>
	<div class="{inputClassDefault.join(' ')} {validationClass.join(' ')} {inputClass.join(' ')}" on:click={switchSwitch} bind:this={containerEl}>
		<div class="mformswitch-dot" bind:this={dotEl}>
			<div class="mformswitch-left">{onText}</div>
			<div class="mformswitch-right">{offText}</div>
		</div>
	</div>
</FormBase>

<style>
	.mformswitch-container {
		display: block;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		transition: background-color 0.2s ease;
	}
	.mformswitch-contains {
		display: flex;
		position: absolute;
	}
	.mformswitch-dot {
		position: relative;
		background-color: red;
		border-radius: 50%;
		transition: left 0.2s ease;
	}
	.mformswitch-left,
	.mformswitch-right {
		position: absolute;
		color: white;
		top: 0;
		line-height: 1rem;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.mformswitch-left {
		right: 30px;
	}

	.mformswitch-right {
		left: 30px;
	}
</style>

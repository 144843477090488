<script>
	import { onMount } from "svelte";
	// import { fade } from "svelte/transition";
	import { navigate } from "svelte-routing";
	import { itemsTemp, mainapp } from "./store.js";
	import { formatOfferName, nl2br } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import OffersDetailsCard from "./OffersDetailsCard.svelte";
	import OfferWin from "./OfferWin.svelte";

	export let of_id;
	export let routename;
	export let location;
	if (location);
	// console.log("routename", routename);

	// mainapp.dialogAlert("titre", "texte");

	let offer = {};
	let showOfferWin = { show: false };
	let tofollow = false;

	async function saveFollow() {
		await M_.utils.fetchJsonPut(`/backoffice/1.0/follow/offer/${of_id}`, { tofollow });
	}
	async function load() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/offers/${of_id}`);
		if (res.err) {
			return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		}
		if (res.data) offer = res.data;
		tofollow = offer.tofollow;
	}
	function returnList() {
		navigate("/offers");
	}
	function openOfferWin() {
		showOfferWin = { show: true, of_id };
		// navigate(`/offers/${offer.of_id}/edit`, { replace: true });
		window.history.pushState("", "", `/offers/edit/${of_id}`);
	}
	function offerWinCanceled() {
		// navigate(`/offers/${offer.of_id}/details`, { replace: true });
		window.history.pushState("", "", `/offers/details/${of_id}`);
	}
	function offerWinSaved() {
		// navigate(`/offers/${offer.of_id}/details`, { replace: true });
		load();
		window.history.pushState("", "", `/offers/details/${of_id}`);
	}
	function offerWinDestroyed() {
		navigate(`/offers`);
	}

	onMount(async () => {
		if (!of_id) returnList();
		await load();
		if (routename == "offersedit") openOfferWin();
	});
</script>

<main class="container-fluid">
	<div class="row overflow-auto">
		<div class="col-md-5 ">
			<div class="card">
				<div class="card-img-top">Image</div>
			</div>

			<div class="card">
				<div class="card-body">
					<h5>Les actions</h5>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<h5>0 corrélation</h5>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<h5>0 fil d'infos</h5>
				</div>
			</div>
		</div>
		<div class="col-md-7">
			<div class="row">
				<div class="col ">
					<div class="mb-2 d-flex">
						<button type="button" class="btn btn-secondary" on:click={returnList}>Retour liste</button>
						<div class="ms-auto d-flex justify-content-end">
							<div class="me-2">Suivre le bien</div>
							<M_.FormSwitch bind:value={tofollow} width={50} label="" onText=" " offText=" " on:input={saveFollow} />
						</div>
						<button type="button" class="btn btn-primary ms-3" on:click={openOfferWin}>Modifier</button>
					</div>
					<div class="card mb-2 bg-yellow">
						<div class="card-body">
							<!-- <h5>Description du bien</h5> -->
							<h5>{formatOfferName(offer)}</h5>
							<p class="mb-0">{@html nl2br(offer.of_text_fr)}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col ">
					<div class="card">
						<div class="card-body">
							<h5>Les détails</h5>
							<OffersDetailsCard items={$itemsTemp.items_frame_details} {offer} />
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Les services</h5>
							<OffersDetailsCard items={$itemsTemp.items_of_services} {offer} />
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Le propriétaire et le mandat</h5>
							<OffersDetailsCard items={$itemsTemp.items_frame_mandat} {offer} />
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Immeuble, adresse et quartier</h5>
							<OffersDetailsCard items={$itemsTemp.items_frame_building} {offer} />
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Fichiers</h5>
						</div>
					</div>
				</div>
				<div class="col ">
					<div class="card">
						<div class="card-body">
							<h5>Statut et visibilité</h5>
							<OffersDetailsCard items={$itemsTemp.items_frame_visibility} {offer} />
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Diffusions</h5>
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h5>Informations commerciales... et consignes de visites</h5>
							<OffersDetailsCard items={$itemsTemp.items_frame_commercial} {offer} />
						</div>
					</div>

					{#if offer.of_sell}
						<div class="card">
							<div class="card-body">
								<h5>A la vente</h5>
								<OffersDetailsCard items={$itemsTemp.items_frame_tosell} {offer} />
							</div>
						</div>
					{/if}

					{#if offer.of_rent}
						<div class="card">
							<div class="card-body">
								<h5>A la location</h5>
								<OffersDetailsCard items={$itemsTemp.items_frame_torent} {offer} />
							</div>
						</div>
					{/if}

					<div class="card">
						<div class="card-body">
							<h5>Avis des clients</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
<OfferWin bind:value={showOfferWin} on:canceled={offerWinCanceled} on:saved={offerWinSaved} on:destroyed={offerWinDestroyed} />

<script>
	import { onMount, onDestroy } from "svelte";
	import {} from "svelte/transition";
	import {} from "svelte-routing";
	import { groups, currentContact, mainapp } from "./store.js";
	import { formatOfferName, formatContactName } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import ContactDetails from "./ContactDetails.svelte";
	import Fa from "svelte-fa";
	import { faPlus, faAngleRight, faAnglesRight, faAngleLeft, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

	// console.log("currentContact", currentContact, $currentContact);

	export let co_id = null;
	export let gr_id = "all";
	export let routename;
	export let location;
	if (location);

	let contacts = [];
	// let group = $groups[0];
	let editContact = false;
	let searchContact = "";
	let delayContacts;
	let contactsDiv;
	let skip = 0,
		limit = 10,
		total = 0;
	let numpage = 0,
		totalpage = 0;

	$: {
		searchContact;
		delayContacts = M_.utils.delay(delayContacts, loadContacts, 300);
	}
	// $: {
	// 	console.log("editContact", editContact);
	// }

	async function loadContacts() {
		if (contactsDiv) limit = Math.ceil(contactsDiv.offsetHeight / 25) - 1;
		let params = { gr_id, skip, limit, text: searchContact, orderby: "t1.co_name" };
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/contacts`, params);
		if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		if (res.data) {
			contacts = res.data;
			total = res.meta.total;
			numpage = Math.ceil(skip / limit) + 1;
			totalpage = Math.ceil(total / limit);
		}
		window.history.pushState("", "", `/contacts/group/${gr_id}/details/${co_id}`);
	}
	function onContactSaved(evt) {
		co_id = evt.detail.co_id;
		// console.log("co_id", co_id);
		window.history.pushState("", "", `/contacts/group/${gr_id}/details/${co_id}`);
	}
	function onContactCanceled() {
		window.history.pushState("", "", `/contacts/group/${gr_id}/details/${co_id}`);
	}
	function onContactDestroyed() {
		co_id = -1;
		loadContacts();
		window.history.pushState("", "", `/contacts/group/${gr_id}/details/-1`);
	}

	async function loadContact(_co_id) {
		co_id = _co_id;
		// let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/contacts/${co_id}`);
		// if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		// if (res.data) contacts = res.data;
		window.history.pushState("", "", `/contacts/group/${gr_id}/details/${co_id}`);
	}
	function onClickGroup(item, evt) {
		console.log("item", item);
		gr_id = item.gr_id;
		loadContacts();
	}
	function onClickContact(item, evt) {
		loadContact(item.co_id);
		$currentContact = item;
	}
	function onEditingContact(evt) {
		window.history.pushState("", "", `/contacts/group/${gr_id}/edit/${co_id}`);
	}
	function addNewContact() {
		co_id = -1;
		editContact = true;
	}
	function pager(_skip) {
		skip = _skip;
		if (skip < 0) skip = 0;
		// console.log("skip", skip);
		loadContacts();
	}

	function reportWindowSize() {
		skip = 0;
		loadContacts();
	}
	onMount(() => {
		if (routename == "ContactDetails" || routename == "contactsedit") loadContact(co_id);
		if (routename == "contacts" && $currentContact) loadContact($currentContact.co_id);
		if (routename == "contactsedit") editContact = true;
		window.addEventListener("resize", reportWindowSize);
	});
	onDestroy(() => {
		window.removeEventListener("resize", reportWindowSize);
	});
</script>

<main class="container-fluid">
	<div class="row h-100">
		<div class="col-3 h-100 overflow-auto">
			<div class="d-flex justify-content-between">
				<h5 class="mb-0">Groupes</h5>
				<button type="button" class="btn btn-primary" on:click={addNewContact}><Fa icon={faPlus} /> </button>
			</div>
			<M_.Table items={$groups} itemKey="gr_id" tableClass={["table-sm", "mt-2"]}>
				<svelte:fragment slot="tds" let:item>
					<td
						class="pointer {gr_id == item.gr_id ? 'table-active' : ''}"
						on:click={evt => {
							onClickGroup(item, evt);
						}}>{item.value}</td
					>
				</svelte:fragment>
			</M_.Table>
		</div>
		<div class="col-3 h-100 d-flex flex-column overflow-hidden">
			<div class="flex-grow-0 d-flex justify-content-between">
				<div class="d-flex w-100">
					<h5 class="mb-0">{total} contacts</h5>
					<div class="ms-auto btn-group">
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == 0}
							on:click={() => {
								pager(0);
							}}
							><Fa icon={faAnglesLeft} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == 0}
							on:click={() => {
								pager(skip - limit);
							}}
							><Fa icon={faAngleLeft} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == Math.ceil(total / limit) * limit - limit}
							on:click={() => {
								pager(skip + limit);
							}}
							><Fa icon={faAngleRight} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == Math.ceil(total / limit) * limit - limit}
							on:click={() => {
								pager(Math.ceil(total / limit) * limit - limit);
							}}
							><Fa icon={faAnglesRight} />
						</button>
					</div>
					<div><div class="mt-2 ms-2">page {numpage}/{totalpage}</div></div>
				</div>
			</div>
			<div class="flex-grow-0 mt-2">
				<M_.FormInput bind:value={searchContact} placeholder="Rechercher" />
			</div>
			<div class="flex-grow-1 overflow-auto mt-2" bind:this={contactsDiv}>
				<M_.Table items={contacts} itemKey="co_id" tableClass={["table-sm"]}>
					<svelte:fragment slot="tds" let:item>
						<td
							class="pointer {item.co_id == co_id ? 'table-active' : ''}"
							on:click={evt => {
								onClickContact(item, evt);
							}}>{formatContactName(item)}</td
						>
					</svelte:fragment>
				</M_.Table>
			</div>
		</div>
		<div class="col-6 h-100 overflow-auto">
			<ContactDetails
				{co_id}
				bind:edit={editContact}
				on:editing={onEditingContact}
				on:saved={onContactSaved}
				on:canceled={onContactCanceled}
				on:destroyed={onContactDestroyed}
			/>
		</div>
	</div>
</main>

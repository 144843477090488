<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	export let inline = false;
	export let labelReverse = false;
	export let label = "";
	export let labelButton = "";
	export let help = "";
	export let feedback = "";
	export let labelFor = "";
	export let labelClass = [];
	export let containerClass = ["mb-1"];
	export let labelSize = 3;
	export let displayed = true;

	const labelClassDefault = inline ? ["col-form-label"] : ["form-label"];
	if (inline) labelClassDefault.push("col-sm-" + labelSize);
	const containerClassDefault = ["has-validation", "position-relative", "form-control-container"];
	if (inline) containerClassDefault.push("row", "gx-1");
	if (labelReverse) containerClassDefault.push("flex-row-reverse");
	let containerEl;

	$: {
		if (containerEl) {
			// console.log("containerEl.classList", containerEl.classList);
			containerEl.style.display = displayed ? (containerEl.classList.contains("row") ? "flex" : "block") : "none";
			// console.log("displayed", displayed);
		}
	}

	function onclickcontainer(evt) {
		dispatch("clickcontainer", evt);
	}
	function onclicklabel(evt) {
		dispatch("clicklabel", evt);
	}
	function onclicklabelbutton(evt) {
		evt.stopPropagation();
		dispatch("clicklabelbutton", evt);
	}
</script>

<div bind:this={containerEl} on:click={onclickcontainer} class="{containerClassDefault.join(' ')} {containerClass.join(' ')}">
	<slot name="label" {label} {labelClass}>
		{#if label}
			<label for={labelFor} on:click={onclicklabel} class="{labelClassDefault.join(' ')} {labelClass.join(' ')}">
				{label}
				{#if labelButton}
					<button class="btn btn-secondary btn-xs" type="button" on:click={onclicklabelbutton}>{labelButton}</button>
				{/if}
			</label>
		{/if}
	</slot>

	<slot />

	{#if help}
		<div>{help}</div>
	{/if}
	{#if feedback}
		<div>{feedback}</div>
	{/if}
</div>

<style>
	.form-control-container {
		margin-bottom: 3px;
	}
</style>

<script>
	import FormInputDropdown from "./FormInputDropdown.svelte";
	import { fetchJsonGet, fetchJsonPost, fetchJsonPut, getDataFromProperties, delay } from "./utils.js";
	import { createEventDispatcher, onMount, tick } from "svelte";
	// import Fa from "svelte-fa";
	import { faArrowDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";

	const dispatch = createEventDispatcher();

	export let value = null;
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let labelButton = "";
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let icon = faCaretDown;
	export let selectOnFocus = true;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];

	export let noneText = "Aucun résultat";
	export let noneDisplay = true;
	export let hideSelected = false;
	export let clearValueIfEmpty = true;
	export let itemKey = "key";
	export let itemValue = "value";
	export let items = [];
	export let search = null;
	export let remoteUrl = "";
	export let remoteMethod = "GET";
	export let remoteParams = {};
	export let remoteTextProperty = "text";
	export let remoteDataProperty = "data"; // "data.list"

	// console.log("itemValue", itemVa lue);
	// console.log("items", items);
	let valueIsItem = remoteUrl ? true : false;
	let itemsOk = [];
	let inputDropdown;
	let inputValue = "";
	let delayId = "";

	$: {
		if (items) {
			itemsOk = [...items];
			if (inputDropdown) {
				setTimeout(() => {
					inputDropdown.setPositionDropDown();
				}, 100);
			}
			// synchInputVal();
		}
		// console.log("itemsOk", itemsOk);
	}
	$: {
		if (value || !value) synchInputVal();
	}

	function synchInputVal() {
		// console.log("name", name);
		inputValue = getText(value);
		// console.log("inputValue1", inputValue, value);
	}
	function findItem(v) {
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (item[itemKey] == v) return item;
		}
		return null;
	}
	function getText(item) {
		// console.log("typeof item", typeof item, value);
		if (itemValue instanceof Function) {
			return itemValue(item);
		}
		if (!valueIsItem) {
			let itemTemp = findItem(item);
			if (!itemTemp) return "";
			return itemTemp[itemValue];
		}
		if (!item || !item[itemValue]) return "";
		return item[itemValue];
	}
	async function ondropdownshown(evt) {
		if (remoteUrl) loadStore();
		dispatch("dropdownshown", evt);
	}
	async function ondropdownhidden(evt) {
		await tick();
		synchInputVal();
		dispatch("dropdownhidden", evt);
	}
	function onclickitem(item, evt) {
		value = valueIsItem ? item : item[itemKey];
		console.log("value", value);
		synchInputVal();
		inputDropdown.hideDropdown();
		dispatch("clickitem", { item, evt });
	}
	async function loadStore() {
		// console.log("loadStore");
		let res,
			tabMethods = { POST: fetchJsonPost, GET: fetchJsonGet, PUT: fetchJsonPut };
		let params = { ...remoteParams };
		params[remoteTextProperty] = inputValue;
		// console.log("inputValue2", inputValue);
		res = await tabMethods[remoteMethod.toUpperCase()](remoteUrl, params);
		items = getDataFromProperties(res, remoteDataProperty);
		itemsOk = [...items];
	}
	async function oninput(evt) {
		evt.stopPropagation();
		await tick();
		if (clearValueIfEmpty) value = null;
		if (search) {
			items = await search(inputValue, items);
		} else if (remoteUrl) {
			delayId = delay(
				delayId,
				() => {
					loadStore();
				},
				100
			);
		} else {
			itemsOk = items.filter(item => {
				let v = getText(valueIsItem ? item : item[itemKey]);
				console.log("v", v);
				return v.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) >= 0;
			});
		}
		dispatch("input", evt);
	}

	onMount(() => {
		// synchInputVal();
	});
</script>

<FormInputDropdown
	bind:this={inputDropdown}
	bind:value={inputValue}
	{label}
	{icon}
	{inline}
	{labelReverse}
	{labelButton}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	{inputClass}
	{id}
	{name}
	{placeholder}
	{selectOnFocus}
	{prependClass}
	{appendClass}
	dropdownTag="ul"
	on:input={oninput}
	on:focus
	on:click
	on:dropdownshown={ondropdownshown}
	on:dropdownhidden={ondropdownhidden}
	on:clicklabelbutton
>
	<svelte:fragment slot="dropdown">
		{#each itemsOk as item, indexItem (item[itemKey])}
			<li on:click={evt => onclickitem(item, evt)}>
				<slot name="item">
					{#if !hideSelected || !value || (hideSelected && item[itemKey] !== value[itemKey])}
						<a
							class:dropdown-item={true}
							class:active={value && (item[itemKey] === value || item[itemKey] === value[itemKey])}
							href={"javascript:void(0)"}>{getText(valueIsItem ? item : item[itemKey])}</a
						>
					{/if}
				</slot>
			</li>
		{/each}
		{#if itemsOk == 0 && noneDisplay}
			<li><a class:dropdown-item={true} href={"javascript:void(0)"}>{noneText}</a></li>
		{/if}
	</svelte:fragment>
</FormInputDropdown>

<style>
</style>

<script>
	import { onMount } from "svelte";
	import { fade, slide } from "svelte/transition";
	import { session, itemsTemp } from "./store.js";
	import { formatFromArray } from "./Services.js";
	import * as M_ from "./components/M_.js";

	export let show;

	let lists = [];
	let showreal = false;

	$: {
		if (show) load();
		else showreal = false;
	}

	async function load() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/preferences/lists`);
		lists = res.data;
		showreal = true;
	}
	onMount(() => {});

	function edit() {}
</script>

{#if showreal}
	<div class="row" transition:slide>
		<div class="col">
			<div class="mb-2">
				<button class="btn btn-primary">Nouveau élément</button>
			</div>

			<table class="table table-striped w-100">
				<thead>
					<tr>
						<th>Liste</th>
						<th>Clé</th>
						<th>Valeur</th>
						<th>Options</th>
						<th style="width: 50px">&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{#each lists as row_li, indexList (indexList)}
						<tr>
							<td>
								{formatFromArray(row_li.li_name, $itemsTemp.items_lists, "")}
							</td>
							<td>{row_li.value}</td>
							<td>{row_li.text}</td>
							<td>{row_li.options}</td>
							<td>
								<!-- <v-icon @click="$refs.listwinedit.open(row_li.li_id)">mdi-pencil</v-icon> -->
							</td>
						</tr>
					{/each}
				</tbody>
			</table>
		</div>
	</div>
{/if}

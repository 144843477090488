<script>
	import { onMount } from "svelte";
	import * as M_ from "./components/M_.js";
	import PreferencesPerso from "./PreferencesPerso.svelte";
	import PreferencesShared from "./PreferencesShared.svelte";
	import PreferencesRights from "./PreferencesRights.svelte";
	import PreferencesLists from "./PreferencesLists.svelte";

	export let routename;
	export let location;
	if (location || routename);

	let btnOpenPerso;
	let showPreferencesPerso = false;
	function openPreferencesPerso() {
		showPreferencesPerso = !showPreferencesPerso;
		if (showPreferencesPerso) {
			btnOpenPerso.innerText = "Fermer";
			window.history.pushState("", "", `/preferences/perso`);
		} else {
			btnOpenPerso.innerText = "Ouvrir";
			window.history.pushState("", "", `/preferences`);
		}
	}
	let btnOpenShared;
	let showPreferencesShared = false;
	function openPreferencesShared() {
		showPreferencesShared = !showPreferencesShared;
		if (showPreferencesShared) {
			btnOpenShared.innerText = "Fermer";
			window.history.pushState("", "", `/preferences/shared`);
		} else {
			btnOpenShared.innerText = "Ouvrir";
			window.history.pushState("", "", `/preferences`);
		}
	}
	let btnOpenRights;
	let showPreferencesRights = false;
	function openPreferencesRights() {
		showPreferencesRights = !showPreferencesRights;
		if (showPreferencesRights) {
			btnOpenRights.innerText = "Fermer";
			window.history.pushState("", "", `/preferences/rights`);
		} else {
			btnOpenRights.innerText = "Ouvrir";
			window.history.pushState("", "", `/preferences`);
		}
	}
	let btnOpenLists;
	let showPreferencesLists = false;
	function openPreferencesLists() {
		showPreferencesLists = !showPreferencesLists;
		if (showPreferencesLists) {
			btnOpenLists.innerText = "Fermer";
			window.history.pushState("", "", `/preferences/lists`);
		} else {
			btnOpenLists.innerText = "Ouvrir";
			window.history.pushState("", "", `/preferences`);
		}
	}
	onMount(() => {
		if (routename == "preferencesperso") openPreferencesPerso();
		if (routename == "preferencesshared") openPreferencesShared();
		if (routename == "preferencesrights") openPreferencesRights();
		if (routename == "preferenceslists") openPreferencesLists();
	});
</script>

<main class="container-fluid">
	<div class="row overflow-hidden h-100">
		<div class="col overflow-auto h-100">
			<div class="card">
				<div class="card-body">
					<h3>
						Préférences personnelles <button
							bind:this={btnOpenPerso}
							type="button"
							class="btn btn-primary"
							on:click={openPreferencesPerso}>Ouvrir</button
						>
					</h3>

					<PreferencesPerso show={showPreferencesPerso} />
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h3>
						Préférences partagées <button bind:this={btnOpenShared} type="button" class="btn btn-primary" on:click={openPreferencesShared}
							>Ouvrir</button
						>
					</h3>
					<PreferencesShared show={showPreferencesShared} />
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h3>
						Les droits des utilisateurs <button
							bind:this={btnOpenRights}
							type="button"
							class="btn btn-primary"
							on:click={openPreferencesRights}>Ouvrir</button
						>
					</h3>
					<PreferencesRights show={showPreferencesRights} />
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h3>
						Les listes <button bind:this={btnOpenLists} type="button" class="btn btn-primary" on:click={openPreferencesLists}
							>Ouvrir</button
						>
					</h3>
					<PreferencesLists show={showPreferencesLists} />
				</div>
			</div>
		</div>
	</div>
</main>

<script>
	import FormInputDropdown from "./FormInputDropdown.svelte";
	import { createEventDispatcher, onMount } from "svelte";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");
	import { pad } from "./utils.js";

	const dispatch = createEventDispatcher();

	export let value = "";
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let icon = "";
	export let selectOnFocus = false;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];

	export let closeOnDate = true;
	export let showHours = true;
	export let showMinutes = true;
	export let showSeconds = false;

	export let format = "HH:mm";
	export let valueFormat = "HH:mm:ss";

	let inputDropdown;
	let inputValue = "";

	let hours = [];
	let minutes = [];
	let seconds = [];

	$: {
		if (!value || value.substring(0, 10) == "0000-00-00") value = "";
		syncInput();
	}

	function createLists() {
		for (let i = 0; i < 24; i++) {
			hours.push({ key: i, value: pad(i) });
		}
		for (let i = 0; i < 60; i += 5) {
			minutes.push({ key: i, value: pad(i) });
		}
		for (let i = 0; i < 60; i += 5) {
			seconds.push({ key: i, value: pad(i) });
		}
		hours = hours;
		minutes = minutes;
		seconds = seconds;
	}

	function syncInput() {
		if (!value || value.substring(0, 10) == "0000-00-00") {
			value = "0000-00-00 00:00:00";
			inputValue = "";
		} else inputValue = parseDate().format(format);
	}
	function parseDate() {
		let d = dayjs(value, valueFormat);
		if (d.isValid()) return d;
		return "0000-00-00 00:00:00";
	}

	function setHour(h) {
		value = parseDate().set("hour", h).format(valueFormat);
		if (closeOnDate && !showMinutes) inputDropdown.hideDropdown();
		syncInput();
		dispatch("time", { date: value });
	}
	function setMinute(m) {
		value = parseDate().set("minute", m).format(valueFormat);
		if (closeOnDate && !showSeconds) inputDropdown.hideDropdown();
		syncInput();
		dispatch("time", { date: value });
	}
	function setSecond(s) {
		value = parseDate().set("second", s).format(valueFormat);
		if (closeOnDate) inputDropdown.hideDropdown();
		syncInput();
		dispatch("time", { date: value });
	}

	onMount(() => {
		syncInput();
		createLists();
	});
</script>

<FormInputDropdown
	bind:this={inputDropdown}
	bind:value={inputValue}
	{label}
	{icon}
	{inline}
	{labelReverse}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	{inputClass}
	{id}
	{name}
	{placeholder}
	{selectOnFocus}
	{prependClass}
	{appendClass}
	on:focus
	on:click
	on:dropdownshown
	on:dropdownhidden
>
	<div slot="dropdown">
		<div class="d-flex mformtime-container">
			{#if showHours}
				<div class="mformtime-hours">
					{#each hours as hour (hour.key)}
						<div
							on:click={evt => {
								setHour(hour.key, evt);
							}}
						>
							{hour.value}
						</div>
					{/each}
				</div>
			{/if}
			{#if showMinutes}
				<div class="mformtime-minutes">
					{#each minutes as minute (minute.key)}
						<div
							on:click={evt => {
								setMinute(minute.key, evt);
							}}
						>
							{minute.value}
						</div>
					{/each}
				</div>
			{/if}
			{#if showSeconds}
				<div class="mformtime-seconds">
					{#each seconds as second (second.key)}
						<div
							on:click={evt => {
								setSecond(second.key, evt);
							}}
						>
							{second.value}
						</div>
					{/each}
				</div>
			{/if}
		</div>
	</div>
</FormInputDropdown>

<style>
	.mformtime-container {
		padding: 15px;
		height: 100%;
	}
	.mformtime-hours,
	.mformtime-minutes,
	.mformtime-seconds {
		width: 100px;
		overflow: auto;
		text-align: center;
		cursor: pointer;
	}
</style>

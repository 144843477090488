<script>
	import { slide } from "svelte/transition";
	import { formatContactName } from "./Services.js";
	import { session } from "./store.js";
	import * as M_ from "./components/M_.js";

	export let show;
</script>

{#if show}
	<div class="row" transition:slide>
		<div class="col-4">
			<M_.FormCombobox
				label="Fiche de mon agence"
				bind:value={$session.prefsShared.agency}
				itemKey="co_id"
				itemValue={contact => formatContactName(contact)}
				remoteUrl="/backoffice/1.0/contacts"
			/>
			<M_.FormInput bind:value={$session.prefsShared.nextref} label="Prochaine référence bien" />
			<M_.FormInput bind:value={$session.prefsShared.reftemplate} label="Modèle de référence" />
			<M_.FormInput bind:value={$session.prefsShared.refmax} label="Nombre de chiffres pour la référence" />
			<M_.FormInput bind:value={$session.prefsShared.closeddays} label="Jours fériés" />
		</div>
		<div class="col-4">
			<M_.FormCheckbox bind:value={$session.prefsShared.useimmonaco} label="Champs pour Immonaco" />
			<M_.FormCheckbox bind:value={$session.prefsShared.usebuilding} label="Utiliser la base immeuble" />
			<M_.FormCheckbox bind:value={$session.prefsShared.usediagnostic} label="Utiliser les diagnostiques" />
			<M_.FormCheckbox bind:value={$session.prefsShared.usefinancialinfos} label="Utiliser les infos financières (Contact)" />
			<M_.FormCheckbox bind:value={$session.prefsShared.mailemptycalendar} label="Envoyer mail au négaciateur si calendrier non rempli" />
		</div>
		<div class="col-4 ">
			<div class="d-flex flex-wrap">
				<M_.FormCheckbox bind:value={$session.prefsShared.usefr} label="Français" />
				<M_.FormCheckbox bind:value={$session.prefsShared.useen} label="Anglais" />
				<M_.FormCheckbox bind:value={$session.prefsShared.useit} label="Italien" />
				<M_.FormCheckbox bind:value={$session.prefsShared.useru} label="Russe" />
				<M_.FormCheckbox bind:value={$session.prefsShared.usees} label="Espagnol" />
				<M_.FormCheckbox bind:value={$session.prefsShared.usede} label="Allemand" />
			</div>
		</div>
	</div>
{/if}

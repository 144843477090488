<script>
	import { onMount } from "svelte";
	import { slide } from "svelte/transition";
	import { session, itemsTemp } from "./store.js";
	import * as M_ from "./components/M_.js";

	export let show;

	$: {
		if (show) load();
		else showreal = false;
	}

	let roles = [],
		resources = [],
		rolesresources = {};
	let showreal = false;

	async function load() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/preferences/rights`);
		roles = res.roles;
		resources = res.resources;
		let rolesresourcesTemp = res.rolesresources;
		// rolesresources = {};
		roles.map(role => {
			rolesresources[role.riro_id] = {};
			resources.map(resource => {
				rolesresources[role.riro_id][resource.rire_id] = false;
				for (let iRR = 0; iRR < rolesresourcesTemp.length; iRR++) {
					const roleresource = rolesresourcesTemp[iRR];
					if (roleresource.riro_id == role.riro_id && roleresource.rire_id == resource.rire_id) {
						rolesresources[role.riro_id][resource.rire_id] = true;
					}
				}
			});
		});
		showreal = true;
		// console.log("rolesresources", rolesresources);
	}
	function checkCol() {}
	function checkLine() {}
	onMount(() => {
		// load();
	});
</script>

{#if showreal}
	<div class="row" transition:slide>
		<div class="col">
			<table class="table table-striped  w-100">
				<thead>
					<tr>
						<th>Droits</th>
						{#each roles as role, indexRole (role.riro_id)}
							<th
								class="item-header"
								on:click={() => {
									checkCol(indexRole);
								}}
							>
								<strong>{role.riro_label}</strong>
							</th>
						{/each}
					</tr>
				</thead>
				<tbody>
					{#each resources as resource, indexResource (indexResource)}
						<tr>
							<td
								class="bis"
								on:click={() => {
									checkLine(indexResource);
								}}
							>
								{resource.rire_label}
							</td>
							{#each roles as role, indexRole (role.riro_id)}
								<td class="item-header text-center font-weight-bold" :key="indexCol">
									<!-- <v-simple-checkbox v-model="itemRow[itemCol.value]" /> -->
									<!-- <M_.FormCheckbox bind:value={toto} label="" /> -->
									<input type="checkbox" bind:checked={rolesresources[role.riro_id][resource.rire_id]} />
								</td>
							{/each}
						</tr>
					{/each}
				</tbody>
			</table>
		</div>
	</div>
{/if}

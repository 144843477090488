<script>
	import { createEventDispatcher, onMount, tick } from "svelte";
	// import { fade } from "svelte/transition";
	import { mainapp, itemsTemp } from "./store.js";
	import Fa from "svelte-fa";
	import { faPhone, faClipboardCheck, faComment, faHandshake, faEnvelope, faEye, faKey, faPaste } from "@fortawesome/free-solid-svg-icons";
	import { formatOfferName } from "./Services.js";
	import * as M_ from "./components/M_.js";

	// console.log('itemsTemp', $itemsTemp.);

	const dispatch = createEventDispatcher();

	export let value = { show: false };

	let contact = {};
	let els = {
		co_email1: [null, false],
		co_email2: [null, false],
		co_email3: [null, false],
		co_tel1: [null, false],
		co_tel2: [null, false],
		co_tel3: [null, false],
		co_fax1: [null, false],
		co_fax2: [null, false],
		co_fax3: [null, false],
		co_mobile1: [null, false],
		co_mobile2: [null, false],
		co_mobile3: [null, false],
		co_web1: [null, false],
		co_web2: [null, false],
		co_web3: [null, false],
		co_address1: [null, false],
		co_address2: [null, false],
		co_address3: [null, false]
	};

	// $: {
	// 	console.log("value2", value);
	// }

	$: {
		if (value) load();
	}

	async function cancel() {
		value.show = false;
		dispatch("canceled");
		// await tick();
	}
	async function save(evt) {
		// showConfirmSave = true;
		// if (confirmed) {
		let res;
		if (contact.co_id) res = await M_.utils.fetchJsonPut(`/backoffice/1.0/contacts/${contact.co_id}`, contact);
		else res = await M_.utils.fetchJsonPost(`/backoffice/1.0/contacts`, contact);

		value.show = false;
		dispatch("saved", { evt, contact: res.data });
		// await tick();
		// }
	}
	async function destroy(evt) {
		if (!contact.co_id) return $mainapp.dialogAlert("Erreur effacement", "Vous ne pouvez pas effacer une fiche en création");
		$mainapp.dialogConfirm(
			"Effacement",
			"Etes-vous certain de vouloir effacer cette fiche ?",
			async () => {
				let res = await M_.utils.fetchJsonDelete(`/backoffice/1.0/contacts/${contact.co_id}`);
				dispatch("destroyed", { evt, event: res.data });
				document.dispatchEvent(new Event("contactdestroyed"));
				value.show = false;
			},
			() => {}
		);
	}
	async function load() {
		if (!value || !value.co_id) return;
		// console.log("value.start", value.start);
		// console.log("load::event.ve_id", event.ve_id);
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/contacts/${value.co_id}`);
		if (res.err) return $mainapp.dialogAlert("Erreur chargement", "Le chargement de la page ne s'est pas bien déroulé.");
		if (res.data) contact = res.data;
		showHideFields();
	}
	function showHideFields() {
		let t = ["co_email", "co_fax", "co_mobile", "co_tel", "co_web", "co_address"];
		for (let iT = 0; iT < t.length; iT++) {
			const e = t[iT];
			els[e + "1"][1] = true;
			els[e + "2"][1] = false;
			els[e + "3"][1] = false;
			if (contact[e + "2"]) {
				els[e + "2"][1] = true;
				els[e + "3"][1] = true;
			} else if (contact[e + "1"]) {
				els[e + "2"][1] = true;
			}
		}

		// for (let p in els) {
		// 	// console.log("p", p.substring(p.length - 1, p.length));
		// 	let n = p.substring(p.length - 1, p.length) * 1;
		// 	if (contact[p] || n == 1) {
		// 		els[p][1] = true;
		// 	} else els[p][1] = false;
		// 	// console.log("els[p][1],p", els[p][1], p);
		// }
	}
	function onshown() {
		showHideFields();
		// console.log("els", els);
	}
	onMount(async () => {
		// console.log("els", els);
		showHideFields();
		// await tick();
		// console.log("co_email1", co_email1);
	});
	// load();

	// function offerToAdd() {}
</script>

<M_.Modal bind:value={value.show} on:shown={onshown}>
	<svelte:fragment slot="header">
		<h5 class="modal-title">Edition d'un contact</h5>
		<button type="button" class="btn-close" data-mo-dismiss="modal" aria-label="Close" />
	</svelte:fragment>

	<form autocomplete="off">
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-4">
								<M_.FormCombobox label="Type de contact" bind:value={contact.co_type} items={$itemsTemp.items_co_type} />
								<!-- <M_.FormInput bind:value={contact.co_name} label="Nom" /> -->
							</div>
							{#if contact.co_type != "contact"}
								<div class="col-4">
									<M_.FormInput bind:value={contact.co_login} label="Identifiant" />
								</div>
								<div class="col-4">
									<M_.FormInput bind:value={contact.co_pass} type="password" label="Mot de passe" />
								</div>
							{:else}
								<div class="col-8 d-flex flex-wrap">
									<M_.FormCheckbox bind:value={contact.co_issociety} label="Société" />
									<M_.FormCheckbox bind:value={contact.co_isowner} label="Propriétaire" />
									<M_.FormCheckbox bind:value={contact.co_isrenter} label="Locataire" />
									<M_.FormCheckbox bind:value={contact.co_isagent} label="Négociateur immo" />
									<M_.FormCheckbox bind:value={contact.co_iscaretaker} label="Concierge" />
									<M_.FormCheckbox bind:value={contact.co_islawyer} label="Avocat" />
									<M_.FormCheckbox bind:value={contact.co_isagency} label="Agence immobilière" />
									<M_.FormCheckbox bind:value={contact.co_issyndic} label="Syndic" />
									<M_.FormCheckbox bind:value={contact.co_isintermediaire} label="Apporteur d'affaire" />
								</div>
							{/if}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<M_.FormInput bind:value={contact.co_name} label="Nom" inline={true} />
						<M_.FormInput bind:value={contact.co_firstname} label="Prénom" inline={true} />
						<M_.FormInput bind:value={contact.co_society} label="Société" inline={true} />
						<M_.FormInput bind:value={contact.co_function} label="Fonction" inline={true} />
						<M_.FormInput bind:value={contact.co_code} label="Code" inline={true} />
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<M_.FormInput
							inline={true}
							bind:value={contact.co_address1}
							bind:this={els.co_address1[0]}
							displayed={els.co_address1[1]}
							label="Adresse 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_address2}
							bind:this={els.co_address2[0]}
							displayed={els.co_address2[1]}
							label="Adresse 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_address3}
							bind:this={els.co_address3[0]}
							displayed={els.co_address3[1]}
							label="Adresse 3"
							on:input={showHideFields}
						/>
						<div class="d-flex">
							<label for="" class="w-25">CP / Ville</label>
							<M_.FormInput bind:value={contact.co_zip} label="" inline={true} containerClass={["w-25"]} />
							<M_.FormInput bind:value={contact.co_city} label="" containerClass={[]} />
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<M_.FormInput bind:value={contact.co_comment} type="textarea" label="Commentaire" containerClass={[]} />
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-body">
						<M_.FormInput
							inline={true}
							bind:value={contact.co_email1}
							bind:this={els.co_email1[0]}
							displayed={els.co_email1[1]}
							label="Email 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_email2}
							bind:this={els.co_email2[0]}
							displayed={els.co_email2[1]}
							label="Email 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_email3}
							bind:this={els.co_email3[0]}
							displayed={els.co_email3[1]}
							label="Email 3"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_tel1}
							bind:this={els.co_tel1[0]}
							displayed={els.co_tel1[1]}
							label="Tél 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_tel2}
							bind:this={els.co_tel2[0]}
							displayed={els.co_tel2[1]}
							label="Tél 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_tel3}
							bind:this={els.co_tel3[0]}
							displayed={els.co_tel3[1]}
							label="Tél 3"
							on:input={showHideFields}
						/>

						<M_.FormInput
							inline={true}
							bind:value={contact.co_mobile1}
							bind:this={els.co_mobile1[0]}
							displayed={els.co_mobile1[1]}
							label="Mobile 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_mobile2}
							bind:this={els.co_mobile2[0]}
							displayed={els.co_mobile2[1]}
							label="Mobile 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_mobile3}
							bind:this={els.co_mobile3[0]}
							displayed={els.co_mobile3[1]}
							label="Mobile 3"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_web1}
							bind:this={els.co_web1[0]}
							displayed={els.co_web1[1]}
							label="Web 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_web2}
							bind:this={els.co_web2[0]}
							displayed={els.co_web2[1]}
							label="Web 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_web3}
							bind:this={els.co_web3[0]}
							displayed={els.co_web3[1]}
							label="Web 3"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_fax1}
							bind:this={els.co_fax1[0]}
							displayed={els.co_fax1[1]}
							label="Fax 1"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_fax2}
							bind:this={els.co_fax2[0]}
							displayed={els.co_fax2[1]}
							label="Fax 2"
							on:input={showHideFields}
						/>
						<M_.FormInput
							inline={true}
							bind:value={contact.co_fax3}
							bind:this={els.co_fax3[0]}
							displayed={els.co_fax3[1]}
							label="Fax 3"
							on:input={showHideFields}
						/>
					</div>
				</div>
			</div>
		</div>
	</form>

	<svelte:fragment slot="footer">
		<button type="button" class="btn btn-danger me-auto" on:click={destroy}>Effacer</button>
		<button type="button" class="btn btn-secondary" on:click={cancel}>Annuler</button>
		<button type="button" class="btn btn-primary" on:click={save}>Enregistrer</button>
	</svelte:fragment>
</M_.Modal>

<style>
</style>

let nextId = 0;

const interceptorsBefore = [],
	interceptorsAfter = [],
	interceptorsError = [];
let fetchHost = "";

let tabDelay = {};

const buildParams = data => {
	const params = new URLSearchParams();
	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			for (let index = 0; index < value.length; index++) {
				const value2 = value[index];
				params.append(key + "[]", value2);
			}
		} else params.append(key, data[key]);
	}
	return params;
};

async function _fetchJson(url, data, opts) {
	// console.log("data", data);
	// console.log("opts", opts);
	// if (data) {
	// }
	// console.log("opts.body", opts.body);

	try {
		opts.url = url;
		opts.host = fetchHost;
		if (!opts.headers) opts.headers = {};
		if (!opts.headers["Content-Type"]) opts.headers["Content-Type"] = "application/json;charset=utf-8";
		if (!opts.method || opts.method.toUpperCase() == "GET") opts.params = data;
		else opts.body = data;
		// console.log("opts.params", opts.params);
		for (let i = 0; i < interceptorsBefore.length; i++) {
			const intercept = interceptorsBefore[i];
			await intercept(opts);
		}
		if (opts.body) opts.body = JSON.stringify(opts.body);
		// var headers = new Headers(opts.headers);
		// headers.append("Content-Type", "application/json;charset=utf-8");
		url = opts.host + opts.url;
		// console.log("opts.params", opts.params);
		if (opts.params) url += "?" + buildParams(opts.params);
		// const request = new Request(url, opts);
		let res = await fetch(url, opts);
		for (let i = 0; i < interceptorsAfter.length; i++) {
			const intercept = interceptorsBefore[i];
			await intercept(res);
		}
		// console.log("res", res);
		return await res.json();
	} catch (error) {
		console.warn("fetcherror", error);
		for (let i = 0; i < interceptorsError.length; i++) {
			const intercept = interceptorsBefore[i];
			await intercept(error);
		}
		return {};
	}
}

// export const utils = {
export function pad(num, n) {
	// let res = new Array(n).join("0").slice((n || 2) * -1) + num;
	let res = num + "";
	if (num < 10) res = "0" + num;
	return res;
}
export function getId() {
	return "m_id_" + nextId++;
}
export function cloneArray(arr, deep = true) {
	if (deep) return JSON.parse(JSON.stringify(arr));
	return [...arr];
}
export function cloneObject(obj, deep = true) {
	if (deep) return JSON.parse(JSON.stringify(obj));
	return { ...obj };
}
export function find(arr, what) {
	for (let i = 0; i < arr.length; i++) {
		const el = arr[i];
		let ok = true;
		for (const prop in what) {
			if (el[prop] && el[prop] != what[prop]) ok = false;
		}
		if (ok) return el;
	}
	return null;
}
export function delay(name, cb, delay) {
	if (!name) {
		name = getId();
		tabDelay[name] = null;
	}
	if (tabDelay[name]) clearTimeout(tabDelay[name]);
	tabDelay[name] = setTimeout(() => {
		delete tabDelay[name];
		cb();
	}, delay);
	return name;
}
export function wait(whatFn, maxDelay, delay = 50) {
	return new Promise((resolve, reject) => {
		let tsMax = setTimeout(() => {
			resolve(false);
		}, maxDelay);
		function test() {
			if (whatFn()) {
				clearTimeout(tsMax);
				return resolve(true);
			}
			retry();
		}
		function retry() {
			setTimeout(() => {
				test();
			}, delay);
		}
		test();
	});
}

export async function fetchJsonGet(url, data = null, opts = {}) {
	// console.log("params", params);
	return _fetchJson(url, data, opts);
}
export async function fetchJsonPost(url, data, opts = {}) {
	// console.log("opts", opts, { ...opts, method: "POST" });
	return _fetchJson(url, data, { ...opts, method: "POST" });
}
export async function fetchJsonPut(url, data, opts = {}) {
	return _fetchJson(url, data, { ...opts, method: "PUT" });
}
export async function fetchJsonDelete(url, data, opts = {}) {
	return _fetchJson(url, data, { ...opts, method: "DELETE" });
}

export function addFetchInterceptorBefore(fn) {
	interceptorsBefore.push(fn);
}
export function addFetchInterceptorAfter(fn) {
	interceptorsAfter.push(fn);
}
export function addFetchInterceptorError(fn) {
	interceptorsError.push(fn);
}
export function setFetchHost(host) {
	fetchHost = host;
}
export function getFetchHost() {
	return fetchHost;
}

export function getDataFromProperties(res, property) {
	let t = property.split(".");
	let obj = res;
	for (let i = 0; i < t.length; i++) {
		const d = t[i];
		obj = obj[d];
	}
	return obj;
}
// };

// export function useFetch(url) {
// 	const data = ref(null);
// 	const error = ref(null);

// 	async function doFetch() {
// 		// reset state before fetching..
// 		data.value = null;
// 		error.value = null;

// 		// resolve the url value synchronously so it's tracked as a
// 		// dependency by watchEffect()
// 		const urlValue = unref(url);

// 		try {
// 			// artificial delay / random error
// 			// await timeout();
// 			// unref() will return the ref value if it's a ref
// 			// otherwise the value will be returned as-is
// 			const res = await fetch(urlValue);
// 			data.value = await res.json();
// 		} catch (e) {
// 			error.value = e;
// 		}
// 	}

// 	if (isRef(url)) {
// 		// setup reactive re-fetch if input URL is a ref
// 		watchEffect(doFetch);
// 	} else {
// 		// otherwise, just fetch once
// 		doFetch();
// 	}

// 	return { data, error, retry: doFetch };
// }

// artificial delay
// function timeout() {
// 	return new Promise((resolve, reject) => {
// 		setTimeout(() => {
// 			if (Math.random() > 0.3) {
// 				resolve();
// 			} else {
// 				reject(new Error("Random Error"));
// 			}
// 		}, 300);
// 	});
// }

// export function useStringToObj(prop) {
// 	let p =
// 		typeof prop == "string"
// 			? prop.split(" ").reduce((acc, cur, i) => {
// 					acc[cur] = true;
// 					return acc;
// 			  }, {})
// 			: { ...prop };
// 	return p;
// }

// export function useRandomString(keep = "", length = 12) {
// 	let result = "",
// 		characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
// 		charactersLength = characters.length;
// 	for (var i = 0; i < length; i++) {
// 		result += characters.charAt(Math.floor(Math.random() * charactersLength));
// 	}
// 	if (keep) result = keep + "__" + result;
// 	return result;
// }

// export function cloneArray(arr, deep = false) {
// 	if (deep) return JSON.parse(JSON.stringify(arr));
// 	let myclone = [];
// 	for (let i = 0; i < arr.length; i++) {
// 		const el = arr[i];
// 		myclone.push(el);
// 	}
// 	return myclone;
// }
// export function cloneObject(obj, deep = false) {
// 	if (deep) return JSON.parse(JSON.stringify(obj));
// 	return { ...obj };
// }
// export function copyArrayIn(arr1, arr2) {
// 	arr2.length = 0;
// 	for (let iItem = 0; iItem < arr1.length; iItem++) {
// 		const item = arr1[iItem];
// 		arr2.push(item);
// 	}
// }

<script>
	import { onMount } from "svelte";
	import { Router, Route, Link } from "svelte-routing";
	import { globalHistory, navigate } from "svelte-routing/src/history";
	import { currentPath, session } from "./store.js";
	import * as M_ from "./components/M_.js";
	// import { fade } from "svelte/transition";
	import Login from "./Login.svelte";
	import Home from "./Home.svelte";
	import ActionWin from "./ActionWin.svelte";
	import Contacts from "./Contacts.svelte";
	import Calendar from "./Calendar.svelte";
	import Offers from "./Offers.svelte";
	import OffersDetails from "./OffersDetails.svelte";
	import Preferences from "./Preferences.svelte";
	import Messages from "./Messages.svelte";
	import Stats from "./Stats.svelte";
	import Crm from "./Crm.svelte";

	export let url = "";

	let showActionWin = { show: false };
	let errShowDialog = false;
	let errIsConfirm = false;
	let errTitle = "Attention";
	let errText = "Erreur";
	let errCbOk = null;
	let errCbCancel = null;

	export function dialogAlert(title, text, cbOk = null) {
		dialogConfirm(title, text, cbOk);
	}
	export function dialogConfirm(title, text, cbOk, cbCancel = null) {
		errIsConfirm = true;
		errTitle = title;
		errText = text;
		errShowDialog = true;
		errCbOk = cbOk;
		errCbCancel = cbCancel;
	}
	function errOk() {
		errShowDialog = false;
		if (errCbOk) errCbOk();
	}
	function errCancel() {
		errShowDialog = false;
		if (errCbCancel) errCbCancel();
	}

	function logout() {
		$session = {};
		navigate("/");
	}
	function newAction() {
		showActionWin = { show: true, ac_id: -1, type: "comment" };
	}
	function changeTemplate(template) {
		console.log("template", template);
	}

	// function enableTooltips() {
	// 	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	// 	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	// 		return new bootstrap.Tooltip(tooltipTriggerEl);
	// 	});
	// }

	onMount(() => {
		let unsub = globalHistory.listen(({ location, action }) => {
			// console.log(location, action);
			$currentPath = location.pathname;
			// console.log("$currentPath", $currentPath);
		});
		$currentPath = window.location.pathname;
		// console.log("$currentPath", $currentPath);
		return unsub;
	});
</script>

<Router {url}>
	<div id="app">
		{#if $currentPath !== "/"}
			<aside style="width: 280px; min-width: 280px; max-width: 280px;" class="">
				<div class="d-flex flex-column flex-shrink-0 p-3">
					<ul class="nav nav-pills flex-column mb-auto">
						<li class="nav-item">
							<Link to="/home" class="nav-link link-light">Accueil</Link>
						</li>
						<li>
							<Link to="/offers" class="nav-link link-light">Biens</Link>
						</li>
						<li>
							<Link to="/contacts" class="nav-link link-light">Contacts</Link>
						</li>
						<li>
							<Link to="/calendar" class="nav-link link-light">Calendrier</Link>
						</li>
						<li>
							<Link to="/crm" class="nav-link link-light">CRM</Link>
						</li>
						<li>
							<Link to="/messages" class="nav-link link-light">Fil d'infos</Link>
						</li>
						<li>
							<Link to="/stats" class="nav-link link-light">Statistiques</Link>
						</li>
						<li>
							<Link to="/preferences" class="nav-link link-light">Préférences</Link>
						</li>
						<li>
							<a href={`javascript:void(0);`} on:click={logout} class="nav-link link-light">Déconnexion</a>
						</li>
					</ul>

					<!-- <hr />
					<div class="dropdown">
						<a
							href={"javascript:void(0)"}
							class="d-flex align-items-center link-light text-decoration-none dropdown-toggle"
							id="dropdownUser2"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<strong>mdo</strong>
						</a>
						<ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
							<li><a class="dropdown-item" href={"javascript:void(0)"}>New project...</a></li>
							<li><a class="dropdown-item" href={"javascript:void(0)"}>Settings</a></li>
							<li><a class="dropdown-item" href={"javascript:void(0)"}>Profile</a></li>
							<li><hr class="dropdown-divider" /></li>
							<li><a class="dropdown-item" href={"javascript:void(0)"}>Sign out</a></li>
						</ul>
					</div> -->
				</div>
			</aside>
		{/if}

		<div id="app-container">
			<nav class="navbar navbar-expand-md navbar-dark" aria-label="Third navbar example">
				<div class="container-fluid">
					<a class="navbar-brand" href={"javascript:void(0)"}>Immonaco</a>
					<button
						type="button"
						class="navbar-toggler"
						data-bs-toggle="collapse"
						data-bs-target="#navbarsExample03"
						aria-controls="navbarsExample03"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon" />
					</button>

					<div class="collapse navbar-collapse" id="navbarsExample03">
						<ul class="navbar-nav me-auto mb-2 mb-sm-0">
							<li class="nav-item">
								<!-- <a class="nav-link active" aria-current="page" href={"javascript:void(0)"}>Home</a> -->
								<Link to="/home" class="nav-link">Accueil</Link>
							</li>
							<li class="nav-item">
								<a class="nav-link" on:click={newAction} href={"javascript:void(0)"}>Nouvelle action</a>
							</li>
							<li class="nav-item">
								<a class="nav-link disabled" href={"javascript:void(0)"}>Disabled</a>
							</li>
							<li class="nav-item dropdown">
								<a
									class="nav-link dropdown-toggle"
									href={"javascript:void(0)"}
									id="dropdown03"
									data-bs-toggle="dropdown"
									aria-expanded="false">Charte</a
								>
								<ul class="dropdown-menu" aria-labelledby="dropdown03">
									<li>
										<a
											class="dropdown-item"
											href={"javascript:void(0)"}
											on:click={() => {
												changeTemplate("light");
											}}>Charte light</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											href={"javascript:void(0)"}
											on:click={() => {
												changeTemplate("dark");
											}}>Charte dark</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											href={"javascript:void(0)"}
											on:click={() => {
												changeTemplate("colored");
											}}>Charte coloré</a
										>
									</li>
								</ul>
							</li>
						</ul>
						<form>
							<input class="form-control" type="text" placeholder="Search" aria-label="Search" />
						</form>
						{#if $session.user && $session.user.co_id}
							<div class="text-light">
								{$session.user.co_firstname} <b>{$session.user.co_name}</b>
							</div>
							<div class="rounded-circle bg-gray" style="width:30px;height:30px;">Image</div>
						{/if}
					</div>
				</div>
			</nav>
			<Route path="/" component={Login} routename="login" />
			<Route path="/home" component={Home} routename="home" />
			<Route path="/contacts" component={Contacts} routename="contacts" />
			<Route path="/contacts/group/:gr_id/details/:co_id" component={Contacts} routename="ContactDetails" />
			<Route path="/contacts/group/:gr_id/edit/:co_id" component={Contacts} routename="contactsedit" />
			<Route path="/calendar" component={Calendar} routename="calendar" />
			<Route path="/crm" component={Crm} routename="crm" />
			<Route path="/offers" component={Offers} routename="offers" />
			<Route path="/offers/details/:of_id" component={OffersDetails} routename="offersdetails" let:params />
			<Route path="/offers/edit/:of_id" component={OffersDetails} routename="offersedit" let:params />
			<Route path="/preferences" component={Preferences} routename="preferences" />
			<Route path="/preferences/perso" component={Preferences} routename="preferencesperso" />
			<Route path="/preferences/shared" component={Preferences} routename="preferencesshared" />
			<Route path="/preferences/rights" component={Preferences} routename="preferencesrights" />
			<Route path="/preferences/lists" component={Preferences} routename="preferenceslists" />
			<Route path="/messages" component={Messages} routename="messages" />
			<Route path="/stats" component={Stats} routename="stats" />
			<!-- <Route path="/preferences/perso" component={OffersDetails} routename="preferences" let:params /> -->
		</div>
	</div>
</Router>

<ActionWin bind:value={showActionWin} />
<M_.Dialog title={errTitle} bind:value={errShowDialog} isConfirm={errIsConfirm} on:ok={errOk} on:cancel={errCancel}>{@html errText}</M_.Dialog>

<style>
	/* main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	} */

	/* h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	} */

	/* @media (min-width: 640px) {
		main {
			max-width: none;
		}
	} */
</style>

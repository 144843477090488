<script>
	import FormInputDropdown from "./FormInputDropdown.svelte";
	import Calendar from "./Calendar.svelte";
	import { createEventDispatcher } from "svelte";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");

	const dispatch = createEventDispatcher();

	export let value = "";
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let icon = "";
	export let selectOnFocus = false;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];
	export let closeOnDate = true;

	export let format = "DD/MM/YYYY";
	export let valueFormat = "YYYY-MM-DD";

	let inputDropdown;
	let inputValue = "";
	let keepTime = null;

	$: {
		keepTime = dayjs(value);
		// console.log("keepTime.isValid(),value", keepTime.isValid(), value);
		if (!keepTime.isValid()) {
			value = "0000-00-00 00:00:00";
			keepTime = dayjs().startOf("day");
		}
		syncInput();
	}

	function syncInput() {
		if (dayjs(value).isValid() && value.substring(0, 10) !== "0000-00-00") inputValue = dayjs(value).format(format);
		else inputValue = "";
	}
	syncInput();

	function ondate(evt) {
		if (closeOnDate) inputDropdown.hideDropdown();
		let d = dayjs(evt.detail.date);
		if (keepTime) d = d.startOf("day").add(keepTime.hour(), "hour").add(keepTime.minute(), "minute").add(keepTime.second(), "second");
		value = dayjs(d).format(valueFormat);
		syncInput();
		dispatch("date", { date: value, evt });
	}
	function ondropdownshown(evt) {
		if (value == "" || value.substring(0, 10) === "0000-00-00") value = dayjs().format(valueFormat);
		dispatch("dropdownshown", evt);
	}
</script>

<FormInputDropdown
	bind:this={inputDropdown}
	bind:value={inputValue}
	{label}
	{icon}
	{inline}
	{labelReverse}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	{inputClass}
	{id}
	{name}
	{placeholder}
	{selectOnFocus}
	{prependClass}
	{appendClass}
	on:focus
	on:click
	on:dropdownshown={ondropdownshown}
	on:dropdownhidden
>
	<div slot="dropdown">
		<Calendar year={false} {valueFormat} bind:value on:date={ondate} />
	</div>
</FormInputDropdown>

<style>
</style>

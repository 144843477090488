<script>
	import { onMount } from "svelte";
	import {} from "svelte/transition";
	import {} from "svelte-routing";
	import Fa from "svelte-fa";
	import { faAngleRight, faAngleLeft, faCalendarDay, faCalendarWeek, faCalendar } from "@fortawesome/free-solid-svg-icons";
	import * as M_ from "./components/M_.js";
	import {} from "./store.js";
	import CalendarView from "./CalendarView.svelte";

	export let routename;
	export let location;
	if (location || routename);

	let selectedUsers = [];
	let calendarview;

	function calendarWinOpen() {}
	function calendarWinSaved() {}
	function calendarWinCanceled() {}
	function calendarWinDestroyed() {}

	onMount(() => {});
</script>

<main class="container-fluid">
	<div class="row">
		<div class="col">
			<div class="d-flex justify-content-between mb-2" style="flex-grow: 0;">
				<div>
					<h3>Calendrier</h3>
				</div>
				<div>
					<div class="btn-group" role="group" aria-label="Basic example">
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().prev()}
							><span><Fa icon={faAngleLeft} /></span></button
						>
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().today()}>Aujourd'hui</button>
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().next()}
							><span><Fa icon={faAngleRight} /></span></button
						>
					</div>
				</div>
				<div>
					<div class="btn-group" role="group" aria-label="Basic example">
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().changeView("timeGridDay")}
							><span><Fa icon={faCalendarDay} /></span> Jour</button
						>
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().changeView("timeGridWeek")}
							><span><Fa icon={faCalendarWeek} /></span> Semaine</button
						>
						<button type="button" class="btn btn-primary" on:click={calendarview.getCalendar().changeView("dayGridMonth")}
							><span><Fa icon={faCalendar} /></span> Mois</button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row flex-grow-1">
		<div class="col">
			<CalendarView
				bind:this={calendarview}
				users={selectedUsers}
				on:opened={calendarWinOpen}
				on:saved={calendarWinSaved}
				on:canceled={calendarWinCanceled}
				on:destroyed={calendarWinDestroyed}
			/>
		</div>
	</div>
</main>

<script>
	import { fade } from "svelte/transition";
	import { navigate } from "svelte-routing";
	import { offersTemp, filtersOffers, session } from "./store.js";
	import * as M_ from "./components/M_.js";

	export let routename;
	export let location;
	if (location || routename);

	let login = "david@miglior.fr";
	let password = "morph123";

	async function connect() {
		let res = await M_.utils.fetchJsonPost("/backoffice/1.0/login", { login, password });
		if (res.err) {
			return;
		}
		session.update(v => {
			v.user = res.data;
			v.accesstoken = res.data.co_accesstoken;
			v.refreshtoken = res.data.co_refreshtoken;
			return v;
		});
		navigate(`/home`);
	}
</script>

<main class="container-fluid">
	<div class="row">
		<div class="col-3 offset-4">
			<div class="card" style="width: 100%;">
				<div class="card-body">
					<h5 class="card-title">Connection</h5>
					<p class="card-text">Indiquez votre identifiant et mot de passe.</p>
					<M_.FormInput label="Identifiant" bind:value={login} placeholder="" />
					<M_.FormInput type="password" label="Mot de passe" bind:value={password} placeholder="" />
					<button type="button" class="btn btn-primary mt-2" on:click={connect}>Connectez-vous</button>
					<!-- <a href={"javascript:void(0)"} class="btn btn-primary">Go somewhere</a> -->
				</div>
			</div>
		</div>
	</div>
</main>

<script>
	import { onMount } from "svelte";
	import {} from "svelte/transition";
	import { mainapp } from "./store.js";
	import Fa from "svelte-fa";
	import { faTrash } from "@fortawesome/free-solid-svg-icons";
	import { formatContactName, formatOfferName, formatDateTime } from "./Services.js";
	import * as M_ from "./components/M_.js";

	let message = { me_title: "", me_text: "", offer: {}, contact: {} };
	let messages = [];

	async function saveMessage() {
		let res;
		if (message.me_id) res = await M_.utils.fetchJsonPut(`/backoffice/1.0/messages/${message.me_id}`, message);
		else res = await M_.utils.fetchJsonPost(`/backoffice/1.0/messages`, message);
		// console.log("res", res);
		message = { me_title: "", me_text: "", offer: {}, contact: {} };
		await loadMessages();
	}
	async function editMessage(message) {}
	async function destroyMessage(message) {
		$mainapp.dialogConfirm(
			"Effacement",
			"Etes-vous certain de vouloir effacer cette fiche ?",
			async () => {
				let res = await M_.utils.fetchJsonDelete(`/backoffice/1.0/messages/${message.me_id}`);
				await loadMessages();
				dispatch("destroyed", { evt, event: res.data });
				document.dispatchEvent(new Event("messagedestroyed"));
				value.show = false;
			},
			() => {}
		);
	}
	async function loadMessages() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/messages`, { orderby: "createdAt desc" });
		messages = res.data;
	}
	onMount(() => {
		loadMessages();
	});
</script>

<div>
	<h3>Fil d'infos</h3>
	<!-- <M_.FormInput bind:value={message.me_title} label="Object" /> -->
	<M_.FormInput bind:value={message.me_text} type="textarea" label="Message" />
	<M_.FormCombobox
		inline={true}
		label="Bien"
		bind:value={message.offer}
		itemKey="of_id"
		itemValue={offer => formatOfferName(offer)}
		remoteUrl="/backoffice/1.0/offers"
	/>
	<M_.FormCombobox
		inline={true}
		label="Contact"
		bind:value={message.contact}
		itemKey="co_id"
		itemValue={contact => formatContactName(contact)}
		remoteUrl="/backoffice/1.0/contacts"
	/>
	<div class="mt-2"><button type="button" class="btn btn-primary" on:click={saveMessage}>Envoyer</button></div>
	{#each messages as message (message.me_id)}
		<div class="message-container">
			<div>
				{formatContactName(message.user)} le {formatDateTime(message.createdAt)}
				<a href={`javascript:void(0);`} on:click={() => destroyMessage(message)}><Fa icon={faTrash} /></a>
			</div>
			<div on:click={() => editMessage(message)}>{message.me_text}</div>
			{#if message.offer && message.offer.of_id}
				<div class="fs-sm mt-2">Bien : <a href={`/offers/details/${message.offer.of_id}`}>{formatOfferName(message.offer)}</a></div>
			{/if}
			{#if message.contact && message.contact.co_id}
				<div class="fs-sm mt-2">
					Contact : <a href={`/contacts/group/all/details/${message.contact.co_id}`}>{formatContactName(message.contact)}</a>
				</div>
			{/if}
		</div>
	{/each}
</div>

<style>
	.message-container {
		border-bottom: 1px solid #ccc;
		padding: 10px;
	}
</style>

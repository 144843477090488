<script>
	import Modal from "./Modal.svelte";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	export let value = false;
	export let cancelButtonClass = ["btn-secondary"];
	export let okButtonClass = ["btn-primary"];
	export let title = "";
	export let okButtonText = "OK";
	export let cancelButtonText = "Cancel";
	export let isConfirm = false;

	function cancel(evt) {
		value = false;
		dispatch("cancel", evt);
	}
	function ok(evt) {
		value = false;
		dispatch("ok", evt);
	}
</script>

<Modal bind:value size="sm">
	<svelte:fragment slot="header">
		<h5 class="modal-title">
			{title} <span style=""><font-awesome-icon :icon="icon" color="var(--bs-danger)" /></span>
		</h5>
		<button type="button" class="btn-close" on:click={cancel} aria-label="Close" />
	</svelte:fragment>
	<slot />
	<svelte:fragment slot="footer">
		{#if isConfirm}
			<button type="button" class="btn {cancelButtonClass.join(' ')}" on:click={cancel}>{cancelButtonText}</button>
		{/if}
		<button type="button" class="btn {okButtonClass.join(' ')}" on:click={ok}>{okButtonText}</button>
	</svelte:fragment>
</Modal>

<style>
</style>

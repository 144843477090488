<script>
	import FormDate from "./FormDate.svelte";
	import FormTime from "./FormTime.svelte";
	import { createEventDispatcher } from "svelte";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");

	const dispatch = createEventDispatcher();

	export let value = "";
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let icon = "";
	export let selectOnFocus = false;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];
	// export let closeOnDate = true;

	export let formatDate = "DD/MM/YYYY";
	export let formatTime = "HH:mm";
	export let valueFormat = "YYYY-MM-DD HH:mm:ss";

	let valueDate = "";
	let valueTime = "";
	let labelTime = "";

	$: {
		if (!value || value.substring(0, 10) == "0000-00-00") {
			value = "";
			valueDate = "";
			valueTime = "";
		} else {
			valueDate = dayjs(value, valueFormat).format("YYYY-MM-DD HH:mm:ss");
			valueTime = dayjs(value, valueFormat).format("YYYY-MM-DD HH:mm:ss");
		}
	}
	$: {
		if (label) labelTime = "-";
		else labelTime = "";
	}

	function ondate(evt) {
		value = dayjs(evt.detail.date, "YYYY-MM-DD HH:mm:ss").format(valueFormat);
	}
	function ontime(evt) {
		value = dayjs(evt.detail.date, "YYYY-MM-DD HH:mm:ss").format(valueFormat);
	}
</script>

<div class="d-flex">
	<FormDate
		bind:value={valueDate}
		{label}
		{icon}
		{inline}
		{labelReverse}
		{help}
		{feedback}
		{labelFor}
		{labelClass}
		{labelSize}
		{inputClass}
		{id}
		{name}
		{placeholder}
		{selectOnFocus}
		{prependClass}
		{appendClass}
		format={formatDate}
		valueFormat="YYYY-MM-DD HH:mm:ss"
		containerClass={["flex-fill", ...containerClass]}
		on:focus
		on:click
		on:dropdownshown
		on:dropdownhidden
		on:date={ondate}
	/>
	<FormTime
		bind:value={valueTime}
		label={labelTime}
		{icon}
		{inline}
		{labelReverse}
		{help}
		{feedback}
		{labelFor}
		{labelClass}
		{labelSize}
		{inputClass}
		{id}
		{name}
		{placeholder}
		{selectOnFocus}
		{prependClass}
		{appendClass}
		format={formatTime}
		valueFormat="YYYY-MM-DD HH:mm:ss"
		containerClass={["flex-fill", ...containerClass]}
		on:focus
		on:click
		on:dropdownshown
		on:dropdownhidden
		on:time={ontime}
	/>
</div>

<style>
</style>

<script>
	import { onMount, createEventDispatcher } from "svelte";
	import { fade } from "svelte/transition";
	import { offersTemp, mainapp } from "./store.js";
	import { itemsItems } from "./items.js";

	import Fa from "svelte-fa";
	import {
		faPhone,
		faClipboardCheck,
		faComment,
		faHandshake,
		faEnvelope,
		faEye,
		faKey,
		faPaste,
		faBuildingCircleArrowRight
	} from "@fortawesome/free-solid-svg-icons";
	import { formatOfferName, formatContactName } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import ContactWin from "./ContactWin.svelte";

	const dispatch = createEventDispatcher();

	export let value = { show: false };

	let offerToAdd = null;
	let offersAdded = [];
	let action = {};
	let showContactWin = { show: false };
	let items_feedbackstypes = itemsItems.items_feedbackstypes.map(feedbacktype => {
		feedbacktype.items = itemsItems.items_feedbacks.filter(feedback => {
			feedback.value = 0;
			return feedback.type == feedbacktype.key;
		});
		// console.log("feedbacktype.items", feedbacktype.items);
		return feedbacktype;
	});

	// $: {
	// 	console.log("value2", value);
	// }
	$: {
		if (offerToAdd) offersAdded.push(offerToAdd);
		offersAdded = offersAdded;
		offerToAdd = null;
	}
	$: {
		if (value.show && value.ac_id) load(value.ac_id);
	}

	async function load() {
		let params = { ...value };
		if (params.start) params.start = params.start.format("YYYY-MM-DD HH:mm:ss");
		if (params.end) params.end = params.end.format("YYYY-MM-DD HH:mm:ss");
		// console.log("params", params);
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/actions/${value.ac_id}`, params);
		action = res.data;
		if (action.ac_report && Array.isArray(action.ac_report)) {
			items_feedbackstypes.map(feedbacktype => {
				feedbacktype.items.map(feedback => {
					feedback.value = 0;
					let f = action.ac_report.find(report => {
						if (report.feedback == feedback.key) return feedback;
					});
					if (f) feedback.value = f.value;
				});
			});
			items_feedbackstypes = [...items_feedbackstypes];
		}
	}
	async function save(evt) {
		if (false) {
			textErr = "Merci de compléter les champs :";
			showDialogErr = true;
		}
		action.ac_report = [];
		if (action.ac_type == "report") {
			items_feedbackstypes.map(feedbacktype => {
				feedbacktype.items.map(feedback => {
					action.ac_report.push({
						feedbacktype: feedbacktype.key,
						feedback: feedback.key,
						value: feedback.value
					});
				});
			});
			// console.log("action.ac_report", action.ac_report);
		}
		let res;
		if (action.ac_id) res = await M_.utils.fetchJsonPut(`/backoffice/1.0/actions/${action.ac_id}`, action);
		else res = await M_.utils.fetchJsonPost(`/backoffice/1.0/actions`, action);

		dispatch("saved", { evt, action: res.data });
		document.dispatchEvent(new Event("actionsaved"));

		value.show = false;
	}
	function cancel() {
		value.show = false;
	}
	function destroy() {
		if (!action.ac_id) return $mainapp.dialogAlert("Erreur effacement", "Vous ne pouvez pas effacer une fiche en création");
		$mainapp.dialogConfirm(
			"Effacement",
			"Etes-vous certain de vouloir effacer cette fiche ?",
			async () => {
				let res = await M_.utils.fetchJsonDelete(`/backoffice/1.0/actions/${action.ac_id}`);
				dispatch("destroyed", { event: res.data });
				document.dispatchEvent(new Event("actiondestroyed"));
				value.show = false;
			},
			() => {}
		);
	}
	function newcontact() {
		showContactWin = { show: true, co_id: -1 };
	}
	function onContactWinSaved(evt) {
		// console.log("evt.detail", evt.detail);
		action.contact = evt.detail.contact;
	}
	function onContactWinDestroyed() {}
	function onContactWinCanceled() {}
	onMount(() => {
		// if (value.show) {
		// 	if (value.ac_id) load(value.ac_id);
		// 	else load(-1);
		// }
	});
</script>

<M_.Modal bind:value={value.show} modalClass={["actionwin-modal"]}>
	<svelte:fragment slot="header">
		<div class="actionwin-btn-top">
			<div
				class:active={action.ac_type == "call"}
				on:click={() => {
					action.ac_type = "call";
				}}
			>
				<span><Fa icon={faPhone} /></span>
				Appeler
			</div>
			<div
				class:active={action.ac_type == "todo"}
				on:click={() => {
					action.ac_type = "todo";
				}}
			>
				<span><Fa icon={faClipboardCheck} /></span>
				A faire
			</div>
			<div
				class:active={action.ac_type == "comment"}
				on:click={() => {
					action.ac_type = "comment";
				}}
			>
				<span><Fa icon={faComment} /></span>
				Commentaire
			</div>
			<div
				class:active={action.ac_type == "sign"}
				on:click={() => {
					action.ac_type = "sign";
				}}
			>
				<span><Fa icon={faHandshake} /></span>
				Signature
			</div>
			<div
				class:active={action.ac_type == "email"}
				on:click={() => {
					action.ac_type = "email";
				}}
			>
				<span><Fa icon={faEnvelope} /></span>
				Email
			</div>
			<div
				class:active={action.ac_type == "present"}
				on:click={() => {
					action.ac_type = "present";
				}}
			>
				<span><Fa icon={faEye} /></span>
				Présenté
			</div>
			<div
				class:active={action.ac_type == "event"}
				on:click={() => {
					action.ac_type = "event";
				}}
			>
				<span><Fa icon={faKey} /></span>
				A visiter
			</div>
			<div
				class:active={action.ac_type == "report"}
				on:click={() => {
					action.ac_type = "report";
				}}
			>
				<span><Fa icon={faPaste} /></span>
				C. rendu
			</div>
			<div
				class:active={action.ac_type == "follow"}
				on:click={() => {
					action.ac_type = "follow";
				}}
			>
				<span><Fa icon={faBuildingCircleArrowRight} /></span>
				A suivre
			</div>
		</div>
	</svelte:fragment>

	<div class="row mt-2">
		<div class="col border-end">
			<form autocomplete="off">
				{#if action.ac_type == "call"}
					<div class="mb-2 d-flex" in:fade>
						<M_.FormRadio bind:value={action.ac_type_call} name="ac_type_call" htmlvalue="out" label="Appel sortant" />
						<M_.FormRadio bind:value={action.ac_type_call} name="ac_type_call" htmlvalue="in" label="Appel entrant" />
						<M_.FormRadio bind:value={action.ac_type_call} name="ac_type_call" htmlvalue="recall" label="A rappeler" />
					</div>
				{/if}

				{#if action.ac_type == "sign"}
					<div class="row" in:fade>
						<div class="col">
							<M_.FormRadio bind:value={action.ac_signature_sellrent} name="ac_signature_sellrent" htmlvalue="1" label="Vente" />
							<M_.FormRadio bind:value={action.ac_signature_sellrent} name="ac_signature_sellrent" htmlvalue="2" label="Location" />
						</div>
						<div class="col">
							<M_.FormInput bind:value={action.ac_signature_sum} type="number" label="Montant signé" inline={true} labelSize={6} />
							<M_.FormInput bind:value={action.ac_signature_sum2} type="number" label="M. comission" inline={true} labelSize={6} />
							<M_.FormInput bind:value={action.ac_signature_sum3} type="number" label="% comission" inline={true} labelSize={6} />
						</div>
					</div>
				{/if}

				<M_.FormCombobox
					label="Nom du demandeur"
					bind:value={action.user}
					itemKey="co_id"
					itemValue={contact => formatContactName(contact)}
					remoteUrl="/backoffice/1.0/contacts"
					remoteParams={{ types: ["user", "admin"] }}
				/>

				{#if action.ac_type == "event"}
					<div class="d-flex">
						<M_.FormDateTime bind:value={action.ac_date} label="Du" />
						<M_.FormDateTime bind:value={action.ac_date_end} label="Au" />
					</div>
				{:else}
					<M_.FormDateTime bind:value={action.ac_date} />
				{/if}

				{#if action.ac_type == "todo"}
					<div class="mb-1" in:fade>
						<M_.FormCombobox
							label="Nom de l'utilisateur qui doit faire l'action"
							bind:value={action.usertodo}
							itemKey="co_id"
							itemValue={contact => formatContactName(contact)}
							remoteUrl="/backoffice/1.0/contacts"
						/>
					</div>
				{/if}
				{#if action.ac_type == "todo"}
					<div class="mb-2" in:fade>
						<M_.FormDateTime bind:value={action.ac_todo_date} />
					</div>
				{/if}
				<M_.FormCombobox
					label="Nom du contact / client / prospect"
					labelButton="Nouveau contact"
					bind:value={action.contact}
					itemKey="co_id"
					itemValue={contact => formatContactName(contact)}
					remoteUrl="/backoffice/1.0/contacts"
					on:clicklabelbutton={newcontact}
				/>
				<!-- <div class="mb-2">
					<M_.FormCombobox label="Confrère" bind:value={action.negociator} items={$offersTemp} itemKey="of_id" itemValue="of_name" />
				</div> -->
				<M_.FormCombobox
					label="Concerne les biens suivants"
					bind:value={offerToAdd}
					itemKey="of_id"
					itemValue={offer => formatOfferName(offer)}
					remoteUrl="/backoffice/1.0/offers"
				/>
				{#each offersAdded as offer (offer.of_id)}
					<div>
						{formatOfferName(offer)}
					</div>
				{/each}
				{#if action.ac_type == "email"}
					<M_.FormCombobox label="Envoyer à" bind:value={action.negociator} items={$offersTemp} itemKey="of_id" itemValue="of_name" />
					<M_.FormCombobox label="Template mail" bind:value={action.negociator} items={$offersTemp} itemKey="of_id" itemValue="of_name" />
					<M_.FormCheckbox label="Envoyer le(s) bien(s) avec toutes les images" bind:value={action.ac_mail_send} />
				{/if}
				<div class="mb-2">
					<M_.FormInput type="textarea" label="Commentaire" bind:value={action.ac_comment} />
				</div>
			</form>
		</div>
		<div class="col">
			{#if action.ac_type == "report"}
				{#each items_feedbackstypes as feedbacktype}
					<h6>{feedbacktype.value}</h6>
					{#each feedbacktype.items as feedback}
						<div class="d-flex">
							<div
								class="w-33 text-end me-2 pointer"
								on:click={() => {
									feedback.value = -1;
								}}
							>
								{feedback.text2}
							</div>
							<M_.FormRange type="textarea" label="" bind:value={feedback.value} rangeMin={-1} rangeMax={1} containerClass={["w-33"]} />
							<div
								class="w-33 ms-2 pointer"
								on:click={() => {
									feedback.value = 1;
								}}
							>
								{feedback.text1}
							</div>
						</div>
					{/each}
				{/each}
			{/if}
		</div>
	</div>

	<svelte:fragment slot="footer">
		<button type="button" class="btn btn-danger me-auto" on:click={destroy}>Effacer</button>
		<button type="button" class="btn btn-secondary" on:click={cancel}>Annuler</button>
		<button type="button" class="btn btn-primary" on:click={save}>Enregistrer</button>
	</svelte:fragment>
</M_.Modal>

<ContactWin bind:value={showContactWin} on:saved={onContactWinSaved} on:canceled={onContactWinCanceled} on:destroyed={onContactWinDestroyed} />

<style>
	:global(.actionwin-modal .modal-header) {
		background-color: var(--bs-gray-200);
		padding: 0;
	}
	.actionwin-btn-top {
		display: flex;
	}
	.actionwin-btn-top div {
		padding: 13px 10px 0 10px;
		text-align: center;
		min-width: 90px;
		height: 90px;
		cursor: pointer;
	}
	.actionwin-btn-top div.active {
		background-color: rgba(0, 0, 0, 0.2);
	}
	.actionwin-btn-top div span {
		font-size: 30px;
		display: block;
		text-align: center;
	}
</style>

<script>
	import { onMount } from "svelte";
	import {} from "svelte/transition";
	import { navigate } from "svelte-routing";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");
	import weekOfYear from "dayjs/plugin/weekOfYear";
	dayjs.extend(weekOfYear);
	import Fa from "svelte-fa";
	import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
	import { session } from "./store.js";
	import { formatContactName, formatUserRight } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import CalendarView from "./CalendarView.svelte";
	import ActionsList from "./ActionsList.svelte";
	import MessagesView from "./MessagesView.svelte";

	export let routename;
	export let location;
	if (location || routename);

	let mydate = dayjs().startOf("day");
	let mydateWeek = [];
	let calendarview;
	let selectedUsers = [];
	let filtersActions = { orderby: "ac_date desc", types: ["todo", "call"], ac_done: false, user: $session.user };

	$: {
		mydateWeek = [];
		let mydatebis = dayjs(mydate).startOf("week");
		for (let i = 0; i < 7; i++) {
			let dw = mydatebis.add(i, "day").startOf("day");
			if (mydate.isSame(dw)) dw.isactive = "dateactive";
			mydateWeek.push(dw);
		}
		if (calendarview) calendarview.getCalendar().gotoDate(mydate.toDate());
	}

	function calendarWinOpen() {}
	function calendarWinSaved() {}
	function calendarWinCanceled() {}
	function calendarWinDestroyed() {}

	onMount(() => {});
</script>

<main class="container-fluid">
	<div class="row h-100">
		<div class="col-6 overflow-auto h-100">
			<div class="d-flex">
				<div class="rounded-circle bg-gray" style="width:150px;height:150px;">Image</div>
				<div>
					<h3>{formatContactName($session.user)}</h3>
					<h5>{formatUserRight($session.user)}</h5>
					<div>
						<a href={`javascript:void(0)`} on:click={() => navigate("/contacts/group/all/details/" + $session.user.co_id)}
							>Voir ma fiche</a
						>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-end">
				<div class="me-2">Voir les faits</div>
				<M_.FormSwitch bind:value={filtersActions.ac_done} width={50} label="" onText=" " offText=" " />
			</div>
			<ActionsList filters={filtersActions} />
		</div>
		<div class="col-3 h-100">
			<div class="d-flex flex-column h-100">
				<div class="flex-grow-0">
					<div class="d-flex">
						<div class="daydate1">{mydate.format("DD")}</div>
						<div>
							<div class="daydate2">avril</div>
							<div>Semaine {mydate.week()}</div>
						</div>
					</div>
					<div>
						<table class="w-100">
							<tr>
								<td class="pointer" on:click={() => (mydate = mydate.add(-7, "day").startOf("day"))}><Fa icon={faAnglesLeft} /></td>
								{#each mydateWeek as dw}
									<td class="text-center pointer" on:click={() => (mydate = dayjs(dw).startOf("day"))}>
										<div>{dw.format("dd")}</div>
										<div class={dw.isactive}>{dw.format("DD")}</div>
									</td>
								{/each}
								<td class="pointer" on:click={() => (mydate = mydate.add(7, "day").startOf("day"))}><Fa icon={faAnglesRight} /></td>
							</tr>
						</table>
					</div>
				</div>
				<div class="flex-grow-1">
					<CalendarView
						users={selectedUsers}
						initialView="timeGridDay"
						bind:this={calendarview}
						on:opened={calendarWinOpen}
						on:saved={calendarWinSaved}
						on:canceled={calendarWinCanceled}
						on:destroyed={calendarWinDestroyed}
					/>
				</div>
			</div>
		</div>
		<div class="col-3 overflow-auto h-100">
			<MessagesView />
		</div>
	</div>
</main>

<style>
	.daydate1 {
		font-size: 3rem;
	}
	.daydate2 {
		font-size: 1.6rem;
	}
	.dateactive {
		background-color: var(--btn-bgcolor-primary);
	}
</style>

<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	export let items = [];
	export let itemKey = "id";
	export let draggable = false;
	export let tableClass = [];
	export let trClass = [];
	export let styleItemSelected = false;
	// export let tdClass = [];

	let itemSelected = null;
	let tableClassDefault = ["table"];
	const trClassDefault = [];
	if (draggable) trClassDefault.push("handle");
	// let modelValue = ref(props.modelValue);

	function onitemclick(item, indexItem, evt) {
		let indexCol = 0,
			node = evt.target;
		while (node.tagName != "TD") {
			node = node.parentNode;
			if (!node) break;
		}
		while ((node = node.previousSibling) != null) indexCol++;

		if (styleItemSelected) {
			itemSelected = item;
		}
		dispatch("itemclick", { item, indexItem, indexCol, evt });
	}
	function onitemheaderclick(evt) {
		// console.log("evt.target", evt.target.parentNode.childNodes);
		let indexCol = 0;
		let previous = evt.target;
		while (previous) {
			if (previous.nodeType === 1) indexCol++;
			previous = previous.previousSibling;
		}
		// console.log("evt.target.previousSibling", evt.target.previousSibling);
		// console.log("indexCol", indexCol);
		dispatch("headerclick", { indexCol: indexCol - 1, evt });
	}
</script>

<div class="mtablesimple-container hscroll">
	<table class="{tableClassDefault.join(' ')} {tableClass.join(' ')}">
		<thead>
			<tr on:click={onitemheaderclick}>
				<slot name="ths" />
			</tr>
		</thead>

		{#if draggable}
			<draggable v-model="itemsDyn" tag="tbody" handle=".handle" {itemKey}>
				<!-- <template #item="{ element: item }">
				<tr class="{ trClassDefault.join(' ')} {(trClass.join(' ')) }" :key="item[itemKey]">
					<slot name="tds" :item="item"></slot>
				</tr>
			</template> -->
			</draggable>
		{:else}
			<tbody>
				{#each items as item, indexItem (item[itemKey])}
					<tr
						class="{trClassDefault.join(' ')} {trClass.join(' ')}"
						on:click={evt => {
							onitemclick(item, indexItem, evt);
						}}
					>
						<slot name="tds" {item} />
					</tr>
				{/each}
			</tbody>
		{/if}
	</table>
</div>

<style>
	.flip-list-move {
		transition: transform 0.5s;
	}
</style>

<script>
	import { createEventDispatcher } from "svelte";
	import { getId } from "./utils.js";

	const dispatch = createEventDispatcher();

	export let value = 0;
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelClass = [];
	// export let labelReverse = false;
	// export let help = "";
	// export let feedback = "";
	export let containerClass = [];
	export let isswitch = false;
	export let indeterminate = false;
	export let useIndeterminate = false;
	export let htmlvalue = "";

	const containerClassDefault = ["form-check"];
	if (isswitch) containerClassDefault.push("form-switch");
	if (inline) containerClassDefault.push("form-check-inline");
	const inputClassDefault = ["form-check-input"];
	const labelClassDefault = ["form-check-label"];
	// const value = value;
	if (indeterminate) value = false;
	const switchDyn = isswitch ? "switch" : "";

	if (!id) id = getId();

	function onclick(evt) {
		if (useIndeterminate) {
			evt.preventDefault();
			// value = false;
			// indeterminate = false;
			// console.log("value,indeterminate", value, indeterminate);
			setTimeout(() => {
				if (!value && !indeterminate) {
					value = true;
					indeterminate = false;
				} else if (value && !indeterminate) {
					value = false;
					indeterminate = true;
				} else {
					value = false;
					indeterminate = false;
				}
				// console.log("value,indeterminate", value, indeterminate);
				dispatch("state", { value, indeterminate });
			}, 100);
		}
	}
</script>

<div class="{containerClassDefault.join(' ')} {containerClass.join(' ')}">
	<input
		bind:group={value}
		{id}
		{name}
		{indeterminate}
		value={htmlvalue}
		class="{inputClassDefault.join(' ')} {inputClass.join(' ')}"
		type="radio"
		on:click={onclick}
	/>
	<label :class="{labelClassDefault.join(' ')} {labelClass.join(' ')}" for={id}>{label}</label>
</div>

<style>
	.form-check {
		margin-right: 15px;
	}
</style>

import App from "./App.svelte";
// import { utils } from "./components/utils";
import { session, mainapp } from "./store.js";
import { utils, init } from "./components/M_.js";

utils.setFetchHost("http://localhost:5001");
let sessionVal;
session.subscribe(v => {
	sessionVal = v;
});
utils.addFetchInterceptorBefore(opts => {
	if (sessionVal?.accesstoken) opts.headers["x-auth-accesstoken"] = sessionVal.accesstoken;
});
init();

export const app = new App({
	target: document.body,
	hydrate: true,
	props: {
		// name: "world"
	}
});
// console.log("mainapp,set");
mainapp.set(app);

// export default app;

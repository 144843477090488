<script>
	import { onMount, createEventDispatcher, tick } from "svelte";
	// import { contactsTemp, contactTemp, groups, currentContact } from "./store.js";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");
	import { Calendar } from "@fullcalendar/core";
	import dayGridPlugin from "@fullcalendar/daygrid";
	import timeGridPlugin from "@fullcalendar/timegrid";
	import interactionPlugin from "@fullcalendar/interaction";
	import frLocale from "@fullcalendar/core/locales/fr";
	import { formatContactName, formatOfferName, nl2br } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import ActionWin from "./ActionWin.svelte";

	const dispatch = createEventDispatcher();

	// export let value = dayjs().startOf("day");
	export let users = [];
	export let initialView = "timeGridWeek";
	export function getCalendar() {
		return calendar;
	}

	let calendar;
	let events = [];
	let editOnlyVeid = "";
	let showActionWin = { show: false, ac_type: "event" };
	// let calendarRef;
	let hoverEl;
	let calendarParentEl;
	let calendarEl;

	let selectedUsers = [];
	let calendarOptions = {
		initialView,
		height: "100%",

		plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
		// defaultView: "dayGridWeek",
		views: {
			timeGridDay: {
				titleFormat: { day: "numeric", month: "long" }
			}
		},
		// defaultDate: value.toDate(),
		editable: true,
		selectable: true,
		// firstDay: 1,
		// scrollTime: "09:00:00",
		locale: frLocale,
		headerToolbar: false,
		// headerToolbar: {
		// 	left: "prev,next today",
		// 	center: "title",
		// 	right: "dayGridMonth,timeGridWeek,timeGridDay"
		// },
		dateClick: info => {},
		select: info => {
			openCalendarWin(-1, dayjs(info.start), dayjs(info.end), info.allDay);
		},
		eventMouseEnter: info => {
			// let f = this.$_.find(this.events, { ac_id: info.event.id * 1 });
			let f = events.find(el => el.ac_id == info.event.id * 1);
			if (f) {
				let txt = [];
				if (f.co_id_user) txt.push("Utilisateur : <b>" + formatContactName(f.co_id_user) + "</b>");
				if (f.co_id_contact) txt.push("Contact : <b>" + formatContactName(f.co_id_contact) + "</b>");
				if (f.ac_summary) txt.push("Sujet : <b>" + f.ac_summary + "</b>");
				if (f.ac_description) txt.push("Description : <b>" + nl2br(f.ac_description) + "</b>");
				if (f.visits) {
					for (let iVi = 0; iVi < f.visits.length; iVi++) {
						const visit = f.visits[iVi];
						if (visit.of_id) txt.push(formatOfferName(visit.of_id));
					}
				}
				hoverEl.innerHTML = txt.join("<br/>");
				hoverEl.style.display = "block";
				hoverEl.style.left = info.el.getBoundingClientRect().left - calendarParentEl.getBoundingClientRect().left + "px";
				hoverEl.style.top =
					info.el.getBoundingClientRect().top -
					calendarParentEl.getBoundingClientRect().top +
					info.el.getBoundingClientRect().height +
					"px";
			}
		},
		eventMouseLeave: info => {
			hoverEl.style.display = "none";
		},
		eventClick: info => {
			// console.log("info", info);
			let editable = true;
			if (editOnlyVeid) {
				editable = false;
				if (editOnlyVeid == info.event.id) {
					editable = true;
				}
			}
			if (!editable) return;
			openCalendarWin(info.event.id);
		},
		eventResize: info => {
			saveEventUpdated(info.event);
		},
		eventDrop: info => {
			saveEventUpdated(info.event);
		},
		events: async (fetchInfo, successCallback, failureCallback) => {
			let myusers = users.map(row_co => {
				return row_co.co_id;
			});
			let params = {
				withOffers: true,
				from: dayjs(fetchInfo.start).format("YYYY-MM-DD HH:mm:ss"),
				to: dayjs(fetchInfo.end).format("YYYY-MM-DD HH:mm:ss"),
				// envoyer aussi un tableau avec le ou les agents
				users: myusers,
				types: ["event"]
			};
			let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/actions`, params);
			events = res.data;
			// console.log("events", events);
			dispatch("events", events);

			let eventsCal = [];
			for (let iE = 0; iE < events.length; iE++) {
				const row_ac = events[iE];
				let editable = true;
				// let color =
				// 	row_ac.co_id_user.co_preferences && row_ac.co_id_user.co_preferences.color
				// 		? row_ac.co_id_user.co_preferences.color.hex
				// 		: this.defaultColor;
				let color = "red";
				if (editOnlyVeid) {
					editable = false;
					if (editOnlyVeid == row_ac.ac_id) {
						editable = true;
						color = "red";
					}
				}
				let title = [];
				if (row_ac.ac_comment) title.push(row_ac.ac_comment);
				if (row_ac.ac_description?.trim()) title.push(row_ac.ac_description);
				if (row_ac.co_id_contact?.co_id) title.push(formatOfferName(row_ac.co_id_contact));
				if (row_ac.offers && Array.isArray(row_ac.offers) && row_ac.offers.length) {
					let tabTemp = [];
					row_ac.offers.forEach(row_of => {
						title.push(row_of.of_ref);
					});
				}
				eventsCal.push({
					editable,
					id: row_ac.ac_id,
					title: title.join(" | "),
					start: dayjs(row_ac.ac_date).toDate(),
					end: dayjs(row_ac.ac_date_end).toDate(),
					allDay: row_ac.ac_allday,
					color
				});
			}
			// console.log("eventsCal", eventsCal);
			successCallback(eventsCal);
		}
	};

	function openCalendarWin(ac_id, start, end, allday) {
		showActionWin = { show: true, ac_id, start, end, allday, ac_type: "event" };
		dispatch("calendarWinOpened", { ac_id, start, end, allday, ac_type: "event" });
	}

	function calendarWinDestroyed() {
		calendar.refetchEvents();
		dispatch("calendarWinDestroyed");
	}
	function calendarWinCanceled() {
		dispatch("calendarWinCanceled");
	}
	async function calendarWinSaved(event) {
		// console.log("event", event);
		// events.push({ ...event.detail.event });
		dispatch("calendarWinSaved", event);
		calendar.refetchEvents();
	}
	async function saveEventUpdated(evt) {
		// console.log("evt", evt);
		let res = await M_.utils.fetchJsonPut(`/backoffice/1.0/actions/${evt.id}`, {
			ac_date: dayjs(evt.start).format("YYYY-MM-DD HH:mm:ss"),
			ac_date_end: dayjs(evt.end).format("YYYY-MM-DD HH:mm:ss"),
			ac_allday: evt.allDay
		});
		dispatch("calendarWinSaved", { evt });
		calendar.refetchEvents();
		// this.$socket.emit("calendar_event", evt.id);
	}
	function initCalendar() {
		calendar = new Calendar(calendarEl, calendarOptions);
		calendar.render();
		// console.log('calendar.getApi()', calendar.getApi());
		// console.log("initCalendar", calendarEl, calendar, calendar.refetchEvents);
	}

	onMount(() => {
		initCalendar();
	});
</script>

<div class="fill-height overflow-hidden calendarParent" bind:this={calendarParentEl}>
	<!-- <div ref="calendarContainer" class="fill-height calendar-view2"></div> -->
	<!-- <FullCalendar bind:this={calendarRef} options={calendarOptions} /> -->
	<div bind:this={calendarEl} />

	<!-- <CalendarWin bind:value={showActionWin} on:saved={calendarWinSaved} on:canceled={calendarWinCanceled} on:destroyed={calendarWinDestroyed} /> -->
	<ActionWin bind:value={showActionWin} on:saved={calendarWinSaved} on:canceled={calendarWinCanceled} on:destroyed={calendarWinDestroyed} />
	<div bind:this={hoverEl} class="hoverdiv" />
</div>

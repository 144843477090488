import dayjs from "dayjs";
import "dayjs/locale/fr";
dayjs.locale("fr");
import numeral from "numeral";
import { itemsItems } from "./items.js";

export function formatEmail(txt) {
	return `<a href="mailto:${txt}">${txt}</a>`;
}

export function formatTel(txt) {
	return `<a href="tel:${txt}">${txt}</a>`;
}
export function formatWeb(txt) {
	return `<a href="${txt}" target="_blank">${txt}</a>`;
}

export function formatEventName(row_ev) {
	return row_ev.ve_summary;
}
export function formatOfferName(row_of, lang = "fr", brBeforePrice = false, bold = false) {
	if (!row_of) return "";
	let txt = [];
	if (row_of.of_ref) txt.push(row_of.of_ref);
	if (row_of.building && row_of.building.bu_name && row_of.building.bu_name != "Aucun / Nouveau") {
		let bu_name = row_of.building.bu_name;
		if (bold) bu_name = "<b>" + bu_name + "</b>";
		txt.push(bu_name);
	}
	if (row_of.of_type) txt.push(formatFromArray(row_of.of_type, itemsItems.items_of_type, "A définir", lang));
	if (row_of.of_nbpieces) txt.push(formatPieces(row_of, lang));
	if (row_of.of_surface) txt.push(formatOfferSurface(row_of, true));
	if (row_of["of_name_" + lang]) txt.push(row_of["of_name_" + lang]);
	let ps = formatOfferPrices(row_of, " / ", brBeforePrice, lang);
	if (brBeforePrice) {
		return txt.join(" / ") + "<br>" + ps;
	}
	if (ps) txt.push(ps);
	return txt.join(" / ");
}

export function formatOfferPrices(row_of, sep = " / ", withBold = false, lang = "fr") {
	let txt = [],
		b1 = withBold ? "<b>" : "",
		b2 = withBold ? "</b>" : "";

	if (row_of.of_rent) {
		if (row_of.of_rent_uponrequest) txt.push("A louer" + " : " + "Prix sur demande");
		else {
			let moisAn = "";
			if (row_of.of_rent_sum) moisAn += "A louer" + " : " + b1 + formatPrice(row_of.of_rent_sum, " €/" + "mois") + b2;
			else if (row_of.of_rent_sum_year) moisAn += "A louer" + " : " + b1 + formatPrice(row_of.of_rent_sum_year, " €/" + "an") + b2;
			if (row_of.of_rent_charge_included) {
				moisAn += " (" + "Chargesincluses" + ")";
			} else if (row_of.of_rent_charge) {
				moisAn += " (" + "Charges" + " : " + formatPrice(row_of.of_rent_charge, " €/" + "mois") + ")";
			} else if (row_of.of_rent_charge_year) {
				moisAn += " (" + "Charges" + " : " + formatPrice(row_of.of_rent_charge_year, " €/" + "an") + ")";
			}
			txt.push(moisAn);
		}
	}
	if (row_of.of_sell) {
		let viagerTemp = "",
			viagerTemp2 = "";
		if (row_of.of_sell_lifeannuity) {
			viagerTemp = " " + "en viager";
			viagerTemp2 = " + " + formatPrice(row_of.of_sell_lifeannuity) + "/" + "mois";
		}
		if (row_of.of_sell_uponrequest) txt.push("A vendre" + viagerTemp + " : " + "Prix sur demande");
		else if (row_of.of_sell_sum) txt.push("A vendre" + viagerTemp + " : " + b1 + formatPrice(row_of.of_sell_sum) + b2 + viagerTemp2);
	}

	return txt.join(sep);
}
export function formatPieces(row_of) {
	if (!row_of) return "";
	let txt = "";
	if (row_of.of_nbpieces == 1 && row_of.of_type == 1) txt = "Studio";
	else if (row_of.of_nbpieces == 1) txt = "1 " + "pièce";
	else if (row_of.of_nbpieces > 1) txt = row_of.of_nbpieces + " " + "pièces";
	return txt;
}

export function formatContactName(row_co) {
	// console.log("row_co", row_co);
	if (!row_co) return "";
	// console.log("row_co", row_co);
	let res = "";
	if (row_co.co_name) res += row_co.co_name + " ";
	if (row_co.co_firstname) res += row_co.co_firstname + " ";
	return res.trim();
}

export function nl2br(str, is_xhtml) {
	if (typeof str === "undefined" || str === null) {
		return "";
	}
	var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
	return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
}

export function formatSurface(val, whithoutSup) {
	let more = " m<sup>2</sup>";
	if (whithoutSup) more = "m2";
	return numeral(val * 1).format("0,0") + more;
}
export function formatYesNo(val) {
	return val ? "Oui" : "Non";
}
export function formatFromArray(val, myarray, mydefault, propertyKey = "key", propertyValue = "value", lang = "fr") {
	// let f = _.result(_.find(myarray, { value: val }), "text", mydefault);
	// return f, lang);
	// console.log("myarray", myarray, val);
	let f = myarray.find(el => el[propertyKey] == val);
	// console.log("f", f);
	if (f) return f[propertyValue];
	return "";
}
export function formatOfferSurface(row_of, whithoutSup) {
	let txt = formatSurface(row_of.of_surface, whithoutSup);
	if (row_of.of_surface_approximate) txt += " (approximatif)";
	return txt;
}
export function formatContactFunctions(contact) {
	let what = [];
	// let f = formatFromArray(contact.co_function, contactFunctions, "", "key", "value", "fr");
	if (contact.co_issociety) what.push("Société");
	if (contact.co_isowner) what.push("Propriétaire");
	if (contact.co_isrenter) what.push("Locataire");
	if (contact.co_isagent) what.push("Négociateur immo");
	if (contact.co_iscaretaker) what.push("Gardien");
	if (contact.co_islawyer) what.push("Avocat");
	if (contact.co_isagency) what.push("Agence immobilière");
	if (contact.co_issyndic) what.push("Syndic");
	if (contact.co_isintermediaire) what.push("Apporteur d'affaire");
	if (contact.co_function) what.push(contact.co_function);
	return what.join(" / ");
}
export function formatDate(val, format = "DD/MM/YYYY") {
	let v = "";
	if (typeof val === "string") {
		let m = dayjs(val);
		if (m.isValid()) v = m.format(format);
	} else if (dayjs.isDayjs(val)) v = val.format(format);
	else if (val instanceof Date) v = dayjs(val).format(format);
	return v;
}
export function formatDateTime(val) {
	let v = "";
	if (typeof val === "string") {
		let m = dayjs(val);
		if (m.isValid()) v = m.format("DD/MM/YYYY [à] HH[H]mm");
	}
	if (dayjs.isDayjs(val)) v = val.format("DD/MM/YYYY [à] HH[H]mm");
	if (val instanceof Date) v = dayjs(val).format("DD/MM/YYYY [à] HH[H]mm");
	return v;
}
export function formatDateAgo(val) {
	let v = "";
	if (typeof val === "string") {
		let m = dayjs(val);
		if (m.isValid()) v = m.fromNow();
	}
	if (dayjs.isDayjs(val)) v = val.fromNow();
	if (val instanceof Date) v = dayjs(val).fromNow();
	return v;
}
export function formatPrice(val, suffixe) {
	if (!suffixe) suffixe = " €";
	val = val * 1;
	// if (val >= 1000000) return numeral(val / 1000000).format("0,0.0") + "M" + suffixe;
	// if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "K" + suffixe;
	return numeral(val).format("0,0") + suffixe;
}

export function formatPurcent(val) {
	return numeral((val * 1) / 100).format("0,0 %") + "";
}

export function formatContactAdress(row_co, sep = "<br/>") {
	let txt = [];
	if (row_co.co_address1) txt.push(row_co.co_address1);
	if (row_co.co_address2) txt.push(row_co.co_address2);
	if (row_co.co_address3) txt.push(row_co.co_address3);
	if (row_co.co_zip && row_co.co_city) txt.push(row_co.co_zip + " " + row_co.co_city);
	if (row_co.co_country) txt.push(row_co.co_country);
	return `<a href="https://www.google.fr/maps?q=${encodeURI(txt.join(", "))}" target="_blank">${txt.join(sep)}</a>`;
}

export function formatUserRight(user) {
	let f = itemsItems.items_co_type.find(el => el.key == user.co_type);
	if (f) return f.value;
	return user.co_type;
}

<script>
	import { onMount } from "svelte";
	import {} from "svelte/transition";
	import {} from "./store.js";
	import { formatContactName, formatOfferName, formatDateTime } from "./Services.js";
	import * as M_ from "./components/M_.js";
	import MessagesView from "./MessagesView.svelte";

	export let routename;
	export let location;
	if (location || routename);

	let stats = {
		nboffers: 0,
		nbofferstosell: 0,
		nbofferstorent: 0,
		nboffersarchives: 0,
		nboffersagency: 0,
		nboffersagencytosell: 0,
		nboffersagencytorent: 0,
		nboffersimmotools: 0,
		nboffersimmotoolstosell: 0,
		nboffersimmotoolstorent: 0,
		nbcontacts: 0,
		nbusers: 0
	};

	async function loadStats() {
		let res = await M_.utils.fetchJsonGet(`/backoffice/1.0/stats`);
		stats = res.data;
	}
	onMount(() => {
		loadStats();
	});
</script>

<main class="container-fluid">
	<div class="row overflow-hidden h-100">
		<div class="col overflow-auto h-100">
			<h1>Les statistiques</h1>
			<div>
				Nombre de biens total : {stats.nboffers} ({stats.nbofferstosell} à vendre / {stats.nbofferstorent} à louer)
			</div>
			<div>
				Nombre de biens de mon agence : {stats.nboffersagency} ({stats.nboffersagencytosell} à vendre / {stats.nboffersagencytorent} à louer)
			</div>
			<div>
				Nombre de biens immotools : {stats.nboffersimmotools} ({stats.nboffersimmotoolstosell} à vendre / {stats.nboffersimmotoolstorent} à louer)
			</div>
			<div>Nombre de contacts total : {stats.nbcontacts}</div>
			<div>Nombre d'utilisateurs : {stats.nbusers}</div>
			<div>Nombre de : {stats.nbusers}</div>
		</div>
	</div>
</main>

<style>
</style>

<script>
	import { onMount, onDestroy, createEventDispatcher } from "svelte";
	// import { itemsTemp } from "./store.js";
	import { itemsItems } from "./items.js";
	import Fa from "svelte-fa";
	import { faEdit } from "@fortawesome/free-solid-svg-icons";
	import * as M_ from "./components/M_.js";
	import { formatContactName, formatOfferName, formatEventName, formatFromArray, formatDateTime } from "./Services.js";

	const dispatch = createEventDispatcher();

	export let action = {};
	export let showSwitch = true;

	function onclickedit(action) {
		dispatch("editaction", { action });
	}
	function formatActionDone(action) {
		let txt = "",
			cls = "bg-danger";
		if (action.ac_done) cls = "bg-success";
		if (action.ac_type == "call") txt = action.ac_done ? "Appelé" : "À appeler";
		else if (action.ac_type == "present") txt = action.ac_done ? "Présenté" : "À présenter";
		else txt = action.ac_done ? "Fait" : "À faire";
		// console.log("action.ac_done,txt", action.ac_done, txt);
		return `<span class="badge ${cls}">${txt}</span>`;
	}

	function formatAcType(action) {
		let res = formatFromArray(action.ac_type, itemsItems.items_ac_type, "");
		if (action.ac_type == "call") {
			if (action.ac_type_call == "out") res = "Appel sortant";
			if (action.ac_type_call == "in") res = "Appel entrant";
			if (action.ac_type_call == "recall") res = "À rappeler";
		}
		if (action.ac_type == "follow") {
			if (action.co_id_user) res = `Contact à suivre`;
			if (action.of_id) res = `Offre à suivre`;
		}
		return res;
	}
	function formatReport(action) {
		let txt = [];
		itemsItems.items_feedbackstypes.map(feedbacktype => {
			itemsItems.items_feedbacks
				.filter(feedback => {
					feedback.value = 0;
					return feedback.type == feedbacktype.key;
				})
				.map(feedback => {
					let f = action.ac_report.find(report => {
						if (report.feedback == feedback.key) return feedback;
					});
					if (f) {
						// feedback.value = f.value;
						if (f.value == -1) txt.push(`${feedback.text2}`);
						else if (f.value == 1) txt.push(`${feedback.text1}`);
					}
				});
		});
		return txt.join(", ");
	}

	async function saveSwitchDone(action, evt) {
		await M_.utils.fetchJsonPut(`/backoffice/1.0/actions/${action.ac_id}`, { ac_done: action.ac_done });
	}

	onMount(() => {});
	onDestroy(() => {});
</script>

<div class="action-container line-height-1">
	<div class="d-flex">
		<div class="text-sm mb-2">
			<b>{formatAcType(action)}</b>
			le {formatDateTime(action.ac_date)}
			{#if action.user && action.user.co_id}
				par {formatContactName(action.user)}
			{/if}
		</div>
		<div class="ms-3">
			<a href={`javascript:void(0)`} on:click={() => onclickedit(action)}><Fa icon={faEdit} /></a>
		</div>
		{#if showSwitch}
			<div class="ms-auto">
				{@html formatActionDone(action)}
			</div>
			<div class="ms-2">
				<M_.FormSwitch on:input={evt => saveSwitchDone(action, evt)} bind:value={action.ac_done} width={50} label="" onText=" " offText=" " />
			</div>
		{/if}
	</div>

	{#if action.usertodo?.co_id}
		<div>A faire par : {formatContactName(action.usertodo)}</div>
	{/if}
	{#if action.contact?.co_id}
		<div>Contact : {formatContactName(action.contact)}</div>
	{/if}
	{#if action.event?.co_id}
		<div>Evénement : {formatEventName(action.contact)}</div>
	{/if}
	{#if action.offer?.of_id}
		<div>Concerne le bien : {formatOfferName(action.offer)}</div>
	{/if}
	{#if action.ac_comment}
		<div>{action.ac_comment}</div>
	{/if}
	{#if action.ac_type == "report" && Array.isArray(action.ac_report)}
		{formatReport(action)}
	{/if}
</div>

<style>
	.action-container {
		background-color: white;
		border-bottom: 1px solid var(--color-border);
		padding: 6px 0;
	}
</style>

import { writable } from "svelte/store";
import { itemsItems, tradsItems, groupsItems } from "./items.js";

export const itemsTemp = writable(itemsItems);
export const tradsTemp = writable(tradsItems);
export const groups = writable(groupsItems);

export const currentPath = writable("");

export const mainapp = writable(null);

function createContactStore() {
	let currentContact;
	try {
		currentContact = JSON.parse(localStorage.getItem("currentContact"));
	} catch (error) {
		currentContact = null;
	}
	if (!currentContact) currentContact = { co_id: 0 };
	// console.log("currentContact", currentContact);
	const { subscribe, set, update } = writable(currentContact);
	return {
		subscribe,
		set: function (val) {
			// console.log("set", val);
			localStorage.setItem("currentContact", JSON.stringify(val));
		},
		update: function (val) {
			// console.log("update", val);
			localStorage.setItem("currentContact", JSON.stringify(val));
		}
	};
}
export const currentContact = createContactStore();

function createSessionStore() {
	let sessDefault = {
		user: {},
		prefsPerso: { showSaturday: true, showSunday: true, color: "blue", help: true, receivemail: true, startofday: 9 },
		prefsShared: {},
		accesstoken: "",
		refreshtoken: ""
	};
	let sess = {};
	try {
		sess = JSON.parse(localStorage.getItem("session"));
		sess.prefsPerso = { ...sessDefault.prefsPerso, ...sess.prefsPerso };
	} catch (error) {}
	sess = { ...sessDefault, ...sess };
	const { subscribe, set, update } = writable(sess);
	return {
		subscribe,
		// setUser: function (user) {},
		set: function (val) {
			console.log("sess", val);
			set(val);
			localStorage.setItem("session", JSON.stringify(val));
		},
		update: function (fn) {
			update(fn);
			console.log("sess", sess);
			localStorage.setItem("session", JSON.stringify(sess));
			// console.log("session2", session);
		}
	};
}
export const session = createSessionStore();

export const contactTemp = writable({ co_id: 200, co_name: "Contact N°200", co_firstname: "Prénom" });

function createFiltersOffersStore() {
	const filtersOffersDefault = {
		room_1: false,
		room_2: false,
		room_3: false,
		room_4: false,
		room_5: false,
		monaco: false,
		france: false,
		budgetmin: 0,
		budgetmax: 0,
		surfacemin: 0,
		surfacemax: 0,
		agency: "",
		negociator: "",
		building: "",
		area: "",
		text: ""
	};
	let filtersOffersSaved;
	try {
		filtersOffersSaved = JSON.parse(localStorage.getItem("filtersOffers"));
	} catch (error) {
		filtersOffersSaved = {};
	}
	const { subscribe, set, update } = writable({ ...filtersOffersDefault, ...filtersOffersSaved });
	return {
		subscribe,
		set: function (val) {
			set(val);
			localStorage.setItem("filtersOffers", JSON.stringify(val));
		},
		update: function (val) {
			console.log("update", val);
			// localStorage.setItem("filtersOffers", JSON.stringify(val));
		},
		reset: function () {
			let val = { ...filtersOffersDefault };
			set(val);
			localStorage.setItem("filtersOffers", JSON.stringify(val));
		}
	};
}
export const filtersOffers = createFiltersOffersStore();

let offersTempBis = [];
for (let i = 1; i < 10; i++) offersTempBis.push({ of_id: i, of_name: "Offre N°" + i });
export const offersTemp = writable(offersTempBis);

let contactsTempBis = [];
for (let i = 1; i < 100; i++) contactsTempBis.push({ co_id: i, co_name: "Contact N°" + i, co_firstname: "Prénom" });
export const contactsTemp = writable(contactsTempBis);

<script>
	import { fade, slide } from "svelte/transition";
	import { session } from "./store.js";
	import * as M_ from "./components/M_.js";

	export let show;
</script>

{#if show}
	<div class="row" transition:slide>
		<div class="col-4">
			<M_.FormCheckbox bind:value={$session.prefsPerso.showSaturday} label="Calendrier, afficher le samedi" />
			<M_.FormCheckbox bind:value={$session.prefsPerso.showSunday} label="Calendrier, afficher le dimanche" />
			<M_.FormInput
				inline={true}
				bind:value={$session.prefsPerso.color}
				type="color"
				label="Calendrier, couleur"
				labelSize={6}
				labelReverse={true}
			/>
			<M_.FormInput
				inline={true}
				bind:value={$session.prefsPerso.startofday}
				label="Calendrier, Début de la journée"
				labelSize={6}
				labelReverse={true}
			/>
		</div>
		<div class="col-4">
			<M_.FormCheckbox bind:value={$session.prefsPerso.help} label="Afficher les bulles d'aide" />
			<M_.FormCheckbox bind:value={$session.prefsPerso.receivemail} label="Recevoir les mails quotidien" />
		</div>
	</div>
{/if}

<script>
	import { onMount } from "svelte";
	import { navigate } from "svelte-routing";
	import { itemsTemp } from "./store.js";
	import * as formaters from "./Services.js";
	import * as M_ from "./components/M_.js";

	export let offer;
	export let items;

	let showprivatebt = "Voir";
	let showprivateok = false;

	$: items_goods = items.filter(item => {
		// console.log("item.filter", item.filter);
		return hasGoodValue(item, offer);
	});

	function onshowprivate() {
		showprivateok = true;
	}
	function hasGoodValue(item) {
		if (!offer) return false;
		let ok = offer[item.key] && offer[item.key] != "0000-00-00";
		if (item.force) ok = true;
		return ok;
	}

	onMount(() => {});
</script>

<table class="table table-striped table-xs">
	<tbody>
		{#each items_goods as item (item.key)}
			<tr>
				{#if hasGoodValue(item)}
					<td>{item.label ? item.label : item.label_fr}</td>
				{/if}
				{#if item.key == "co_id_owner" && hasGoodValue(item)}
					<td>
						<a on:click={onshowprivate} href={"javascript:void(0)"}>{{ showprivatebt }}</a>
						{#if showprivateok}
							<div>
								<a
									on:click={() => {
										navigate(`/contacts/details/${offer.co_id_owner.co_id}`);
									}}
									href={"javascript:void(0)"}
								>
									{@html formaters[item.filter](
										offer[item.key],
										item.filtermore && $itemsTemp[item.filtermore] ? $itemsTemp[item.filtermore] : item.filtermore
									)}
								</a>
							</div>
						{/if}
					</td>
				{:else if item.key == "of_mandat" && hasGoodValue(item)}
					<td>
						<a on:click={onshowprivate} href={"javascript:void(0)"}>{showprivatebt}</a>
						{#if showprivateok}
							<div>{offer[item.key]}</div>
						{/if}
					</td>
				{:else if item.key == "of_notes_customer" && hasGoodValue(item)}
					<td>
						<a on:click={onshowprivate} href={"javascript:void(0)"}>{showprivatebt}</a>
						{#if showprivateok}
							<div>{formaters.nl2br(offer[item.key])}</div>
						{/if}
					</td>
				{:else if item.key == "of_notes_private" && hasGoodValue(item)}
					<td>
						<a on:click={onshowprivate} href={"javascript:void(0)"}>{showprivatebt}</a>
						{#if showprivateok}
							<div>{formaters.nl2br(offer[item.key])}</div>
						{/if}
					</td>
				{:else if item.key == "of_notes_private2" && hasGoodValue(item)}
					<td>
						<a on:click={onshowprivate} href={"javascript:void(0)"}>{showprivatebt}</a>
						{#if showprivateok}
							<div>{formaters.nl2br(offer[item.key])}</div>
						{/if}
					</td>
				{:else if item.filter && hasGoodValue(item)}
					<td>
						{@html formaters[item.filter](
							offer[item.key],
							item.filtermore && $itemsTemp[item.filtermore] ? $itemsTemp[item.filtermore] : item.filtermore
						)}
					</td>
				{:else if hasGoodValue(item)}
					<td>{offer[item.key]}</td>
				{/if}
			</tr>
		{/each}
	</tbody>
</table>

<script>
	import { createEventDispatcher, onMount } from "svelte";
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	dayjs.locale("fr");
	import Fa from "svelte-fa";
	import { faArrowLeft, faArrowRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";

	const dispatch = createEventDispatcher();

	export let value;
	export let year = false;
	export let valueFormat = "YYYY-MM-DD";
	// export let format = "DD/MM/YYYY";

	let valueDayjs = dayjs(value);
	let currentTime = dayjs(value);
	let view = year ? "years" : "days",
		// valueInterne = ref(dayjs().startOf("day")),
		tabDays = [],
		tabDaysWeek = [],
		tabMonths = [],
		tabYears = [],
		currentMonth = dayjs(valueDayjs).startOf("month");

	$: {
		if (!value || value.substring(0, 10) == "0000-00-00") {
			valueDayjs = dayjs();
		} else {
			valueDayjs = dayjs(value);
		}
		currentMonth = dayjs(valueDayjs).startOf("month");
		render();
	}

	function createTabDays() {
		let tabDaysTemp = [];
		currentMonth = dayjs(currentMonth).startOf("month");
		let w = 0;
		let startDay = currentMonth.day() - 1;
		if (startDay == -1) startDay = 6;
		for (let i = startDay * -1; i < 50; i++) {
			let d = dayjs(currentMonth).add(i, "day");
			if (!tabDaysTemp[w]) tabDaysTemp[w] = [];
			tabDaysTemp[w].push(d);
			if (d.day() == 0) w++;
			if (currentMonth.month() != d.month() && d.day() == 0) break;
		}
		// console.log("tabDays", tabDays);
		tabDaysWeek = ["L", "M", "M", "J", "V", "S", "D"];
		tabDays = tabDaysTemp;
	}
	function createTabMonths() {
		let tabMonthsTemp = [];
		let m = -1;
		let start = dayjs(currentMonth).startOf("year");
		for (let i = 0; i < 12; i++) {
			if (i % 3 == 0) m++;
			if (!tabMonthsTemp[m]) tabMonthsTemp[m] = [];
			tabMonthsTemp[m].push(dayjs(start).add(i, "month"));
		}
		tabMonths = tabMonthsTemp;
	}
	function createTabYears() {
		let tabYearsTemp = [];
		let y = -1;
		let start = dayjs(currentMonth).startOf("year").add(-6, "year");
		for (let i = 0; i < 12; i++) {
			if (i % 3 == 0) y++;
			if (!tabYearsTemp[y]) tabYearsTemp[y] = [];
			tabYearsTemp[y].push(dayjs(start).add(i, "year"));
		}
		tabYears = tabYearsTemp;
	}

	function incrementMonth(v, evt) {
		if (evt) evt.stopPropagation();
		// this.currentMonth = moment(this.currentMonth).add(v, "month");
		currentMonth = currentMonth.startOf("month").add(v, "month");
		render();
	}
	function incrementYear(v, evt) {
		if (evt) evt.stopPropagation();
		// this.currentMonth = moment(this.currentMonth).add(v, "month");
		currentMonth = currentMonth.startOf("month").add(v, "year");
		render();
	}
	function changeView(v, evt) {
		if (evt) evt.stopPropagation();
		view = v;
	}
	function addHours(d) {
		return d.add(currentTime.hour(), "hour").add(currentTime.minute(), "minute").add(currentTime.second(), "second");
	}
	function setDate(d) {
		// console.log("d", d);
		// console.log("d", d.format("YYYY-MM-DD"));
		valueDayjs = addHours(dayjs(d).startOf("day"));
		// emit("update:modelValue", valueDayjs.format(valueFormat));
		dispatch("date", { date: valueDayjs.format(valueFormat) });
	}
	function setMonth(m, evt) {
		if (evt) evt.stopPropagation();
		view = "days";
		currentMonth = m;
		render();
	}
	function setYear(m, evt) {
		m = dayjs(m);
		if (year) {
			valueDayjs = addHours(m.startOf("year"));
			// emit("update:modelValue", valueDayjs.format(valueFormat));
		} else {
			if (evt) evt.stopPropagation();
			view = "months";
			currentMonth = m;
			render();
		}
	}

	function render() {
		createTabDays();
		createTabMonths();
		createTabYears();
	}

	onMount(() => {
		render();
	});
</script>

<div class="mcalendar">
	{#if view == "days"}
		<div>
			<div class="d-flex">
				<div
					class="pointer"
					on:click={evt => {
						incrementMonth(-1, evt);
					}}
				>
					<Fa icon={faArrowLeft} />
				</div>
				<div
					class="me-auto ms-auto pointer"
					on:click={evt => {
						changeView("months", evt);
					}}
				>
					{currentMonth.format("MMMM YYYY")}&nbsp;
					<Fa icon={faArrowDown} />
				</div>
				<div
					class="pointer"
					on:click={evt => {
						incrementMonth(1, evt);
					}}
				>
					<Fa icon={faArrowRight} />
				</div>
			</div>
			<div>
				<table class="mcalendar-days table">
					<tr>
						{#each tabDaysWeek as dw, indexWe (indexWe)}
							<td>
								{dw}
							</td>
						{/each}
					</tr>
					{#each tabDays as ds, indexDs (indexDs)}
						<tr>
							{#each ds as d, indexD (indexD)}
								<td
									on:click={evt => {
										setDate(d, evt);
									}}
									class:othermonth={currentMonth.month() != d.month()}
									class:currentday={dayjs().isSame(dayjs(d), "day")}
									class:over={valueDayjs.isSame(d, "day")}
								>
									{d.format("DD")}
								</td>
							{/each}
						</tr>
					{/each}
				</table>
			</div>
		</div>
	{/if}
	{#if view == "months"}
		<div>
			<div class="d-flex">
				<div on:click={evt => incrementYear(-1, evt)} class="pointer">
					<Fa icon={faArrowLeft} />
				</div>
				<div
					class="me-auto ms-auto pointer"
					on:click={evt => {
						changeView("years", evt);
					}}
				>
					{currentMonth.format("YYYY")}&nbsp;
					<Fa icon={faArrowDown} />
				</div>
				<div
					class="pointer"
					on:click={evt => {
						incrementYear(1, evt);
					}}
				>
					<Fa icon={faArrowRight} />
				</div>
			</div>
			<div>
				<table class="mcalendar-months table">
					{#each tabMonths as mos, indexMo (indexMo)}
						<tr>
							{#each mos as m, indexM (indexM)}
								<td
									on:click={evt => {
										setMonth(m, evt);
									}}
									class:over={valueDayjs.startOf("month").isSame(m)}
								>
									{m.format("MMMM")}
								</td>
							{/each}
						</tr>
					{/each}
				</table>
			</div>
		</div>
	{/if}
	{#if view == "years"}
		<div>
			<div class="d-flex">
				<div
					class="pointer"
					on:click={evt => {
						incrementYear(-12, evt);
					}}
				>
					<Fa icon={faArrowLeft} />
				</div>
				<div class="me-auto ms-auto pointer">&nbsp;</div>
				<div
					class="pointer"
					on:click={evt => {
						incrementYear(12, evt);
					}}
				>
					<Fa icon={faArrowRight} />
				</div>
			</div>
			<div>
				<table class="mcalendar-years table">
					{#each tabYears as yes, indexYe (indexYe)}
						<tr>
							{#each yes as y, indexY (indexY)}
								<td
									on:click={evt => {
										setYear(y, evt);
									}}
									class:over={valueDayjs.startOf("year").isSame(y)}
								>
									{y.format("YYYY")}
								</td>
							{/each}
						</tr>
					{/each}
				</table>
			</div>
		</div>
	{/if}
</div>

<style>
	.mcalendar {
		padding: 15px;
	}
	.mcalendar .mcalendar-days,
	.mcalendar .mcalendar-months,
	.mcalendar .mcalendar-years {
		table-layout: fixed;
		width: 300px;
	}
	.mcalendar .mcalendar-days td,
	.mcalendar .mcalendar-months td,
	.mcalendar .mcalendar-years td {
		cursor: pointer;
		text-align: center;
	}

	.mcalendar .mcalendar-days td.othermonth {
		color: #cccccc;
	}
	.mcalendar .mcalendar-days td.currentday {
		background-color: blue;
		color: white;
	}
	.mcalendar .mcalendar-days td.over {
		background-color: green;
		color: white;
	}
</style>

<script>
	import FormBase from "./FormBase.svelte";
	import { createEventDispatcher } from "svelte";
	import Fa from "svelte-fa";
	import { getId } from "./utils.js";
	// import { faArrowLeft, faArrowRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";

	const dispatch = createEventDispatcher();

	export let value = "";
	export let inputClass = [];
	export let type = "text";
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let labelButton = "";
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let maxlength = -1;
	export let nbrows = 3;
	export let icon = null;
	export let selectOnFocus = true;
	export let numberEmptyIfZero = false;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];
	export let displayed = true;

	export function getInputEl() {
		return myinput;
	}

	name += "_" + getId();

	// $: {
	// 	if (value == "") console.log("vide");
	// }

	let myinput;
	const mainClassDefault = ["mforminput-wrapper"];
	if ($$slots.prepend || $$slots.append) mainClassDefault.push("input-group");
	if (inline) mainClassDefault.push("col");
	const prependClassDefault = ["input-group-text"];
	const appendClassDefault = ["input-group-text"];
	const inputClassDefault = ["form-control"];
	// console.log("$$slots", $$slots);

	function treat() {
		if (type == "number") {
			value = value * 1;
			if (isNaN(value)) value = 0;
			if (numberEmptyIfZero && value === 0) value = "";
		}
	}
	treat();
	function onkeypress(evt) {
		evt = evt ? evt : window.event;
		let ok = true;
		if (type == "number") {
			ok = false;
			var charCode = evt.which ? evt.which : evt.keyCode;
			// console.log("charCode", charCode, String.fromCharCode(32));
			if (charCode >= 48 && charCode <= 57) ok = true;
			if (charCode == 44 && numberFloatAllowed) ok = true;
		}
		if (!ok) return evt.preventDefault();
		dispatch("keypress", evt);
		return true;
	}
	function onfocus(evt) {
		if (selectOnFocus) evt.target.select();
		dispatch("focus", evt);
	}

	function oninput(evt) {
		dispatch("input", evt);
	}
	function onclick(evt) {
		evt.stopPropagation();
		dispatch("click", evt);
	}
	function onclickicon(evt) {
		evt.stopPropagation();
		dispatch("clickicon", evt);
	}
	function onclickcontainer(evt) {
		console.log("onclickcontainer");
	}
</script>

<FormBase
	{displayed}
	{label}
	{inline}
	{labelReverse}
	{labelButton}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	on:clickcontainer
	on:clicklabel
	on:clicklabelbutton
>
	<div class={mainClassDefault.join(" ")}>
		{#if $$slots.prepend}
			<div class="{prependClassDefault.join(' ')} {prependClass.join(' ')}">
				<slot name="prepend" />
			</div>
		{/if}
		{#if type == "textarea"}
			<textarea
				ref="myinput2"
				bind:value
				class="{inputClassDefault.join(' ')} {inputClass.join(' ')}"
				{id}
				{name}
				{maxlength}
				{placeholder}
				rows={nbrows}
				on:keypress={onkeypress}
				on:input={oninput}
				on:focus={onfocus}
				on:click={onclick}
			/>
		{:else if type == "text" || type == "number"}
			<input
				bind:this={myinput}
				bind:value
				class="{inputClassDefault.join(' ')} {inputClass.join(' ')}"
				type="text"
				{id}
				{name}
				{maxlength}
				{placeholder}
				autocomplete="new-password"
				on:keypress={onkeypress}
				on:input={oninput}
				on:focus={onfocus}
				on:click={onclick}
			/>
		{:else if type == "color"}
			<input
				bind:this={myinput}
				bind:value
				class="{inputClassDefault.join(' ')} {inputClass.join(' ')}"
				type="color"
				{id}
				{name}
				{maxlength}
				{placeholder}
				autocomplete="new-password"
				on:keypress={onkeypress}
				on:input={oninput}
				on:focus={onfocus}
				on:click={onclick}
			/>
		{:else if type == "password"}
			<input
				bind:this={myinput}
				bind:value
				class="{inputClassDefault.join(' ')} {inputClass.join(' ')}"
				type="password"
				{id}
				{name}
				{maxlength}
				{placeholder}
				autocomplete="new-password"
				on:keypress={onkeypress}
				on:input={oninput}
				on:focus={onfocus}
				on:click={onclick}
			/>
		{/if}
		{#if $$slots.append}
			<div class="{appendClassDefault.join(' ')} {appendClass.join(' ')}">
				<slot name="append" />
			</div>
		{/if}
		{#if icon}
			<div role="button" class="mforminput-icon" on:click={onclickicon}>
				<Fa {icon} />
			</div>
		{/if}

		<slot />
	</div>
</FormBase>

<style>
	.mforminput-wrapper {
		flex: 1 1 0;
		position: relative;
	}
	.mforminput-icon {
		position: absolute;
		top: 50%;
		/* right: v-bind("styleIconRight"); */
		right: 10px;
		transform: translateY(-50%);
		vertical-align: middle;
		background-color: white;
	}
</style>

<script>
	import FormBase from "./FormBase.svelte";
	import { createEventDispatcher, onMount } from "svelte";

	const dispatch = createEventDispatcher();

	export let value = 0;
	export let label = "";
	export let labelReverse = false;
	export let labelFor = "";
	export let labelSize = 3;
	export let rangeMin = 0;
	export let rangeMax = 10;
	// export let width = 200;
	// export let height = 25;
	export let validationClass = [];
	export let inputClass = [];
	// export let dotColor = "white";
	// export let onText = "On";
	// export let offText = "Off";
	// export let onColor = "var(--bs-success)";
	// export let offColor = "var(--bs-secondary)";
	export let displayed = true;
	export let inline = false;
	export let help = "";
	export let feedback = "";
	export let labelClass = [];
	export let containerClass = [];

	const inputClassDefault = ["mformswitch-container"];
	// if ($$slots.prepend || $$slots.append) mainClassDefault.push("input-group");
	// if (inline) inputClassDefault.push("col");
	const margin = 2;
	let containerEl, dotEl;

	// $: {
	// 	if (value) setToogle();
	// 	else setToogle();
	// }

	onMount(() => {});
</script>

<FormBase {label} {displayed} {inline} {labelReverse} {help} {feedback} {labelFor} {labelClass} {containerClass} {labelSize}>
	<div class="{inputClassDefault.join(' ')} {validationClass.join(' ')} {inputClass.join(' ')}" bind:this={containerEl}>
		<input type="range" min={rangeMin} max={rangeMax} bind:value class="custom-slider custom-slider-bullet w-100 mt-1" />
	</div>
</FormBase>

<style>
	input[type="range"].custom-slider.custom-slider-bullet {
		/* height: 2em; */
		color: #fff;
		background: transparent;
		background-image: linear-gradient(to bottom, #fff, #999, #fff);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 25%;
	}
	/*==============================*/
	/* le curseur                   */
	/*==============================*/
	input[type="range"].custom-slider.custom-slider-bullet::-webkit-slider-thumb {
		width: 2em;
		padding: 0.25em; /* largeur du bord */
		border: 1px solid #888;
		border-radius: 50%;
		box-shadow: 0 0 0.5em #fff inset;
		background: linear-gradient(#888, #fff) content-box, /* le centre */ linear-gradient(-90deg, #888, #ddd) border-box; /* le bord */
	}
	input[type="range"].custom-slider.custom-slider-bullet::-moz-range-thumb {
		width: 2em;
		padding: 0.25em; /* largeur du bord */
		border: 1px solid #888;
		border-radius: 50%;
		box-shadow: 0 0 0.5em #fff inset;
		background: linear-gradient(#888, #fff) content-box, /* le centre */ linear-gradient(-90deg, #888, #ddd) border-box; /* le bord */
	}
	input[type="range"].custom-slider.custom-slider-bullet::-ms-thumb {
		width: 2em;
		padding: 0.25em; /* largeur du bord */
		border: 1px solid #888;
		border-radius: 50%;
		box-shadow: 0 0 0.5em #fff inset;
		background: linear-gradient(#888, #fff) content-box, /* le centre */ linear-gradient(-90deg, #888, #ddd) border-box; /* le bord */
	}
</style>

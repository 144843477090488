<script>
	import { onMount, onDestroy, createEventDispatcher } from "svelte";
	import {} from "./store.js";
	import * as M_ from "./components/M_.js";
	import {} from "./Services.js";
	import ActionWin from "./ActionWin.svelte";
	import ActionCard from "./ActionCard.svelte";

	const dispatch = createEventDispatcher();

	// export let co_id = null;
	export let filters = null;
	export function reload() {
		this.loadActions();
	}

	let actions = [];
	let showActionWin = { show: false };
	let delayActions = "";

	$: {
		if (filters) delayActions = M_.utils.delay(delayActions, loadActions, 100);
	}
	function oneditaction(action) {
		// console.log("action", action);
		showActionWin = { show: true, ac_id: action.ac_id };
		// console.log("showActionWin", showActionWin);
		dispatch("editaction", { action });
	}

	async function loadActions() {
		let params = { ...filters };
		if (params.user) {
			params.co_id_user = params.user.co_id;
			delete params.user;
		}
		// console.log("params", params);
		let res = await M_.utils.fetchJsonGet("/backoffice/1.0/actions", params);
		if (res.data) actions = res.data;
	}

	onMount(() => {
		// loadActions();
		document.addEventListener("actionsaved", loadActions);
	});
	onDestroy(() => {
		document.removeEventListener("actionsaved", loadActions);
	});
</script>

{#each actions as action}
	<ActionCard {action} on:editaction={e => oneditaction(e.detail.action)} />
{/each}

<ActionWin bind:value={showActionWin} />

<style>
</style>

<script>
	import FormInput from "./FormInput.svelte";
	import { createEventDispatcher, onMount, onDestroy, tick } from "svelte";
	import Fa from "svelte-fa";
	import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

	const dispatch = createEventDispatcher();

	export let value = "";
	export let inputClass = [];
	export let inline = false;
	export let id = "";
	export let name = "";
	export let label = "";
	export let labelFor = "";
	export let labelSize = 3;
	export let labelClass = [];
	export let labelReverse = false;
	export let labelButton = "";
	export let placeholder = "";
	export let help = "";
	export let feedback = "";
	export let icon = faArrowDown;
	export let selectOnFocus = false;
	export let containerClass = [];
	export let prependClass = [];
	export let appendClass = [];
	export let forceHeight = false;
	export let dropdownWidth = "auto";
	export let dropdownIgnoreClick = true;
	export let dropdownTag = "div";

	let dropdown, elFormInput, viewport;
	// let showdropdown = false;
	let dropdownStyles = { top: 0, bottom: "auto", maxHeight: 300 };
	const mainClassDefault = ["mforminput-wrapper"];
	if ($$slots.prepend || $$slots.append) mainClassDefault.push("input-group");
	if (inline) mainClassDefault.push("col");
	const prependClassDefault = ["input-group-text"];
	const appendClassDefault = ["input-group-text"];
	const inputClassDefault = ["form-control"];

	export async function showDropdown() {
		const event = new Event("dropdownopened");
		document.dispatchEvent(event);

		await tick();

		setPositionDropDown();
		// showdropdown = true;
		dropdown.style.display = "block";
		dispatch("dropdownshown");
	}
	export function hideDropdown(evt) {
		// console.log("hideDropdown", evt);
		// console.log("hideDropdown", hideDropdown);
		// showdropdown = false;
		dropdown.style.display = "none";
		dispatch("dropdownhidden");
	}

	function findRelativeViewport(el) {
		let parent = el.parentNode;
		if (!parent) return el;
		let overflow = getComputedStyle(parent).overflow;
		if (overflow == "auto" || overflow == "hidden") return parent;
		if (parent.tagName == "BODY") return parent;
		return findRelativeViewport(parent);
	}

	export async function setPositionDropDown() {
		await tick();
		// console.log("setPositionDropDown");
		if (!elFormInput) return;
		let spacebeforeWIndow = 20;
		let bb_input = elFormInput.getInputEl().getBoundingClientRect();
		// let viewportHeight = window.innerHeight;
		// let viewportWidth = window.innerWidth;

		// let viewport = findRelativeViewport(elFormInput.getInputEl());
		// console.log("viewport", viewport.getBoundingClientRect());
		let bb_viewport = viewport.getBoundingClientRect();
		let espaceEnBas = bb_viewport.bottom - bb_input.bottom;
		let espaceEnHaut = bb_input.top - bb_viewport.top;
		if (!forceHeight) dropdownStyles.height = "auto";
		// console.log("espaceEnBas, bb_viewport.bottom", espaceEnBas, bb_viewport.bottom, bb_input.bottom);

		if (espaceEnBas > espaceEnHaut) {
			dropdownStyles.top = elFormInput.getInputEl().offsetHeight + "px";
			dropdownStyles.bottom = "auto";
			dropdownStyles.maxHeight = espaceEnBas - spacebeforeWIndow + "px";
			if (forceHeight) dropdownStyles.height = dropdownStyles.maxHeight;
		} else {
			dropdownStyles.top = "auto";
			dropdownStyles.bottom = elFormInput.getInputEl().offsetHeight + "px";
			dropdownStyles.maxHeight = espaceEnHaut - spacebeforeWIndow + "px";
			if (forceHeight) dropdownStyles.height = dropdownStyles.maxHeight;
		}
		if (dropdownWidth == "auto") dropdownStyles.minWidth = "auto";
		if (dropdownWidth == "input") dropdownStyles.minWidth = elFormInput.getInputEl().offsetWidth + "px";
		dropdown.style.top = dropdownStyles.top;
		dropdown.style.bottom = dropdownStyles.bottom;
		dropdown.style.maxHeight = dropdownStyles.maxHeight;
		dropdown.style.height = dropdownStyles.height;
		dropdown.style.minWidth = dropdownStyles.minWidth;
	}
	function onclick(evt) {
		evt.stopPropagation();
	}
	function onclickdropdown(evt) {
		if (dropdownIgnoreClick) evt.stopPropagation();
		dispatch("dropdownclick");
	}
	function onfocus(evt) {
		showDropdown();
		dispatch("focus", evt);
	}
	function onclickicon(evt) {
		// evt.stopPropagation();
		// onfocus(evt);
		// showDropdown();
		elFormInput.getInputEl().focus();
	}

	onMount(() => {
		// console.log("elFormInput.getInputEl()", elFormInput.getInputEl());
		viewport = findRelativeViewport(elFormInput.getInputEl());
		viewport.addEventListener("scroll", setPositionDropDown);
		document.addEventListener("click", hideDropdown);
		document.addEventListener("dropdownopened", hideDropdown);
		return () => {
			viewport.removeEventListener("scroll", setPositionDropDown);
			document.removeEventListener("click", hideDropdown);
			document.removeEventListener("dropdownopened", hideDropdown);
		};
	});
	// onDestroy(() => {});
</script>

<FormInput
	bind:value
	bind:this={elFormInput}
	type="text"
	{label}
	{icon}
	{inline}
	{labelReverse}
	{labelButton}
	{help}
	{feedback}
	{labelFor}
	{labelClass}
	{containerClass}
	{labelSize}
	{inputClass}
	{id}
	{name}
	{placeholder}
	{selectOnFocus}
	{prependClass}
	{appendClass}
	on:input
	on:focus={onfocus}
	on:click={onclick}
	on:clickicon={onclickicon}
	on:clicklabelbutton
>
	{#if dropdownTag == "ul"}
		<div class="dropdown-menu show p-0" on:click={onclickdropdown} bind:this={dropdown}>
			<slot name="dropdown" />
		</div>
	{:else}
		<div class="dropdown-menu show p-0" on:click={onclickdropdown} bind:this={dropdown}>
			<slot name="dropdown" />
		</div>
	{/if}

	<slot />
	<template {label} {labelClass} {labelFor}>
		<slot name="label" {label} {labelClass} {labelFor} />
	</template>
</FormInput>

<style>
	.dropdown-menu {
		font-size: var(--bs-body-font-size);
		overflow: auto;
		display: none;
		/* top: v-bind("dropdownStyles.top");
		bottom: v-bind("dropdownStyles.bottom");
		max-height: v-bind("dropdownStyles.maxHeight");
		height: v-bind("dropdownStyles.height");
		min-width: v-bind("dropdownStyles.minWidth"); */
	}
</style>

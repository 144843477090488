<script>
	import { createEventDispatcher, tick, onMount } from "svelte";
	import { wait } from "./utils.js";

	const dispatch = createEventDispatcher();

	export let value = false;
	export let size = "xl";
	export let staticBackdrop = true;
	export let modalClass = [];
	// export let keyboard = true;
	// export let animation = true;

	let valueReal = false;
	let modalClassDefault = ["modal"];
	let backdropDyn = staticBackdrop ? "static" : "";
	let modalDialogClass = ["modal-dialog"];
	if (size) modalDialogClass.push("modal-" + size);
	let modalEl, modalBackdropEl;
	// let myModal = null;

	$: {
		if (value) show();
		else hide();
	}

	function findHighestZIndex() {
		var divs = document.getElementsByClassName("modal");
		var highest = 1055;
		for (var i = 0; i < divs.length; i++) {
			var zindex = getComputedStyle(divs[i]).zIndex;
			if (zindex > highest) highest = zindex;
		}
		return highest;
	}

	export async function show() {
		// if (!modalEl || !modalBackdropEl) return;
		valueReal = true;
		value = true;
		let maxZIndex = findHighestZIndex() * 1;
		// console.log("maxZIndex", maxZIndex);
		await tick();

		let ok = await wait(() => {
			if (modalBackdropEl && modalEl) return true;
			return false;
		}, 500);
		if (ok) {
			modalEl.style.zIndex = maxZIndex + 2;
			modalBackdropEl.addEventListener("transitionend", transitionend, { once: true });
			modalBackdropEl.style.zIndex = maxZIndex + 1;
			setTimeout(() => {
				modalBackdropEl.classList.add("show");
			}, 50);
		}
	}
	export function hide() {
		if (!modalEl || !valueReal) return;
		// myModal.hide();
		modalEl.addEventListener("transitionend", transitionend, { once: true });
		modalEl.classList.remove("show");
		// modalBackdropEl.classList.remove("show");
	}
	function transitionend(evt) {
		// console.log("evt.target", evt.target);
		if (evt.target === modalBackdropEl) {
			if (modalBackdropEl.classList.contains("show")) {
				modalEl.addEventListener("transitionend", transitionend, { once: true });
				modalEl.style.display = "block";
				setTimeout(() => {
					modalEl.classList.add("show");
				}, 50);
			} else {
				modalEl.style.display = "none";
				valueReal = false;
				dispatch("hidden");
			}
		} else {
			if (modalEl.classList.contains("show")) {
				dispatch("shown");
			} else {
				modalBackdropEl.addEventListener("transitionend", transitionend, { once: true });
				modalBackdropEl.classList.remove("show");
			}
		}
	}

	onMount(() => {
		if (value) show();
		else hide();
	});
</script>

{#if valueReal}
	<div class="{modalClassDefault.join(' ')} {modalClass.join(' ')} fade" tabindex="-1" bind:this={modalEl} data-bs-backdrop={backdropDyn}>
		<div class={modalDialogClass.join(" ")}>
			<div class="modal-content">
				{#if $$slots.header}
					<div class="modal-header">
						<slot name="header" />
					</div>
				{/if}
				<div class="modal-body">
					<slot />
				</div>
				{#if $$slots.footer}
					<div class="modal-footer">
						<slot name="footer" />
					</div>
				{/if}
			</div>
		</div>
	</div>

	<div bind:this={modalBackdropEl} class="modal-backdrop fade" />
{/if}

<style>
</style>

<script>
	import { onMount, onDestroy } from "svelte";
	import { fade } from "svelte/transition";
	import { navigate } from "svelte-routing";
	import { filtersOffers, session, itemsTemp } from "./store.js";
	import * as M_ from "./components/M_.js";
	import { formatPrice, formatSurface, formatFromArray, formatOfferPrices, formatPieces, formatContactName } from "./Services.js";
	import Fa from "svelte-fa";
	import { faAngleRight, faAnglesRight, faAngleLeft, faAnglesLeft, faPlus } from "@fortawesome/free-solid-svg-icons";

	export let routename;
	export let location;
	if (location || routename);

	// filtersOffers.subscribe(v => {
	// 	console.log("v", v);
	// });

	$: {
		if ($filtersOffers) loadOffers();
	}

	let offers = [];
	let listDiv;
	let delayOffer;
	let skip = 0,
		limit = 10,
		total = 0;
	let numpage = 0,
		totalpage = 0;

	async function loadOffers() {
		// console.log("filtersOffers", filtersOffers);
		delayOffer = M_.utils.delay(
			delayOffer,
			async () => {
				if (listDiv) limit = Math.ceil(listDiv.offsetHeight / 50) - 3;

				let params = { ...$filtersOffers, limit, skip };
				let res = await M_.utils.fetchJsonGet("/backoffice/1.0/offers", params);
				if (res.data) {
					offers = res.data;
					total = res.meta.total;
					numpage = Math.ceil(skip / limit) + 1;
					totalpage = Math.ceil(total / limit);
				}
				// offers = offers;
				// console.log("loadOffersReal", offers);
			},
			100
		);
	}
	function addNewOffer() {
		navigate(`/offers/edit/-1`);
	}
	function pager(_skip) {
		skip = _skip;
		if (skip < 0) skip = 0;
		// console.log("skip", skip);
		loadOffers();
	}
	function resetFilters() {
		filtersOffers.reset();
	}
	function onitemclick(evt) {
		// console.log("evt.detail?.item?.of_id", evt.detail?.item?.of_id);
		if (evt.detail?.item?.of_id) navigate(`/offers/details/${evt.detail.item.of_id}`);
		// console.log("item, indexItem, evt", evt);
	}
	function reportWindowSize() {
		skip = 0;
		loadOffers();
	}
	onMount(() => {
		// loadOffers();
		window.addEventListener("resize", reportWindowSize);
	});
	onDestroy(() => {
		window.removeEventListener("resize", reportWindowSize);
	});
</script>

<main class="container-fluid">
	<div class="row">
		<div class="col-4">
			<div class="d-flex">
				<M_.FormCheckbox bind:value={$filtersOffers.room_1} label="Studio" />
				<M_.FormCheckbox bind:value={$filtersOffers.room_2} label="2P" />
				<M_.FormCheckbox bind:value={$filtersOffers.room_3} label="3P" />
				<M_.FormCheckbox bind:value={$filtersOffers.room_4} label="4P" />
				<M_.FormCheckbox bind:value={$filtersOffers.room_5} label="5P+" />
			</div>
			<div class="d-flex">
				<M_.FormCheckbox bind:value={$filtersOffers.monaco} label="Monaco" />
				<M_.FormCheckbox bind:value={$filtersOffers.france} label="France" />
				<M_.FormCheckbox bind:value={$filtersOffers.archives} label="Archives" />
				<M_.FormCheckbox bind:value={$filtersOffers.immotools} label="Immotools" />
			</div>
			<div class="d-flex">
				<M_.FormCheckbox bind:value={$filtersOffers.sell} label="Vente" />
				<M_.FormCheckbox bind:value={$filtersOffers.rent} label="Location" />
				<M_.FormCheckbox bind:value={$filtersOffers.newprogram} label="P. neuf" />
				<M_.FormCheckbox bind:value={$filtersOffers.followed} label="Suivis" />
			</div>
		</div>
		<div class="col-2">
			<div class="d-flex">
				<M_.FormInput
					type="number"
					label="Budget"
					bind:value={$filtersOffers.budgetmin}
					placeholder="min"
					containerClass={["flex-grow-1", "me-1"]}
				/>
				<M_.FormInput type="number" label="&nbsp;" bind:value={$filtersOffers.budgetmax} placeholder="max" containerClass={["flex-grow-1"]} />
			</div>
			<div class="d-flex">
				<M_.FormInput
					type="number"
					label="Surface"
					bind:value={$filtersOffers.surfacemin}
					placeholder="min"
					containerClass={["flex-grow-1", "me-1"]}
				/>
				<M_.FormInput
					type="number"
					label="&nbsp;"
					bind:value={$filtersOffers.surfacemax}
					placeholder="max"
					containerClass={["flex-grow-1"]}
				/>
			</div>
		</div>
		<div class="col-2">
			<M_.FormCombobox
				label="Agence"
				bind:value={$filtersOffers.agency}
				itemKey="co_id"
				itemValue={contact => formatContactName(contact)}
				remoteUrl="/backoffice/1.0/contacts"
			/>
			<M_.FormCombobox
				label="Négociateur"
				bind:value={$filtersOffers.negociator}
				itemKey="co_id"
				itemValue={contact => formatContactName(contact)}
				remoteUrl="/backoffice/1.0/contacts"
			/>
		</div>
		<div class="col-2">
			<M_.FormCombobox label="Immeuble" bind:value={$filtersOffers.building} items={offers} itemKey="of_id" itemValue="of_name" />
			<M_.FormCombobox label="Quartier" bind:value={$filtersOffers.area} items={offers} itemKey="of_id" itemValue="of_name" />
		</div>
		<div class="col-2">
			<M_.FormInput label="Texte libre" bind:value={$filtersOffers.text} />
			<button type="button" class="btn btn-secondary mt-3" on:click={resetFilters}>Reset</button>
		</div>
	</div>
	<div class="row flex-grow-1 overflow-auto" bind:this={listDiv}>
		<div class="col">
			<div class="d-flex ">
				<h5 class="mb-0">{total} offres</h5>
				<div class="ms-auto me-auto d-flex">
					<div class="btn-group">
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == 0}
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title="Tooltip on top"
							on:click={() => {
								pager(0);
							}}
							><Fa icon={faAnglesLeft} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == 0}
							on:click={() => {
								pager(skip - limit);
							}}
							><Fa icon={faAngleLeft} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == Math.ceil(total / limit) * limit - limit}
							on:click={() => {
								pager(skip + limit);
							}}
							><Fa icon={faAngleRight} />
						</button>
						<button
							type="button"
							class="btn btn-primary"
							disabled={skip == Math.ceil(total / limit) * limit - limit}
							on:click={() => {
								pager(Math.ceil(total / limit) * limit - limit);
							}}
							><Fa icon={faAnglesRight} />
						</button>
					</div>
					<div class="mt-2 ms-2">page {numpage}/{totalpage}</div>
				</div>
				<button type="button" class="btn btn-primary" on:click={addNewOffer}><Fa icon={faPlus} /> </button>
			</div>
			<M_.Table itemKey="of_id" items={offers} on:itemclick={onitemclick}>
				<svelte:fragment slot="ths">
					<th>Image</th>
					<th>Nom</th>
					<th>Type</th>
					<th>Surface</th>
					<!-- <th>Immeuble</th> -->
					<th>Prix</th>
				</svelte:fragment>
				<svelte:fragment slot="tds" let:item>
					<td />
					<td>{item.of_name_fr}<br /><span style="font-size:0.6rem">{item.of_ref}</span></td>
					<td>
						{formatFromArray(item.of_type, $itemsTemp.items_of_type, "")}<br />
						{@html formatPieces(item)}
					</td>
					<td>{@html formatSurface(item.of_surface)}</td>
					<td>{@html formatOfferPrices(item, "<br>")}</td>
				</svelte:fragment>
			</M_.Table>
		</div>
	</div>
</main>
